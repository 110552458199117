import {UserCreateRender} from './UserCreateRender';
import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';


export const UserCreateLoad = () => {
	const history = useHistory();
	const [formState, setFormState] = useState({
		firstName: '',
		lastName: '',
		email: '',
		organization: '',
		type: '',
		password: '',
		classLinkId: ''
	});

	const [isLoading, setIsLoading] = useState(false);

	const [createUser] = useMutation(mutations.userCreateOne, {
		onCompleted: () => setIsLoading(false),
	});

	const [updateUserSchool] = useMutation(mutations.SetUserSchool);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const { classLinkId, ...createUserInput } = formState;

			const { data } = await createUser({
				variables: createUserInput,
			});

			const userId = data.CreateUser;

			if (formState.type === 'e4475192-3c69-11ee-be56-0242ac120002' && classLinkId) {
				await updateUserSchool({
					variables: {
						user: userId,
						school: classLinkId,
					},
				});
			}

			history.push('/users');
		} catch (error) {
			console.error('Error creating user:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const allFieldsFilled = () => {
		const requiredFieldsFilled = Object.entries(formState).every(([key, value]) => {
			if (key === 'classLinkId') {
				return true;
			}
			return value.trim() !== '';
		});

		if (formState.type === 'e4475192-3c69-11ee-be56-0242ac120002') {
			return requiredFieldsFilled && formState.classLinkId.trim() !== '';
		}

		return requiredFieldsFilled;
	};

	return (
		<UserCreateRender
			formState={formState}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			allFieldsFilled={allFieldsFilled()}
			isLoading={isLoading}
		/>
	);
};