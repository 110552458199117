import {svgPropTypes} from '../../types';


export const WebsiteIcon = ({ width, height, background, className, style, isActive }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 15 14'
			 fill='none'
			 className={className}
			 style={{ ...style }}
		>
			<path
				d='M7.50391 13.3672C10.8176 13.3672 13.5039 10.6809 13.5039 7.36719C13.5039 4.05348 10.8176 1.36719 7.50391 1.36719C4.1902 1.36719 1.50391 4.05348 1.50391 7.36719C1.50391 10.6809 4.1902 13.3672 7.50391 13.3672Z'
				stroke='white' strokeWidth='1.2' strokeMiterlimit='10'/>
			<path d='M1.50391 7.36719H13.5039' stroke='white' strokeWidth='1.2' strokeLinecap='round'
				  strokeLinejoin='round'/>
			<path
				d='M7.50269 13.2046C8.8834 13.2046 10.0027 10.591 10.0027 7.36708C10.0027 4.14311 8.8834 1.52958 7.50269 1.52958C6.12197 1.52958 5.00269 4.14311 5.00269 7.36708C5.00269 10.591 6.12197 13.2046 7.50269 13.2046Z'
				stroke='white' strokeWidth='1.2' strokeMiterlimit='10'/>
		</svg>
	);
};

WebsiteIcon.propTypes = svgPropTypes;

WebsiteIcon.defaultProps = {
	width: '15',
	height: '14',
	background: 'rgba(255, 255, 255, 1)',
	className: '',
	style: {},
	isActive: false
};

export default WebsiteIcon;