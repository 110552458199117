import {Search} from '../../components';
import {useHistory} from 'react-router-dom';
import {StyledGrid} from './BadgesStyle';
import {ImageIcon, PencilIcon} from '../../icons';
import PropTypes from 'prop-types';
import {CurriculumFindOne} from '../../hoc';

export const BadgesRender = ({ badges }) => {
	const history = useHistory();

	return (
		<div className={'w-full h-full'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<h1 className={'font-sans text-base text-white f-500'}>Badges</h1>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<button
					style={{maxWidth: '340px'}}
					onClick={() => history.push('/badges/create')}
					className={`flex self-center align-center justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default`}
				>
					Create Badge
				</button>
				{badges.map(({_id, label, identifier, curriculum, cover}) => {
					return (
						<div key={_id}
							 className={'w-full h-full rounded flex gap-2 justify-center align-center p-3 mb-3 bg-background-light dark:bg-background-dark'}
							 style={{backgroundColor: 'rgba(11, 23, 57, 1)', border: '1px solid rgba(52, 59, 79, 1)' }}>
							<div className={'flex justify-start'}>
								<div className={'w-22 h-22 flex justify-center items-center mr-2'}>
									{
										!cover?.url ? (
											<div
												className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<ImageIcon width={'1rem'} height={'1rem'}
														   background={'rgba(174, 185, 225, 1)'} />
											</div>
										) : (
											<div
												className={'w-22 h-22 flex justify-center align-center rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<img
													className={'rounded-full'}
													src={cover.url}
													alt={''}/>
											</div>
										)
									}
								</div>
								<div className={'grid'}>
									<span
										className={'font-sans f-700 text-white truncate overflow-ellipsis'}>{label}</span>
									<p className={'font-mono truncate overflow-ellipsis'}
									   style={{ color: 'rgba(174, 185, 225, 1)' }}>Identifier: {identifier ? identifier : 'no-identifier'}</p>
									<div className={'font-mono truncate overflow-ellipsis'}
									   style={{ color: 'rgba(174, 185, 225, 1)' }}>Curriculum: <CurriculumFindOne id={ curriculum }/></div>
								</div>
							</div>
							<div className={'flex justify-center align-center'}
								 onClick={() => history.push(`/badges/${_id}`)}>
								<div onClick={() => history.push(`/badges/${_id}`)}
									 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark cursor-pointer'}>
									<PencilIcon />
								</div>
							</div>
						</div>
					);
				})}
			</StyledGrid>
		</div>
	);
};

BadgesRender.propTypes = {
	badges: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		identifier: PropTypes.string.isRequired,
		curriculum: PropTypes.string.isRequired,
		cover: PropTypes.shape({
			url: PropTypes.string
		}),
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired
	})).isRequired
};