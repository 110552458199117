import Input from '../../../../components/ui/Input';
import {CKEditorInput, TextArea} from '../../../../components';
import {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/ui/Button';
import {useMutation} from '@apollo/client';
import {sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';

export const ArticleForm = ({sparkL})=>{
	const defaultValues = {
		articleTitle: sparkL.articleTitle,
		articleBody: sparkL.articleBody
	};

	const [ formState, setFormState ] = useState({...defaultValues});

	const [updateSparkLibrary, { loading: updateLoading, error: updateError }]  = useMutation(sparkLibrariesMutations('updateOne'), {
		refetchQueries: ['sparkLibraryFindMany']
	});

	const handleOnChange = ({ target, editor }) => {
		const value = editor ? editor.getData() : target.value;
		const name = target.name || 'articleBody';
		setFormState(prev => ({
			...prev, [name]: value
		}));
	};

	const onSubmit = (e)=>{
		e.preventDefault();
		updateSparkLibrary({
			variables:{
				id: sparkL._id,
				record: formState
			}
		});
	};

	return(
		<form onSubmit={onSubmit}>
			<Input
				label = 'Article title'
				className={'w-full'}
				name = 'articleTitle'
				onChange={handleOnChange}
				defaultValue={defaultValues?.articleTitle}
			/>
			<CKEditorInput
				label={'Article body'}
				name='articleBody'
				value={formState.articleBody}
				onChange={handleOnChange}
			/>
			{updateError && <p className='text-red-500'>Error: {updateError.message}</p>}
			<Button
				type={'submit'}
				disabled = {updateLoading}
				className={`w-full ${updateLoading ? 'bg-purple-400' : 'bg-brightLilac-default'}`}
			>Edit</Button>
		</form>
	);
};

ArticleForm.propTypes = {
	sparkL: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		articleTitle: PropTypes.string.isRequired,
		articleBody: PropTypes.string.isRequired
	}).isRequired,
};
