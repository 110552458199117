import React,{useState} from 'react';
import {Link} from 'react-router-dom';

export default function Button({
	className,
	color,
	height,
	type,
	fontSize,
	fontColor,
	children,
	to,
	request,
	requestAction,
	requestError,
	handleSubmit,
	...props
}){
	
	const [defaultClass]=useState('center justify-center b-0 br-quarter inline-flex phor align-center cursor-pointer f-700');
	const [active,setActive]=useState(true);
	
	const onSubmitButton=(data)=>{
		if(active){
			setActive(false);
			return request(data)
			.then(res=>requestAction())
			.catch(err=>requestError(err.message))
			.finally(()=>setActive(true));
		}
	};
	
	switch(type){
		case 'button':
			return (
				<button
					type={'button'}
					className={`${className} ${color ? color : 'black'} ${fontSize ? fontSize : 'f-medium'} ${fontColor ? fontColor : 't-white'} ${height ? height : 'h-2rem'} ${defaultClass}`} {...props}>
					{children}
				</button>
			);
			break;
		case 'Link':
			return (
				<Link to={to}
				  className={`${className} ${color ? color : 'black'} ${fontSize ? fontSize : 'f-medium'} ${fontColor ? fontColor : 't-white'} ${height ? height : 'h-2rem'} ${defaultClass}`} {...props}>
					{children}
				</Link>
			);
			break;
		case 'submit':
			return (
				<button
					type ='submit'
					disabled={props.disabled}
					onClick={ handleSubmit && handleSubmit(onSubmitButton)}
					className={`${className} ${color ? color : 'black'} ${fontSize ? fontSize : 'f-medium'} ${fontColor ? fontColor : 't-white'} ${height ? height : 'h-2rem'} ${defaultClass}`} {...props}>
					{children}
				</button>
			);
			break;
		default:
			return (
				<a className={`${className} ${color ? color : 'black'} ${fontSize ? fontSize : 'f-medium'} ${fontColor ? fontColor : 't-white'} ${height ? height : 'h-2rem'} ${defaultClass}s`} {...props}>
					{children}
				</a>
			);
			break;
	}
	
}
