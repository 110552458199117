import styled from 'styled-components';


export const SearchWrapper = styled.div`
    background-color: rgba(11, 23, 57, 1);
    border-radius: 6px;
    border: 1px solid rgba(52, 59, 79, 1);
    color: rgba(174, 185, 225, 1);
    font-weight: 500;
	width: 100%;

    input {
        color: rgba(174, 185, 225, 1);
        font-size: 0.75rem;
		line-height: 0.76rem;
        font-weight: 500;
        background: transparent;
        border: none;
        outline: none;
        width: 100%;
    }

    input::placeholder {
        color: rgba(174, 185, 225, 1);
        font-size: 0.75rem;
        line-height: 0.76rem;
        font-weight: 500;
    }

    input:focus::placeholder {
        color: transparent;
    }
`;