import {svgPropTypes} from '../../types';

export const LocationIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : background;

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 13 13'
			 fill={fillColor}
			 className={className}
			 style={{ ...style }}>
			<path
				d='M6.50451 0.367188C5.25459 0.368605 4.05627 0.865762 3.17244 1.74959C2.28861 2.63342 1.79146 3.83174 1.79004 5.08166C1.79004 9.11574 6.07592 12.1641 6.25807 12.2927C6.33107 12.3413 6.41681 12.3672 6.50451 12.3672C6.59221 12.3672 6.67795 12.3413 6.75095 12.2927C6.9331 12.1641 11.219 9.11574 11.219 5.08166C11.2176 3.83174 10.7204 2.63342 9.83657 1.74959C8.95275 0.865762 7.75443 0.368605 6.50451 0.367188ZM6.50451 3.3673C6.84357 3.3673 7.17503 3.46785 7.45695 3.65622C7.73887 3.8446 7.95861 4.11234 8.08836 4.4256C8.21812 4.73886 8.25207 5.08356 8.18592 5.41611C8.11977 5.74866 7.95649 6.05413 7.71674 6.29389C7.47698 6.53364 7.17151 6.69692 6.83896 6.76307C6.50641 6.82922 6.16171 6.79527 5.84845 6.66551C5.5352 6.53576 5.26745 6.31602 5.07908 6.0341C4.8907 5.75218 4.79016 5.42072 4.79016 5.08166C4.79016 4.62698 4.97077 4.19093 5.29228 3.86943C5.61378 3.54792 6.04983 3.3673 6.50451 3.3673Z'
				fill={fillColor}/>
		</svg>
	);
};

LocationIcon.propTypes = svgPropTypes;

LocationIcon.defaultProps = {
	width: '13',
	height: '13',
	background: 'rgba(255, 255, 255, 1)',
	className: '',
	style: {},
	isActive: false
};

export default LocationIcon;