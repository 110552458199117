import PropTypes from 'prop-types';
import {ModalCloseButton, ModalContent, ModalOverlay} from './Modal.style';

const Modal = ({ isOpen, setIsOpen, children }) => {
	if (!isOpen) return null;

	return (
		<ModalOverlay className={''} onClick={() => setIsOpen(false)}>
			<ModalContent
				className={'text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
				onClick={(e) => e.stopPropagation()}>
				<ModalCloseButton className={'font-mono'} onClick={() => setIsOpen(false)}>X</ModalCloseButton>
				{children}
			</ModalContent>
		</ModalOverlay>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export default Modal;