import PropTypes from 'prop-types';

export const LessonsCreateTypes = {
	formState: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		curriculum: PropTypes.string.isRequired,
		order: PropTypes.number.isRequired,
		lifeSkill: PropTypes.array.isRequired,
		skillSet: PropTypes.array.isRequired,
		theme: PropTypes.array.isRequired,
		cover: PropTypes.shape({
			url: PropTypes.string,
			type: PropTypes.string,
		}).isRequired,
		episodeTime: PropTypes.number.isRequired,
		quizTime: PropTypes.number.isRequired,
		journalTime: PropTypes.number.isRequired,
		classActivityTime: PropTypes.number.isRequired,
		tapLibraryTime: PropTypes.number.isRequired,
		extendTime: PropTypes.number.isRequired,
		lessonLength: PropTypes.string.isRequired,
		overview: PropTypes.string.isRequired,
		extraActivities: PropTypes.string.isRequired,
		bigIdea: PropTypes.string.isRequired,
		learningGoal: PropTypes.string.isRequired,
		discussion: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				description: PropTypes.string.isRequired,
			})
		).isRequired,
		extend: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				description: PropTypes.string.isRequired,
			})
		).isRequired,
		reflection: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				description: PropTypes.string.isRequired,
			})
		).isRequired,
		activity: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				description: PropTypes.string.isRequired,
			})
		).isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	handleArrayChange: PropTypes.func.isRequired,
	handleAddArrayItem: PropTypes.func.isRequired,
	handleRemoveArrayItem: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	selectedLifeSkills: PropTypes.array.isRequired,
	selectedSkillSets: PropTypes.array.isRequired,
	selectedThemes: PropTypes.array.isRequired,
	setSelectedLifeSkills: PropTypes.func.isRequired,
	setSelectedSkillSets: PropTypes.func.isRequired,
	setSelectedThemes: PropTypes.func.isRequired,
	preview: PropTypes.string,
	allFieldsFilled: PropTypes.bool.isRequired,
	isMounted: PropTypes.bool.isRequired,
};