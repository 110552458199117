import {svgPropTypes} from '../../types';


export const MagnifyingGlassIcon = ( { width, height, background, className, style, isActive } ) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 16 16'
			 fill='none'
			 className={className}
			 style={{...style}}>
			<path
				d='M7.2593 12.9445C10.5321 12.9445 13.1852 10.2914 13.1852 7.01858C13.1852 3.74578 10.5321 1.09265 7.2593 1.09265C3.9865 1.09265 1.33337 3.74578 1.33337 7.01858C1.33337 10.2914 3.9865 12.9445 7.2593 12.9445Z'
				stroke={fillColor} strokeLinecap='round' strokeLinejoin='round'/>
			<path d='M14.6666 14.426L11.4444 11.2037' stroke='#AEB9E1' strokeLinecap='round' strokeLinejoin='round'/>
		</svg>
	);
};

MagnifyingGlassIcon.propTypes = svgPropTypes;

MagnifyingGlassIcon.defaultProps = {
	width: '14',
	height: '14',
	background: '#AEB9E1',
	className: '',
	style: {},
	isActive: false
};

export default MagnifyingGlassIcon;