import {Journal} from './Journal';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useQuestionsLayout} from '../../../../hooks';
import { sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';
import PropTypes from 'prop-types';

export const JournalLoad = ({setIsOpen, sparkL}) =>{
	const [ addQuestionModal, setAddQuestionModal ] = useState(false);

	const mindful = sparkL.journals.map(item => ({
		question: item.question,
		points: item.points
	}))||[];


	const [updateSparkLibrary] = useMutation(sparkLibrariesMutations('updateOne'), {
		refetchQueries: ['sparkLibraryFindMany'],
		awaitRefetchQueries: true
	});

	const onChangeQuestionsArray =async (newQuestions)=>{
		await updateSparkLibrary({
			variables:{id:sparkL._id, record:{ journals: newQuestions }}
		})
		.then();
	};

	const { questions, onUpdateQuestion, onDeleteQuestion, onCreateQuestion } = useQuestionsLayout({questions:mindful,onChangeQuestionsArray});

	return (
		
		<Journal
			addQuestionModal={addQuestionModal}
			questions={questions}
			questionPoints={mindful}

			setAddQuestionModal={setAddQuestionModal}
			onUpdateQuestion={onUpdateQuestion}
			onDeleteQuestion={onDeleteQuestion}
			onCreateQuestion={onCreateQuestion}
		/>
	
	);
};

JournalLoad.propTypes = {
	sparkL: PropTypes.shape({
		_id:PropTypes.string.isRequired,
		journals:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,

	setIsOpen:PropTypes.func.isRequired
};