import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {MoodCreatePageCard, MoodCreatePageContainer, StyledGrid} from './MoodCreate.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';

export const MoodCreate = () => {
	const history = useHistory();

	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		description: '',
		order: 0,
		color: '',
		tips: ['']
	});
	const [isChanged, setIsChanged] = useState(false);

	const [createMood, { loading: isLoading }] = useMutation(mutations.MoodCreateOne, {
		refetchQueries: ['MoodFindMany'],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({
			...prev,
			[name]: name === 'order' ? parseFloat(value).toFixed(2) : value
		}));
		setIsChanged(true);
	};

	const handleTipsChange = (index, value) => {
		const newTips = [...formState.tips];
		newTips[index] = value;
		setFormState((prev) => ({ ...prev, tips: newTips }));
		setIsChanged(true);
	};

	const handleAddTip = () => {
		setFormState((prev) => ({ ...prev, tips: [...prev.tips, ''] }));
		setIsChanged(true);
	};

	const handleRemoveTip = (index) => {
		const newTips = formState.tips.filter((_, i) => i !== index);
		setFormState((prev) => ({ ...prev, tips: newTips }));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await createMood({
				variables: {
					record: {
						...formState,
						order: parseFloat(parseFloat(formState.order).toFixed(2))
					},
				},
			});
			setIsChanged(false);
			history.push('/mood');
		} catch (error) {
			console.error('Error creating mood:', error);
		}
	};

	const allFieldsFilled = formState.label && formState.identifier && formState.description;

	return (
		<MoodCreatePageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Mood</h1>
			<MoodCreatePageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
						<div
							className={'w-45 h-45 cursor-pointer bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'flex justify-center align-center p-2 w-44 h-44 '}>
								<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
							</div>
						</div>
						<span
							className={'font-sans ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
              Upload Image
						</span>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
						<input
							type='text'
							name='label'
							value={formState.label}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
						<input
							type='text'
							name='identifier'
							value={formState.identifier}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
						<textarea
							name='description'
							value={formState.description}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Order</label>
						<input
							type='text'
							name='order'
							value={parseFloat(formState.order).toFixed(2)}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>color</label>
						<input
							type='text'
							name='color'
							value={formState.color}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Tips</label>
						<div className={'w-100 flex justify-center align-center gap-4'}>
							<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px'
								$placeItems='center'>
								{formState.tips.map((tip, index) => (
									<div key={index} className={'flex self-start mb-2'}>
										<input
											type='text'
											name={`tip-${index}`}
											value={tip}
											onChange={(e) => handleTipsChange(index, e.target.value)}
											className={'font-mono p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
										/>
										<button
											type='button'
											onClick={() => handleRemoveTip(index)}
											className={'font-mono ml-2 bg-red-500 text-white rounded-full px-2'}
											style={{width: '1.6rem', height: '1.6rem'}}
										>
											X
										</button>
									</div>
								))}
							</StyledGrid>
						</div>
						<button
							type='button'
							onClick={handleAddTip}
							className={'font-mono px-4 py-2 mt-2 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}
						>
							Add Tip
						</button>
					</div>
					<div className={'flex align-middle justify-center center'}>
						<button
							type='submit'
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								!allFieldsFilled || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={!allFieldsFilled || isLoading}
						>
							{isLoading ? 'Creating your Mood...' : 'Create Mood'}
						</button>
					</div>
				</form>
			</MoodCreatePageCard>
		</MoodCreatePageContainer>
	);
};