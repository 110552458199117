import {svgPropTypes} from '../../types';


export const CheckBoxIcon = ({ width, height, background, className, style, isActive }) => {

	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 6 6'
			 fill='none'
			className={className}
			style={{...style}}
		>
			<path d='M0.801758 3.42196L2.11378 4.73398L5.39384 1.45392' stroke={background} strokeLinecap='round'
				  strokeLinejoin='round'/>
		</svg>
	);
};


CheckBoxIcon.propTypes = svgPropTypes;

CheckBoxIcon.defaultProps = {
	width: '6',
	height: '6',
	background: 'white',
	className: '',
	style: {},
	isActive: false,
};

export default CheckBoxIcon;