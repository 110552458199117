import {MoodEditPageCard, MoodEditPageContainer} from './MoodEdit.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {mutations} from '../../../actions/mutations';
import DeleteConfirmationModal from '../../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';
import {removeTypename as removeTypenameFunction} from '../../../tools';

export const MoodEdit = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('MoodFindMany'), {
		variables: { filter: { _id: id }, limit: 1 },
	});

	const [mood, setMood] = useState(null);
	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		description: '',
		cover: {
			url: '',
			type: '',
		},
		video: {
			url: '',
			type: '',
		},
		order: 0,
		color: '',
		tips: [''],
		createdAt: '',
		updatedAt: '',
		deletedAt: null,
	});
	const [isChanged, setIsChanged] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	useEffect(() => {
		if (data && data.MoodFindMany.length > 0) {
			const moodData = data.MoodFindMany[0];
			setMood(moodData);
			setFormState({
				label: moodData.label || '',
				identifier: moodData.identifier || '',
				description: moodData.description || '',
				cover: {
					url: moodData.cover?.url || '',
					type: moodData.cover?.type || '',
				},
				video: {
					url: moodData.video?.url || '',
					type: moodData.video?.type || '',
				},
				order: moodData.order ? parseFloat(moodData.order).toFixed(2) : 0,
				color: moodData.color || '',
				tips: moodData.tips || [''],
				createdAt: moodData.createdAt,
				updatedAt: moodData.updatedAt,
				deletedAt: moodData.deletedAt,
			});
		}
	}, [data]);

	const [updateMood, {loading: isLoading}] = useMutation(mutations.moodUpdateOne, {
		refetchQueries: ['MoodFindMany'],
		awaitRefetchQueries: true
	});

	const [deleteMood] = useMutation(mutations.MoodDeleteOne, {
		refetchQueries: ['MoodFindMany'],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({
			...prev,
			[name]: name === 'order' ? parseFloat(value).toFixed(2) : value
		}));
		setIsChanged(true);
	};

	const handleTipsChange = (index, value) => {
		const newTips = [...formState.tips];
		newTips[index] = value;
		setFormState((prev) => ({ ...prev, tips: newTips }));
		setIsChanged(true);
	};

	const handleAddTip = () => {
		setFormState((prev) => ({ ...prev, tips: [...prev.tips, ''] }));
		setIsChanged(true);
	};

	const handleRemoveTip = (index) => {
		const newTips = formState.tips.filter((_, i) => i !== index);
		setFormState((prev) => ({ ...prev, tips: newTips }));
		setIsChanged(true);
	};

	const handleDelete = async () => {
		try {
			await deleteMood({
				variables: { mood: mood._id }
			});
			setIsDeleteModalOpen(false);
			history.push('/mood');
		} catch (error) {
			console.error('Error deleting mood:', error);
		}
	};
	const removeTypename = (obj) => removeTypenameFunction(obj);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const cleanedFormState = removeTypename(formState);
			await updateMood({
				variables: {
					_id: mood._id,
					record: {
						...cleanedFormState,
						order: parseFloat(parseFloat(formState.order).toFixed(2))
					},
				},
			});
			setIsChanged(false);
			history.push('/mood');
		} catch (error) {
			console.error('Error updating mood:', error);
		}
	};

	if (loading) return <h2 className={'font-mona f-900 white p-3'}>Loading...</h2>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error: {error.message}</p>;
	if (!data) return <p className={'font-mona f-900 white p-3'}>No data available</p>;

	return (
		<MoodEditPageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Mood</h1>
			<MoodEditPageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
						{formState.cover.url && formState.cover.url !== 'test' ? (
							<img src={formState.cover.url} alt={formState.identifier}
								 className={'w-44 h-44 cursor-pointer'}/>
						) : (
							<div
								className={'w-44 h-44 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
								<ImageIcon width={'3rem'} height={'3rem'} background={'rgba(174, 185, 225, 1)'}/>
							</div>
						)}
						<span
							className={'font-sans ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>Change Image</span>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
						<input
							type='text'
							name='label'
							value={formState.label}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
						<input
							type='text'
							name='identifier'
							value={formState.identifier}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
						<textarea
							name='description'
							value={formState.description}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Order</label>
						<input
							type='text'
							name='order'
							value={parseFloat(formState.order).toFixed(2)}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Color</label>
						<input
							type='text'
							name='color'
							value={formState.color}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Tips</label>
						{formState.tips.map((tip, index) => (
							<div key={index} className={'flex self-start mb-2'}>
								<input
									type='text'
									name={`tip-${index}`}
									value={tip}
									onChange={(e) => handleTipsChange(index, e.target.value)}
									className={'font-mono p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
								/>
								<button type='button'
									onClick={() => handleRemoveTip(index)}
									className={'font-mono ml-2 bg-red-500 text-white rounded-full px-2'}
									style={{width: '1.6rem', height: '1.6rem'}}>
									X
								</button>
							</div>
						))}
						<button type='button' onClick={handleAddTip}
							className={'font-mono px-4 py-2 mt-2 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							Add Tip
						</button>
					</div>
					<div className={'mb-4 flex direction-column justify-between gap-2'}>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Created
							At: </b>{new Date(formState.createdAt).toLocaleString()}</p>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Updated
							At:</b> {new Date(formState.updatedAt).toLocaleString()}</p>
						{formState.deletedAt && (
							<p className={'font-mono text-red-500'}><b>Deleted
								At:</b> {new Date(formState.deletedAt).toLocaleString()}</p>
						)}
					</div>
					<button
						type='button'
						onClick={() => setIsDeleteModalOpen(true)}
						className={'font-mono mb-4 px-4 py-2 mt-4 font-medium text-white bg-red-600 rounded hover:bg-red-700'}
					>
						Delete
					</button>
					<div className={'flex align-middle justify-center center'}>
						<button
							type='submit'
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								!isChanged || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={!isChanged || isLoading}
						>
							{isLoading ? 'Updating your Mood...' : 'Update Mood'}
						</button>
					</div>
				</form>
				<DeleteConfirmationModal
					isOpen={isDeleteModalOpen}
					setIsOpen={setIsDeleteModalOpen}
					onDelete={handleDelete}
					itemName={formState.label}
				/>
			</MoodEditPageCard>
		</MoodEditPageContainer>
	);
};