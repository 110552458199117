import config from '../tools/config';
import {addHeaders,authResponse} from '../tools';

const url=config.url;

export const logout=()=>{
	return fetch(`${url}logout`,{
		method:'POST',
		headers:{'Content-Type':'application/json'},
		credentials:'include',
		firstTime:false
	}).then(res=>{
		localStorage.clear();
		return res;
	}).catch(err=>err);
};

export const refreshToken = () => {
	return fetch(`${url}common/refreshtoken`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		credentials: 'include'
	})
	.then(res => res.json())
	.then(res => {
		console.log('refreshToken HERE');
		if (res && res.token) {
			localStorage.setItem('accessToken', res.token);
			return true;
		} else {
			throw new Error('Token refresh failed');
		}
	})
	.catch(err => {
		console.error('Refresh token failed:', err);
		return null;
	});
};

export const login=(value)=>{
	return fetch(`${url}webapi/login`,{
		headers:addHeaders({'Content-Type':'application/json'}),
		method:'post',
		body:JSON.stringify(value),
		credentials:'include'
	}).then(authResponse);
};

export const AuthUser = () => {
	return fetch(`${url}common/userdata`, {
		headers: addHeaders({ 'Content-Type': 'application/json' }),
		method: 'GET',
		credentials: 'include',
		firstTime: false
	})
	.then(response => {
		return response.json();
	})
	.then(data => {
		return data;
	})
	.catch(err => {
		console.error('Error:', err);
		return err;
	});
};

export const uploadCurriculumPhoto = (file) => {
	const formData = new FormData();
	formData.append('file', file);
	return fetch(`${url}admin/curriculum-document`, {
		method: 'POST',
		headers: addHeaders({}),
		body: formData,
		credentials: 'include',
		firstTime: false
	})
	.then(response => response.json())
	.then(data => {
		return data;
	})
	.catch(err => {
		console.error('Upload Photo Error:', err);
		return err;
	});
};

export const uploadArtworkPicture = async (file, id) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('avatar', id);

	try {
		const response = await fetch(`${url}admin/avatar-artwork`, {
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firstTime: false
		});
		return response.json();
	} catch (err) {
		console.error('Upload Photo in Artwork Error:', err);
		throw new Error(err);
	}
};

export const uploadCollectiblesCover = async (file, id) =>{
	const formData = new FormData();
	formData.append('file', file);
	formData.append('collectible',id);

	try {
		const response = await fetch(`${url}admin/collectible-cover`, {
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.error('Upload Cover in Collectibles Error: ', err);
		throw new Error(err);
	}
};

export const uploadClassCover = async ( file, id ) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('class', id);

	try {
		const response = await fetch(`${url}admin/class-cover`,
			{
				method: 'PUT',
				headers: addHeaders({}),
				body: formData,
				credentials: 'include',
				firsTime: false
			});
		return response.json();
	} catch ( err ) {
		console.error('Upload Cover for Classes Error: ', err);
		throw new Error(err);
	}
};

export const uploadClassDocument = async ( file, id, title ) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('title', title);
	formData.append('id', id);

	try {
		const response = await fetch(`${url}admin/class-document`,
			{
				method: 'PUT',
				headers: addHeaders({}),
				body: formData,
				credentials: 'include',
				firsTime: false
			});
		return response.json();
	} catch ( err ) {
		console.error('Upload Documents for Classes Error: ', err);
		throw new Error(err);
	}
};

export const uploadCurriculumDocument = async ( file, id, title ) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('title', title);
	formData.append('id', id);

	try {
		const response = await fetch(`${url}admin/curriculum-document`,
			{
				method: 'PUT',
				headers: addHeaders({}),
				body: formData,
				credentials: 'include',
				firsTime: false
			});
		return response.json();
	} catch ( err ) {
		console.error('Upload Documents for Curriculum Error: ', err);
		throw new Error(err);
	}
};


export const uploadCollectiblesVideo = async ( file, id ) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('collectible', id);

	try {
		const response = await fetch(`${url}admin/collectible-video`,
			{
				method: 'PUT',
				headers: addHeaders({}),
				body: formData,
				credentials: 'include',
				firsTime: false
			});
		return response.json();
	} catch ( err ) {
		console.error('Upload Video for Collectible Error: ', err);
		throw new Error(err);
	}
};

export const uploadSparkLibraryCover= async(file,id)=>{
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);

	try {
		const response = await fetch(`${url}admin/sparklibrary-cover`,
			{
				method: 'PUT',
				headers: addHeaders({}),
				body: formData,
				credentials: 'include',
				firsTime: false
			});
		return response.json();
	} catch ( err ) {
		console.error('Upload spark library cover error: ', err);
		throw new Error(err);
	}
};

export const uploadPinCover = async(file, id) => {
	const formData = new FormData();
	formData.append('file',file);
	formData.append('id',id);

	try {
		const response = await fetch(`${url}admin/pin-cover`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Pin Cover Error: ', err);
		throw new Error(err);
	}
};

export const uploadPinVideo = async(file, id) => {
	const formData = new FormData();
	formData.append('file',file);
	formData.append('id',id);

	try {
		const response = await fetch(`${url}admin/pin-video`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Pin Video Error: ', err);
		throw new Error(err);
	}
};

export const uploadStickerCover = async(file, id) => {
	const formData = new FormData();
	formData.append('file',file);
	formData.append('id',id);
	formData.append('title','');

	try {
		const response = await fetch(`${url}admin/sticker-cover`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Sticker Cover Error: ', err);
		throw new Error(err);
	}
};

export const uploadStickerVideo = async(file, id) => {
	const formData = new FormData();
	formData.append('file',file);
	formData.append('id',id);
	formData.append('title','');

	try {
		const response = await fetch(`${url}admin/sticker-video`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Sticker Cover Error: ', err);
		throw new Error(err);
	}
};

export const uploadBadgeCover = async(file, id) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('badge', id);

	try {
		const response = await fetch(`${url}admin/badge-cover`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Badge Cover Error: ', err);
		throw new Error(err);
	}
};
export const uploadBadgeVideo = async(file, id) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('badge', id);

	try {
		const response = await fetch(`${url}admin/badge-video`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Badge Video Error: ', err);
		throw new Error(err);
	}
};