import {useState} from 'react';
import {useQuery} from '@apollo/client';
import {sparkLibrariesQueries} from './actions/SparkLibraries.queries';
import {query} from '../../actions/queries';
import {SparkLibrariesRender} from './SparkLibraries.render';

export const SparkLibrariesLoad =()=>{
	const [sparkFilter,setSparkFilter] =useState({
		lesson:undefined
	});

	const [isOpen, setIsOpen]= useState(false);
	const [isDeleteOpen,setIsDeleteOpen] = useState(false);

	const [currentSparkLibrary,setCurrentSparkLibrary] = useState(undefined);

	const {data:sparkLibrariesData,loading,refetch} = useQuery(sparkLibrariesQueries('sparkLibraryFindMany'),{
		variables:{
			filter:sparkFilter
		}
	});
	const {data:lessonsData,loading:lessonsLoading} = useQuery(query('classesAdminFindMany'));

	const sparkLibraries=sparkLibrariesData?.sparkLibraryFindMany?.filter((sl)=>!sl.deleted)||[];
	const lessonOptions = lessonsData?.ClassesAdminFindMany?.map((lesson)=>(({...lesson,label:lesson.title}))) || [];

	const handleLessonChange =({target})=>{
		setSparkFilter(prev=>({...prev, lesson:target.value||undefined}));
	};

	const handleCreate = ()=>{
		setCurrentSparkLibrary(undefined);
		setIsOpen(true);
	};
	const handleEdit = (sparkLibrary)=>{
		setCurrentSparkLibrary(sparkLibrary);
		setIsOpen(true);
	};

	const handleDelete = (sparkLibrary)=>{
		setCurrentSparkLibrary(sparkLibrary);
		setIsDeleteOpen(true);
	};

	return (
		<SparkLibrariesRender
			sparkLibraries={sparkLibraries}
			lessonOptions={lessonOptions}
			refetch={refetch}
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			isDeleteOpen={isDeleteOpen}
			setIsDeleteOpen={setIsDeleteOpen}
			handleCreate={handleCreate}
			handleEdit={handleEdit}
			handleDelete={handleDelete}
			currentSparkLibrary={currentSparkLibrary}
			handleLessonChange={handleLessonChange}
		/>
	);
    
};