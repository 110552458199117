import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {AvatarCreatePageCard, AvatarCreatePageContainer} from './AvatarCreate.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {CurriculumFindMany} from '../../../hoc/CurriculumFindMany';
import {query} from '../../../actions/queries';
import {uploadArtworkPicture} from '../../../actions/restactions';


export const AvatarCreate = () => {
	const history = useHistory();

	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		price: '',
		type: '',
		curriculum: ''
	});
	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);

	const [createAvatar, { loading: isLoading }] = useMutation(mutations.AvatarCreateOne, {
		refetchQueries: [{ query: query('AvatarFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createAvatar({
				variables: {
					identifier: formState.identifier,
					label: formState.label,
					price: parseInt(formState.price),
					type: formState.type,
					curriculum: formState.curriculum,
				},
			});

			const createdAvatarId = data.AvatarCreateOne._id;

			if (selectedFile) {
				await uploadArtworkPicture(selectedFile, createdAvatarId);
			}

			setIsChanged(false);
			history.push('/avatar');
		} catch (error) {
			console.error('Error creating avatar:', error);
		}
	};
	
	const allFieldsFilled = formState.label && formState.identifier && formState.price && formState.type;

	return (
		<AvatarCreatePageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Avatar</h1>
			<AvatarCreatePageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<label htmlFor='file-upload'
						   className={'mb-4 flex direction-column gap-2 justify-center align-center cursor-pointer'}>
						<div
							className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'flex justify-center align-center p-2 w-44 h-44'}>
								{preview ? (
									<img src={preview} alt='Preview' className={'rounded-full w-44 h-44 object-cover'}/>
								) : (
									<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
								)}
							</div>
						</div>
						<span className={'font-sans text-text-light dark:text-text-dark active-nav-item-font-color'}>
							Upload Image
						</span>
						<input
							id='file-upload'
							type='file'
							accept='image/*'
							onChange={handleFileChange}
							className={'hidden'}
						/>
					</label>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
						<input
							type='text'
							name='label'
							value={formState.label}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
						<input
							type='text'
							name='identifier'
							value={formState.identifier}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Price</label>
						<input
							type='number'
							name='price'
							value={formState.price}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Type</label>
						<input
							type='text'
							name='type'
							value={formState.type}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Curriculum
							(Optional)</label>
						<CurriculumFindMany>
							{
								curriculums => (
									<select
										name='curriculum'
										value={formState.curriculum}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									>
										<option value=''>Select a Curriculum</option>
										{curriculums.map(curriculum => (
											<option key={curriculum._id} value={curriculum._id}>
												{curriculum.title}
											</option>
										))}
									</select>
								)
							}
						</CurriculumFindMany>
					</div>
					<div className={'flex align-middle justify-center center'}>
						<button
							type='submit'
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								!allFieldsFilled || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={!allFieldsFilled || isLoading}
						>
							{isLoading ? 'Creating your Avatar...' : 'Create Avatar'}
						</button>
					</div>
				</form>
			</AvatarCreatePageCard>
		</AvatarCreatePageContainer>
	);
};