import Input from '../../../../components/ui/Input';
import {TextArea} from '../../../../components';
import {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/ui/Button';
import {useMutation} from '@apollo/client';
import {sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';

export const TakeAwayForm = ({sparkL})=>{
	const defaultValues = {
		takeAwayLabel:sparkL.takeAwayLabel,
		takeAwayDescription:sparkL.takeAwayDescription
	};
	const [ formState, setFormState ] = useState({...defaultValues});

	const [updateSparkLibrary,{loading : updateLoading,error : updateError}]  = useMutation(sparkLibrariesMutations('updateOne'),{
		refetchQueries:['sparkLibraryFindMany']
	});

	const handleOnChange = ({target})=>{
		setFormState(prev=>({
			...prev,[target.name]:target.value
		}));
	};

	const onSubmit = (e)=>{
		e.preventDefault();
		updateSparkLibrary({
			variables:{
				id:sparkL._id,
				record:formState
			}
		});
	};

	return(
		<form onSubmit={onSubmit}>
			<Input
				label = 'TakeAway label'
				className={'w-full'}
				name = 'takeAwayLabel'
				onChange={handleOnChange}
				defaultValue={formState?.takeAwayLabel}
			/>
			<TextArea
				label={'TakeAway description'}
				className={'w-full'}
				name = 'takeAwayDescription'
				onChange={handleOnChange}
				defaultValue={formState?.takeAwayDescription}
			/>
			<Button
				type={'submit'}
				disabled = {updateLoading}
				className={`w-full ${updateLoading? 'bg-purple-400' : 'bg-brightLilac-default'}`}
			>Edit</Button>
		</form>
	);
};

TakeAwayForm.propTypes = {
	sparkL:
    PropTypes.shape({
    	_id: PropTypes.string.isRequired,
    	question: PropTypes.string.isRequired,
    	points: PropTypes.string.isRequired
    }).isRequired,
};