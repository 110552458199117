import {svgPropTypes} from '../../types';

export const TrashIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 14 14'
			 fill='none'
			className={className}
			style={{...style}}>
			<path
				d='M11.1486 11.9359C11.1134 12.4611 10.6771 12.869 10.1508 12.869H3.84188C3.31558 12.869 2.87936 12.4611 2.84412 11.936L2.27759 3.49451H11.7144L11.1486 11.9359Z'
				fill={fillColor} stroke='#AEB9E1' strokeLinecap='round' strokeLinejoin='round'/>
			<path d='M1 3.49548H13' stroke='#AEB9E1' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round'/>
			<path
				d='M8.52789 1.3186H5.46852C5.19806 1.3186 4.93867 1.43321 4.74742 1.63721C4.55617 1.8412 4.44873 2.11788 4.44873 2.40638V3.49416H9.54768V2.40638C9.54768 2.11788 9.44024 1.8412 9.24899 1.63721C9.05775 1.43321 8.79836 1.3186 8.52789 1.3186Z'
				stroke='#AEB9E1' strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round'/>
		</svg>
	);
};

TrashIcon.propTypes = svgPropTypes;

TrashIcon.defaultProps = {
	width: '14',
	height: '14',
	background: 'transparent',
	className: '',
	style: {},
	isActive: false,
};
export default TrashIcon;