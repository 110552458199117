import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';


export const SkillSetFindMany = ({ children }) => {
	const { data, loading, error } = useQuery(query('skillSetFindMany'), {
		variables: { limit: 1000 },
		fetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.SkillSetFindMany) return <p className={'font-mona f-900 text-white p-3'}>No Skills sets found</p>;

	return children(data.SkillSetFindMany);
};