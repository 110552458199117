import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';


export const DistrictFindMany =  ({children})  => {
	const {data, loading, error} = useQuery(query('districtFindMany'),{
		variables: {
			sort: '_ID_DESC'
		},
		fetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.DistrictFindMany) return <p className={'font-mona f-900 text-white p-3'}>Districts not found</p>;

	return children(data.DistrictFindMany);
};