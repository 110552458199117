import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {AvatarEditPageCard, AvatarEditPageContainer} from './AvatarEdit.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {mutations} from '../../../actions/mutations';
import {CurriculumFindMany} from '../../../hoc/CurriculumFindMany';
import {uploadArtworkPicture} from '../../../actions/restactions';
import DeleteConfirmationModal from '../../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';

export const AvatarEdit = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('AvatarFindMany'), {
		variables: { filter: { _id: id }, limit: 1 },
	});

	const [avatar, setAvatar] = useState(null);
	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		price: '',
		artwork: {
			url: '',
			type: '',
		},
		type: '',
		curriculum: '',
		createdAt: '',
		updatedAt: '',
	});

	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	useEffect(() => {
		if (data && data.AvatarFindMany.length > 0) {
			const avatarData = data.AvatarFindMany[0];
			setAvatar(avatarData);
			setFormState({
				label: avatarData.label,
				identifier: avatarData.identifier,
				price: avatarData.price.toString(),
				artwork: {
					url: avatarData.artwork?.url || '',
					type: avatarData.artwork?.type || '',
				},
				type: avatarData.type,
				curriculum: avatarData.curriculum?._id || '',
				createdAt: avatarData.createdAt,
				updatedAt: avatarData.updatedAt,
			});
		}
	}, [data]);

	const [updateAvatar, { loading: isLoading }] = useMutation(mutations.AvatarUpdateOne, {
		refetchQueries: ['AvatarFindMany'],
		awaitRefetchQueries: true
	});
	const [deleteAvatar] = useMutation(mutations.AvatarDeleteOne,{
		refetchQueries: ['AvatarFindMany'],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const removeTypename = (obj) => {
		if (Array.isArray(obj)) {
			return obj.map((item) => removeTypename(item));
		} else if (typeof obj === 'object' && obj !== null) {
			const newObj = {};
			for (let key in obj) {
				if (key !== '__typename') {
					newObj[key] = removeTypename(obj[key]);
				}
			}
			return newObj;
		}
		return obj;
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const cleanedFormState = removeTypename(formState);
			await updateAvatar({
				variables: {
					_id: avatar._id,
					...cleanedFormState,
					price: parseInt(cleanedFormState.price),
				},
			});

			if (selectedFile) {
				await uploadArtworkPicture(selectedFile, avatar._id);
			}

			setIsChanged(false);
			history.push('/avatar');
		} catch (error) {
			console.error('Error updating avatar:', error);
		}
	};

	const handleDelete = async () => {
		try {
			await deleteAvatar({
				variables: { _id: avatar._id },
			});
			setIsDeleteModalOpen(false);
			history.push('/avatar');
		} catch (error) {
			console.error('Error deleting avatar:', error);
		}
	};
	const getImageSrc = () => {
		if (preview) {
			return preview;
		} else if (formState.artwork.url) {
			return formState.artwork.url;
		} else {
			return null;
		}
	};

	if (loading) return <h2 className={'font-mona f-900 white p-3'}>Loading...</h2>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error: {error.message}</p>;
	if (!data || data.AvatarFindMany.length === 0) return <p className={'font-mona f-900 white p-3'}>No data available</p>;

	return (
		<AvatarEditPageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Avatar</h1>
			<AvatarEditPageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<label htmlFor='file-upload'
						   className={'mb-4 flex direction-column gap-2 justify-center align-center cursor-pointer'}>
						<div
							className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'flex justify-center align-center p-2 w-44 h-44'}>
								{getImageSrc() ? (
									<img src={getImageSrc()} alt='Preview'
										 className={'rounded-full w-44 h-44 object-cover'}/>
								) : (
									<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
								)}
							</div>
						</div>
						<span className={'font-sans text-text-light dark:text-text-dark active-nav-item-font-color'}>
							Upload Image
						</span>
						<input
							id='file-upload'
							type='file'
							accept='image/*'
							onChange={handleFileChange}
							className={'hidden'}
						/>
					</label>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
						<input
							type='text'
							name='label'
							value={formState.label}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
						<input
							type='text'
							name='identifier'
							value={formState.identifier}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Price</label>
						<input
							type='number'
							name='price'
							value={formState.price}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Type</label>
						<input
							type='text'
							name='type'
							value={formState.type}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Curriculum
							(Optional)</label>
						<CurriculumFindMany>
							{curriculums => (
								<select
									name='curriculum'
									value={formState.curriculum}
									onChange={handleChange}
									className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
								>
									<option value=''>Select a Curriculum</option>
									{curriculums.map(curriculum => (
										<option key={curriculum._id} value={curriculum._id}>
											{curriculum.title}
										</option>
									))}
								</select>
							)}
						</CurriculumFindMany>
					</div>
					<div className={'mb-4 flex direction-column justify-between gap-2'}>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Created
							At: </b>{new Date(formState.createdAt).toLocaleString()}</p>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Updated
							At:</b> {new Date(formState.updatedAt).toLocaleString()}</p>
					</div>
					<button
						type='button'
						onClick={() => setIsDeleteModalOpen(true)}
						className={'font-mono mb-4 px-4 py-2 mt-4 font-medium text-white bg-red-600 rounded hover:bg-red-700'}
					>
						Delete
					</button>
					<div className={'flex align-middle justify-center center'}>
						<button
							type='submit'
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								!isChanged || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={!isChanged || isLoading}
						>
							{isLoading ? 'Updating your Avatar...' : 'Update Avatar'}
						</button>
					</div>
				</form>
				<DeleteConfirmationModal
					isOpen={isDeleteModalOpen}
					setIsOpen={setIsDeleteModalOpen}
					onDelete={handleDelete}
					itemName={formState.label}
				/>
			</AvatarEditPageCard>
		</AvatarEditPageContainer>
	);
};