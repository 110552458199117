import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
	UserCounterTitleTable,
	 UserPageWrapperElements, UserTableMainWrapper, UserTableTHSpan,
} from './Schools.style';
import {Search} from '../../components';
import {SchoolArrow} from './components';
import Button from '../../components/ui/Button';
import Modal from '../../components/ui/Modal/Modal';
import {SchoolForm} from './forms';
import {DistrictForm} from '../District/forms';



export const SchoolsRender = ({ schools }) => {

	const [showCreateForm, setShowCreateForm]= useState(false);
	const [showCreateDistrict, setShowCreateDistrict]= useState(false);

	const handleCreate = ()=>setShowCreateForm(true);
	const handleCreateDistrict =()=> setShowCreateDistrict(true);

	return (
		<div className={'w-full h-full m-2'}>
			{showCreateForm ?
				<Modal isOpen={showCreateForm} setIsOpen={setShowCreateForm}>
					<SchoolForm onSuccess={()=>setShowCreateForm(false)}/>
				</Modal>
				: null
			}
			{showCreateDistrict ?
				<Modal isOpen={showCreateDistrict} setIsOpen={setShowCreateDistrict}>
					<DistrictForm onSuccess={()=>setShowCreateDistrict(false)}/>
				</Modal>
				: null
			}

			<div className={'w-full flex justify-between items-center p-1 mb-3 gap-6'}>
				<div className={'flex gap-4 grow'}>
					<h1 className={'font-sans text-white f-500 text-2xl'}>Schools</h1>
					<Search
						onSearch={() => {}}
						style={{marginLeft: '3rem', maxWidth: '350px'}}
					/>
				</div>

				<Button
					type={'button'}
					className={'font-bold text-sm border-2 border-brightLilac-default'}
					onClick = {handleCreateDistrict}
				> Add District +</Button>
				<Button
					type={'button'}
					color={'bg-brightLilac-default'}
					className={'font-bold text-sm '}
					onClick = {handleCreate}
				> Add School +</Button>
			</div>
			<div className={'flex justify-end items-center mb-3 f-500 '}>
				<UserCounterTitleTable className={'font-mono'}>
					<span>1 - {schools.length} </span>
					<span>of {schools.length}</span>
				</UserCounterTitleTable>
			</div>
			<div className={'w-full mb-4'} style={{height: '1px', border: '1px solid rgba(52, 59, 79, 1)'}}/>
			<UserPageWrapperElements className={''}>
				<UserTableMainWrapper className='mt rounded-lg p-2.5'>
					<thead>
						<tr>
							<th className={'left font-mono main-color-background p-2 t-white'}>Name</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>District</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>State</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Country</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{schools.map((school,index) => (
							<SchoolArrow school={school} index={index} key={school._id}/>
						))}
					</tbody>
				</UserTableMainWrapper>
			</UserPageWrapperElements>
			<div className={'flex justify-end items-center mt-3 mb-3'}>
				<UserCounterTitleTable className={'font-mono'}>
					<span>1 - {schools.length} </span>
					<span>of {schools.length}</span>
				</UserCounterTitleTable>
			</div>
		</div>
	);
};

SchoolsRender.propTypes = {
	schools: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		state: PropTypes.string,
		city: PropTypes.string,
		district: PropTypes.string,
		country: PropTypes.string,
		slug: PropTypes.string,
		deletedAt: PropTypes.string,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string,
		settingsObj: PropTypes.shape({
			_id: PropTypes.string,
			accessWithEmail: PropTypes.bool,
			accessWithClever: PropTypes.bool,
			accessWithClassLink: PropTypes.bool,
			accessWithGoogle: PropTypes.bool,
			deleted: PropTypes.bool,
			createdAt: PropTypes.string,
			updatedAt: PropTypes.string
		})
	})).isRequired
};