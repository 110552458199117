import styled from 'styled-components';

export const DropdownItemsContainer = styled.div`
    display: grid;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
	border-radius: 50%;
	a{
		:hover{
            border-left: 1px solid rgba(203, 60, 255, 1);
		}
	}
	
	span{
		padding-left: 11px;
	}
`;