import config from '../../../tools/config';
import {addHeaders} from '../../../tools';

let url = config.url;

export const uploadCoursesCover = async (file, id) => {
	const formData =  new FormData();
	formData.append('file', file);
	formData.append('id', id);

	try {
		const response = await fetch(`${url}admin/curriculum-cover`,
			{
				method: 'PUT',
				headers: addHeaders({}),
				body: formData,
				credentials: 'include',
				firsTime: false
			});
		return response.json();
	} catch ( err ) {
		console.error('Upload Cover for Courses Error: ', err);
		throw new Error(err);
	}
};

export const UploadCurriculumTrailer = async(file, id) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);

	try {
		const response = await fetch(`${url}admin/curriculum-trailer`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Curriculum Trailer Error: ', err);
		throw new Error(err);
	}
};

export const UploadCoursesTrailerClosedCaptions = async(file, id, available, language) =>{
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);
	formData.append('available', available);
	formData.append('language', language);

	try {
		const response = await fetch(`${url}admin/curriculum-trailer-caption`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload Courses Trailer ClosedCaption Error: ', err);
		throw new Error(err);
	}
};