import styled from 'styled-components';

export const SelectWrapper = styled.select`
    background: rgba(10, 19, 48, 1);
	color: rgba(174, 185, 225, 1);
    border: 1px solid rgba(11, 23, 57, 1)
`;

export const SelectContainer = styled.div`
	h2{
		font-weight: 500;
        color: rgba(174, 185, 225, 1);
    }
`;