const config={
	local:{
		url:'http://localhost:8080/',
	},
	development:{
		url:'https://api-test.blueprint.kids/',
	},
	production:{
		url:'https://api.blueprint.kids/',
	}
};

export default {
	...config[process.env.REACT_APP_ENV.trim()]
};