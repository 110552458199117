import PropTypes from 'prop-types';
import {LifeSkillFindMany, SkillSetFindMany, ThemesFindMany} from '../../../../hoc';
import {CaptionsInput, ImageInput, TagsSelector, TextArea} from '../../../../components';
import {UsersFindMany} from '../../../../hoc';
import React from 'react';
import {LabelVideoContainer, VideoElement, VideoWrapper} from '../../../Courses/CoursesCreate/CoursesCreate.style';
import {PlayIcon} from '../../../../icons/svg/PlayIcon';
import Input from '../../../../components/ui/Input';

export const InfoRender = ({
	formState,
	subtitleState,
	selectedLifeSkills,
	selectedSkillSets,
	selectedThemes,
	isChanged,
	getImageSrc,
	getVideoTrailerSrc,
	handleChange,
	handleFileChange,
	handleTrailerChange,
	handleLifeSkillsChange,
	handleSkillSetsChange,
	handleThemesChange,
	handleRadioChange,
	handleSubmit,
	updateClosedCaptions,
	trailerPreview,
	isLoading,
	isError,
	subtitles,
	refetch,
	handleOnChangeFile
}) => {
	const image =getImageSrc();
	return (
		<form
			className={'p-5 w-100  justify-start align-center gap-7'}
			onSubmit={handleSubmit}
		>
			<Input
				label={'Title'}
				type='text'
				name='title'
				value={formState.title}
				onChange={handleChange}
			/>
			<div className={'grid grid-cols-2 gap-4'}>

				<ImageInput
					className={'rounded-md overflow-hidden'}
					defaultImage={image}
					onChange={handleFileChange}
				/>
				<div>
					<TextArea
						label={'Description'}
						name='description'
						value={formState.description}
						onChange={handleChange}
					/>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Teacher</label>
						<UsersFindMany>
							{users => (
								<select
									name='teacher'
									value={formState.teacher}
									onChange={handleChange}
									className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
								>
									<option value=''>Select a Teacher</option>
									{users.map(user => (
										<option key={user.id} value={user.id}>
											{user.firstName} {user.lastName}
										</option>
									))}
								</select>
							)}
						</UsersFindMany>
					</div>

				</div>

			</div>

			<div className={'w-100 flex mb-4 direction-column justify-start align-center self-start'}>
				<label className={'font-sans ml-3 text-text-light dark:text-text-dark self-start'}>Set
					Status in your Curriculum:</label>
			</div>

			<div className={'flex justify-center align-center gap-5 mb-4'}>
				<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Active</label>
				<input
					type='radio'
					name='active'
					value='true'
					checked={formState.active === true}
					onChange={handleRadioChange}
					className={'font-mono p-5'}
				/>
				<label className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Inactive</label>
				<input
					type='radio'
					name='active'
					value='false'
					checked={formState.active === false}
					onChange={handleRadioChange}
					className={'font-mono p-5'}
				/>
			</div>
			<div className={'grid grid-cols-2 gap-4'}>
				<Input
					label={'slug'}
					type='text'
					name='slug'
					value={formState.slug}
					onChange={handleChange}
				/>
				<Input
					label='Category'
					type='text'
					name='animalCategory'
					value={formState.animalCategory}
					onChange={handleChange}
				/>

				<TextArea
					label='Leader Bio'
					name='leaderBio'
					value={formState.leaderBio}
					onChange={handleChange}
				/>

				<TextArea
					label={'Learning Goal'}
					name='learningGoal'
					value={formState.learningGoal}
					onChange={handleChange}
				/>
			</div>

			<div className='flex justify-center align-center self-center'>
				<div className='mb-4 flex direction-column gap-2 justify-center align-center'>
					<LabelVideoContainer htmlFor='trailer-upload'>
						<VideoWrapper>
							{getVideoTrailerSrc() ? (
								<VideoElement controls
									   className='w-full object-cover aspect-square cursor-pointer rounded border border-text-dark'>
									<source src={getVideoTrailerSrc()}/>
									{subtitles && subtitles.map((subtitle, index) => (
										<track
											key={index}
											label={subtitle.label || 'Subtitles'}
											kind='subtitles'
											srcLang={subtitle.language || 'en'}
											src={subtitle.url}
											default={index === 0}
										/>
									))}
								</VideoElement>
							) : (
								<div
									className='w-100 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'>
									<div className='flex justify-center align-center p-2 w-44 h-44 overflow-hidden'>
										<PlayIcon width='3em' height='3em' className='cursor-pointer'/>
									</div>
								</div>
							)}
						</VideoWrapper>
						<span
							className='font-sans f-600 mt-3 text-center text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'>
							{trailerPreview ? 'Edit Trailer' : 'Upload Trailer'}
						</span>
						<input
							id='trailer-upload'
							type='file'
							accept='video/mp4'
							onChange={handleTrailerChange}
							className='hidden'
						/>
					</LabelVideoContainer>
				</div>
			</div>

			<CaptionsInput
				captions={formState.trailer.captions}
				onChangeFile={handleFileChange}
				subtitleLanguage={formState.language}
			/>
			
			<TagsSelector
				selectedTags={selectedLifeSkills}
				setSelectedTags={handleLifeSkillsChange}
				HOC={LifeSkillFindMany}
				label='Life Skills'
			/>
			<TagsSelector
				selectedTags={selectedSkillSets}
				setSelectedTags={handleSkillSetsChange}
				HOC={SkillSetFindMany}
				label='Skill Sets'
			/>
			<TagsSelector
				selectedTags={selectedThemes}
				setSelectedTags={handleThemesChange}
				HOC={ThemesFindMany}
				label='Themes'
			/>
			<div className={'mt-4 mb-4 flex direction-column align-center justify-between gap-2'}>
				<p className={'font-mono text-text-light dark:text-text-dark'}>
					<b>Created At: </b>{new Date(formState?.createdAt).toLocaleString()}
				</p>
				<p className={'font-mono text-text-light dark:text-text-dark'}>
					<b>Updated At: </b>{new Date(formState?.updatedAt).toLocaleString()}
				</p>
			</div>
			<div className={'flex align-middle justify-center center'}>
				<button
					onClick={handleSubmit}
					className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
						!isChanged || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
					} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
					disabled={!isChanged || isLoading}
				>
					{isLoading ? 'Loading...' : 'Update'}
				</button>
				{isError && <p className={'text-red-500'}>{isError.message ? isError.message : 'An error occurred'}</p>}
			</div>

		</form>
	);
};

InfoRender.propTypes = {
	formState: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		teacher: PropTypes.string,
		slug: PropTypes.string,
		animalCategory: PropTypes.string,
		leaderBio: PropTypes.string,
		learningGoal: PropTypes.string,
		active: PropTypes.bool.isRequired,
		cover: PropTypes.shape({
			url: PropTypes.string,
			type: PropTypes.string
		}),
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired
	}).isRequired,
	subtitleState: PropTypes.shape({
		file: PropTypes.string,
		error: PropTypes.string,
		loading: PropTypes.bool
	}),
	selectedLifeSkills: PropTypes.array.isRequired,
	selectedSkillSets: PropTypes.array.isRequired,
	selectedThemes: PropTypes.array.isRequired,
	isChanged: PropTypes.bool.isRequired,
	getImageSrc: PropTypes.func.isRequired,
	getVideoTrailerSrc: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	handleTrailerChange: PropTypes.func.isRequired,
	handleLifeSkillsChange: PropTypes.func.isRequired,
	handleSkillSetsChange: PropTypes.func.isRequired,
	handleThemesChange: PropTypes.func.isRequired,
	handleRadioChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isError: PropTypes.object,
	subtitles: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		language: PropTypes.string,
		url: PropTypes.string
	})),
	refetch: PropTypes.func.isRequired,
};