import {BadgesRender} from './BadgesRender';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';

export const BadgesLoad = () => {
	const { loading, error, data, refetch } = useQuery(query('badgesFindMany'), {
		variables: { },
		nextFetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;
	if (!data || !data.BadgesFindMany) return <p className={'font-mona f-900 white p-3'}>No Data</p>;

	return <BadgesRender badges={data ? data.BadgesFindMany : []} />;
};