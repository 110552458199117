import React, {useState} from 'react';
import {OrganizationFindOneByUser} from '../../../../hoc';
import {AddOrganizationToUser} from '../../forms';
import PropTypes from 'prop-types';
import Button from '../../../../components/ui/Button';

export const Organizations = ({userData}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const handleAddOrganization = (user) => {
		setCurrentUser(user);
		setIsModalOpen(true);
	};

	if(userData.type !== 'e4475192-3c69-11ee-be56-0242ac120002') {
		return (
			<div className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
				<h2 className={'mb-4'}>You need to be a Teacher to add an Organization. Back to users</h2>
			</div>
		);
	}

	return (
		<div className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
			<h2 className={'mb-4'}>Organizations Information: </h2>
			<div className={'flex justify-start items-start direction-column gap-1 cursor-pointer'}>
				<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
					<span className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
						<OrganizationFindOneByUser _id={userData._id} organization={userData.organization} onAddSchool={handleAddOrganization}>
							{({organizationObj}) => (
								<div>
									<p className={'text-sm f-600 text-start'}>Name: {organizationObj?.name}</p>
									<p className={'text-sm f-600 text-start'}>Code: {organizationObj?.code}</p>
								</div>
							)}
						</OrganizationFindOneByUser>
					</span>
				</div>
				<Button
					disabled={!userData.organization}
					onClick={() => handleAddOrganization(userData)}
					className={`${!userData.organization ? 'bg-purple-400' : 'bg-brightLilac-default'} mt-4`}
				>
					Edit
				</Button>
			</div>
			{isModalOpen && currentUser && (
				<AddOrganizationToUser
					isOpenState={[isModalOpen, setIsModalOpen]}
					currentUser={currentUser}
					refetch={() => history.go(0)}
					currentUserId={currentUser}
				/>
			)}
		</div>
	);
};

Organizations.propTypes = {
	userData: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		profilePicture: PropTypes.shape({
			url: PropTypes.string,
		}),
		type: PropTypes.string.isRequired,
		organization: PropTypes.string,
	}).isRequired,
};
