import React, { useReducer } from 'react';
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import { LOGIN_SUCCESSFUL, LOGIN_ERROR, LOGOUT } from '../types';

const AuthState = props => {
	const initialState = {
		token : localStorage.getItem('token'),
		auth : !!localStorage.getItem('token'),
	};

	const [state, dispath] = useReducer(AuthReducer, initialState);

	const authUser = token => {
		dispath({
			type : LOGIN_SUCCESSFUL,
			payload: token
		});
	};

	const logoutUser = () => {
		dispath({
			type : LOGOUT
		});
	};

	return (
		<AuthContext.Provider value={{ token: state.token, auth: state.auth, authUser, logoutUser }}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthState;