import React,{useState, useContext} from 'react';
import config from '../../tools/config';
import {login} from '../../actions/restactions';
import AuthContext from '../../contexts/auth/AuthContext';
import Input from '../../components/ui/Input';
import {withRouter} from 'react-router-dom';

export function Login({history}){
	const authContext = useContext(AuthContext);
	const {authUser} = authContext;
	const [form,setForm]=useState({});
	const [isLoading ,setIsLoading] = useState(false);
	const [error,setError] = useState('');

	if (!document.documentElement.classList.contains('dark')) {
		document.documentElement.classList.add('dark');
	}

	const send=()=>{
		let aux={password:form.password,email:form.email.toLowerCase()};
		setIsLoading(true);
		login(aux)
		.then(res=>{
			authUser(res['access-token']);
			history.push('/users');
		})
		.catch(err => {
			console.log(err);
			setError(err.message);
		})
		.finally(()=>setIsLoading(true));
	};

	return (
		<div className='w-full h-[80vh] flex justify-center items-center  p-3 mt-3'>
			<div className='flex flex-col items-center px-4 py-8 br w-[25rem] bg-midnight-light'
				 style={{background:'rgba(11, 23, 57, 1)', borderRadius:'12px', border:'1px solid rgba(52, 59, 79, 1)'}}>
				<h1 className='font-sans f-700 center mb-4 text-text-light dark:text-text-dark' style={{fontSize:'1.7rem'}}>Login</h1>
				<Input
					type='text'
					placeholder='Email'
					className={'w-full'}
					onChange={(e)=>setForm({...form,email:e.target.value.toLowerCase()})}
				/>

				<Input
					type='password'
					className={'w-full bg'}
					placeholder='Password'
					onChange={(e)=>setForm({...form,password:e.target.value})}
				/>


				<button
					disabled={isLoading}
					onClick={send}
					   className={'font-mono mt-2 px-4 py-2 font-medium ' +
						   'text-white rounded bg-gradient-to-r from-brightLilac-default to-indigo-default ' +
						   'disabled:opacity-40'}
				>
					{!isLoading ? 'Login' : 'loading...' }
				</button>
				{error?<p className={'text-red-500'}> {error} </p> : null}
			</div>
		</div>
	);
}

export default withRouter(Login);