import {ThemesEditPageCard, ThemesEditPageContainer} from './ThemeEdit.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {query} from '../../../actions/queries';
import {useMutation, useQuery} from '@apollo/client';
import {mutations} from '../../../actions/mutations';

export const ThemesEdit = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('themeFindOne'), {
		variables: { id },
	});

	const [theme, setTheme] = useState({
		_id: '',
		label: '',
		identifier: '',
		description: '',
		createdAt: '',
		updatedAt: '',
		deletedAt: ''
	});

	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		description: '',
	});
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (data) {
			setTheme(data.ThemeFindOne);
			setFormState({
				label: data.ThemeFindOne.label || '',
				identifier: data.ThemeFindOne.identifier || '',
				description: data.ThemeFindOne.description || ''
			});
		}
	}, [data]);

	const [updateTheme] = useMutation(mutations.themeUpdateOne);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await updateTheme({
				variables: {
					id: theme._id,
					record: {
						label: formState.label,
						identifier: formState.identifier,
						description: formState.description,
						icon: formState.icon
					}
				}
			});
			setIsChanged(false);
			history.push('/themes');
		} catch (error) {
			console.error('Error updating theme:', error);
		}
	};

	if (loading) return <h2>Loading...</h2>;
	if (error) return <p>Error: {error.message}</p>;

	return (
		<ThemesEditPageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Theme</h1>
			<ThemesEditPageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-row justify-start align-center gap-7'} onSubmit={handleSubmit}>
					<div className={'flex self-start'}>
						<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
							<div
								className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
								<div className={'flex justify-center align-center p-2 w-44 h-44'}>
									{
										theme.icon?.url || theme.icon?.url === undefined || theme.icon?.url === null || theme.icon?.url === '' ?
											<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
											:
											<img src={theme.icon?.url} alt={theme?.identifier}
												 className={'rounded-full w-44 h-44 object-cover'}/>
									}
								</div>
							</div>
							<span
								className={'font-sans ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>Change Image</span>
						</div>
					</div>
					<div className={'w-100'}>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
							<input
								type='text'
								name='label'
								value={formState.label}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
							<input
								type='text'
								name='identifier'
								value={formState.identifier}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
							<textarea
								name='description'
								value={formState.description}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 flex direction-column justify-between gap-2'}>
							<p className={'font-mono text-text-light dark:text-text-dark'}>
								<b>Created At: </b>{new Date(theme.createdAt).toLocaleString()}
							</p>
							<p className={'font-mono text-text-light dark:text-text-dark'}>
								<b>Updated At: </b>{new Date(theme.updatedAt).toLocaleString()}
							</p>
							{theme.deletedAt && (
								<p className={'font-mono text-red-500'}>
									<b>Deleted At: </b>{new Date(theme.deletedAt).toLocaleString()}
								</p>
							)}
						</div>
						<button
							type='submit'
							disabled={!isChanged}
							className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${
								!isChanged ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							}`}
						>
							Update
						</button>
					</div>
				</form>
			</ThemesEditPageCard>
		</ThemesEditPageContainer>
	);
};