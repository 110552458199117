import React from 'react';
import Input from '../../../../components/ui/Input';
import PropTypes  from 'prop-types';

export const ResponseForm=({isLoading,formState,question,onSubmit,handleChange,close})=>{
	
	return (
		<div className={'w-400px flex flex-col gap-4'}>

			<h1 className={'text-2xl'}>Add response</h1>

			<div className='mb-2'>
				Question:
				<span
					dangerouslySetInnerHTML={{__html: question.label}}
					className={'ml-2'}
				/>
			</div>
			
			<form className={'pb-2'} onSubmit={onSubmit}>
				<Input value={formState.label} name={'label'} type='text' onChange={handleChange}
					   placeholder='Type your response'/>
				<Input value={formState.identifier} name={'identifier'} type='text' onChange={handleChange}
					   placeholder='set a unique identifier for this response'/>
				<div className={'pt-2 flex gap-2'}>
					<button
						type='button'
						onClick={close}
						className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}
					>
						cancel
					</button>

					<button
						disabled={isLoading}
						type='submit'
						className={'font-mono px-2 py-0.5  text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
					>
						{isLoading ? 'loading...' : 'save'}
					</button>
				</div>
			</form>
		</div>
	);
};

ResponseForm.prototype = {
	isLoading: PropTypes.bool.isRequired,
	formState: PropTypes.shape({
		label: PropTypes.string.isRequired,
		identifier:PropTypes.string.isRequired,
	}),
	handleChange:PropTypes.func.isRequired,
	onSubmit:PropTypes.func.isRequired,
	close:PropTypes.func.isRequired,
};