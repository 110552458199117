import {useMutation} from '@apollo/client';
import {mutations} from '../../../../actions/mutations';
import {QuestionForm} from './QuestionForm';
import {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal/Modal';

export const QuestionFormLoad = ({question,points,isOpen,setIsOpen,onEdit,onCreate})=>{

	const defaultsValues ={
		label: question?.label ||'',
		identifier: question?.identifier ||'',
		points:points||undefined,
	};

	const [stateForm,setStateForm] = useState(defaultsValues);

	const [ createQuestion, {loading : createLoading} ] = useMutation(mutations.questionsCreateOne);
	const [ updateQuestion, {loading : updateLoading } ] = useMutation(mutations.questionsUpdateOne);

	const handleChange = (e)=>setStateForm(prev=>(
		{...prev,[e.target.name]:e.target.value}
	));

	const handleSuccess=()=>{
		setIsOpen(false);
		setStateForm(defaultsValues);
	};

	const handleUpdateQuestion =async()=>{
		console.log({stateForm});
		if(stateForm.label && stateForm.points){
			const newQuestion = { question:question?._id, points: stateForm.points };

			try {
				await Promise.all([
					updateQuestion({
						variables: {
							id:question._id,
							record: {
								label:stateForm.label,
								identifier:stateForm.identifier
							}
						}
					})
					,
					stateForm.points !== points && onEdit &&
					onEdit(newQuestion)
				]);
				handleSuccess();

			} catch (error) {
				console.error('Error creating QuestionsView', error);
			}
		}
	};
    
	const handleCreateQuestion =async()=>{
		if(stateForm.label && stateForm.points){
			try {
				const result = await createQuestion({
					variables: { record: {
						label:stateForm.label,
						identifier:stateForm.identifier
					}}
				});
				const recordId = result.data.QuestionsCreateOne.recordId;
				onCreate && await onCreate({question:recordId,points:stateForm.points||0});
				handleSuccess();

			} catch (error) {
				console.error('Error creating QuestionsView', error);
			}
		}
	};
    
	const onSubmit = (e)=>{
		e.preventDefault();
		question ? handleUpdateQuestion() : handleCreateQuestion();
	};
    
	return(
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<QuestionForm
				isLoading={updateLoading||createLoading}
				question={question}
				stateForm={stateForm}
				handleChange={handleChange}
				onSubmit={onSubmit}
				close={()=>setIsOpen(false)}
			/>
		</Modal>
	);
};
QuestionFormLoad.prototype={
	question: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		question: PropTypes.string.isRequired,
		points: PropTypes.string.isRequired,
	}).isRequired,
	onSubmit:PropTypes.func.isRequired,
	onEdit:PropTypes.func.isRequired,
	onCreate:PropTypes.func.isRequired,
};