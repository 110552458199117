import {StickerCreateRender} from './StickerCreateRender';
import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {query} from '../../../actions/queries';
import {mutations} from '../../../actions/mutations';
import {uploadStickerCover, uploadStickerVideo} from '../../../actions/restactions';

export const StickerCreateLoad = () => {
	const history = useHistory();
	const [formState, setFormState] = useState({
		label: '',
		deedlyVault: ''
	});

	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);

	const [createSticker] = useMutation(mutations.StickerCreateOne, {
		refetchQueries: [{ query: query('stickerFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createSticker({
				variables: {
					record: {
						label: formState.label,
						deedlyVault: formState.deedlyVault
					}
				}
			});

			const createdStickerId = data.StickerCreateOne.recordId;

			if (selectedFile) {
				await uploadStickerCover(selectedFile, createdStickerId);
			}

			if (videoFile) {
				await uploadStickerVideo(videoFile, createdStickerId);
			}

			setIsChanged(false);
			history.push('/stickers');
		} catch (error) {
			console.error('Error creating sticker:', error);
		}
	};

	const allFieldsFilled = Boolean(formState.label && formState.deedlyVault);

	return (
		<StickerCreateRender
			formState={formState}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleVideoChange={handleVideoChange}
			handleSubmit={handleSubmit}
			preview={preview}
			videoPreview={videoPreview}
			allFieldsFilled={allFieldsFilled}
		/>
	);
};