import {svgPropTypes} from '../../types';


export const PencilIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
	 <svg xmlns='http://www.w3.org/2000/svg'
		  width={width}
		  height={height}
		  viewBox='0 0 13 14'
		  fill='none'
		  className={className}
		  style={{...style}}
	 >
		 <path
			 d='M11.0947 1.99892C10.9244 1.82932 10.7221 1.69516 10.4997 1.60417C10.2772 1.51318 10.0389 1.46716 9.79856 1.46879C9.55822 1.47042 9.32057 1.51965 9.09936 1.61365C8.87815 1.70764 8.67776 1.84453 8.50975 2.01642L1.64715 8.87912C1.3937 9.13258 1.21301 9.44949 1.12401 9.79671L0.542819 12.0641C0.467311 12.3586 0.735111 12.6264 1.02967 12.5508L3.29714 11.9693C3.64426 11.8802 3.96108 11.6996 4.21448 11.4462L11.0772 4.58338C11.2491 4.41545 11.386 4.2151 11.4801 3.99392C11.5741 3.77275 11.6233 3.53512 11.625 3.2948C11.6266 3.05447 11.5806 2.8162 11.4895 2.59377C11.3985 2.37134 11.2643 2.16916 11.0947 1.99892Z'
			 fill={fillColor} stroke='#AEB9E1' strokeLinecap='round' strokeLinejoin='round'/>
	 </svg>
	);
};

PencilIcon.propTypes = svgPropTypes;

PencilIcon.defaultProps = {
	width: '13',
	height: '14',
	background: 'transparent',
	className: '',
	style: {},
	isActive: false,
};

export default PencilIcon;