import {svgPropTypes} from '../../types';


export const PlayIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 16 16'
			 className={className}
			 style={{...style}}
			 fill={background? background : fillColor}
		>
			<path d='M15.2139 8.14041L3.9533 14.6417L3.9533 1.63911L15.2139 8.14041Z'
				  fill='white'
				  stroke='black'
				  strokeWidth='0.65'
				  strokeLinejoin='round'/>
			<line x1='6.18188' y1='11.1124' x2='8.14441' y2='10.0557' stroke='black' strokeWidth='0.65'
				  strokeLinecap='round'/>
		</svg>
	);
};

PlayIcon.propTypes = svgPropTypes;

PlayIcon.defaultProps = {
	width: '13',
	height: '13',
	background: '#AEB9E1',
	className: '',
	style: {},
	isActive: false,
};

export default PlayIcon;