import { LOGIN_SUCCESSFUL, LOGIN_ERROR, LOGOUT } from '../types';

export default (state, action) => {
	switch (action.type) {case LOGIN_SUCCESSFUL:
		localStorage.setItem('token', action.payload);
		return {
			token: localStorage.getItem('token'),
			auth: true
		};
		case LOGOUT:case LOGIN_ERROR:
			localStorage.removeItem('token');
			return {
				token: null,
				auth: false
			};
		default:
			return state;}
};