import {SchoolUserFindOneByUser} from '../../../../hoc';
import React, {useState} from 'react';
import {AddSchoolToUser} from '../../forms';


export const TeamRender = ({userData}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const handleAddSchoolClick = (user) => {
		setCurrentUser(user);
		setIsModalOpen(true);
	};
	if(userData.type !== 'e4475192-3c69-11ee-be56-0242ac120002') {
		return (
			<div className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
				<h2 className={'mb-4'}>You need to be a Teacher to add a School. Back to users</h2>
			</div>
		);
	}

	return (
		<div className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
			<h2 className={'mb-4'}>Schools Information: </h2>
			<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
				<span className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
					<SchoolUserFindOneByUser user={userData._id} onAddSchool={handleAddSchoolClick}>
						{({schoolObj}) => (
							<div>
								<p className={'text-sm f-600 text-start'}>School: {schoolObj?.name}</p>
								<p className={'text-sm f-600 text-start'}>City: {schoolObj?.city}</p>
							</div>
						)}
					</SchoolUserFindOneByUser>
				</span>
			</div>
			{isModalOpen && currentUser && (
				<AddSchoolToUser
					isOpenState={[isModalOpen, setIsModalOpen]}
					currentUser={currentUser}
					refetch={() => history.go(0)}
					currentUserId={currentUser}
				/>
			)}
		</div>
	);
};