import styled from 'styled-components';

export const MenuTitle = styled.h1`
	font-size: 1.5rem;
    font-weight: 900;
	margin-bottom: 40px;
`;

export const UserSettingsInfo = styled.div`
	
	span:first-child{
		font-size: 0.75rem;
		color: rgba(255, 255, 255, 1);
		font-weight: 500;
        max-width: 150px;
        overflow: hidden;
    }
	
	span:last-child{
		font-size: 0.78rem;
		font-weight: 500;
		color: rgba(174, 185, 225, 1);
        max-width: 150px;
        overflow: hidden;
    }
	
	div:last-child{
		svg{
			align-self: end;
			margin-bottom: 0.45rem;
		}
	}
	
	img, svg{
        border-radius: 50%;
        max-width: 1.75rem;
        max-height: 1.75rem;
        min-height: 1.75rem;
        min-width: 1.75rem;
        object-fit: cover;
		margin-right: 7px;
	}
`;
