import styled from 'styled-components';

export const ThemesEditPageCard = styled.div`
    border-radius: 12px;
    background: rgba(11, 23, 57, 1);
    border: 1px solid rgba(52, 59, 79, 1);
	
	form:first-child{
		div:first-child{
			div:first-child{
				div:first-child{
                    border-radius: 50%;
                    padding: 0.2rem;
                    background-color: rgba(52, 59, 79, 1);
				}
			}
		}
	}
	span{
		font-size: 0.752rem;
	}
`;


export const ThemesEditPageContainer = styled.div`
	h1,label, b{
		color: white !important;
	}
	form{
        input, textarea{
            border: 1px solid rgba(52, 59, 79, 1);
        }
	}
`;