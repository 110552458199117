import React from 'react';
import {useHistory} from 'react-router-dom';
import {query} from '../../actions/queries';
import {useQuery} from '@apollo/client';
import {CurriculumFindOne} from '../../hoc';
import {ArrowIndicatorIcon, CheckboxIcon, FilterDataIcon, PencilIcon, TrashIcon} from '../../icons';
import {
	LessonsPageWrapperElements,
	LessonsTableMainWrapper,
	StyledTableRow, UserTableSpan,
	UserTableTHSpan,
	UserTableUserInfo
} from './Lessons.style';

export const Lessons=()=>{
	const history= useHistory();
	const {loading,error,data}=useQuery(query('classesAdminFindMany'),
		{variables:{ limit: 100},
			nextFetchPolicy: 'network-only'}
	);

	if(loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;

	if(error) return <p className={'font-mona f-900 text-white p-3'}>Error</p>;

	return (
		<div className={'w-100 h-100 m-2'}>
			<div className='flex justify-between align-center mb-5'>
				<h1 className='f-700 shrinks text-white ml-3 mr-3'>Classes</h1>
				<button
					style={{maxWidth: '340px'}}
					onClick={() => history.push('lessons/create')}
					className={`button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default`}
				>
					Create
				</button>
			</div>

			<LessonsPageWrapperElements className={''}>
				<div className={'flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'}>
					<h4 className='font-sans text-white'>All Classes</h4>
					<div className={'font-mono text-white'}>
						<span>1 - 10 </span>
						<span>of {data.ClassesAdminFindMany.length}</span>
					</div>
				</div>
				<LessonsTableMainWrapper className='mt rounded-lg p-2.5'>
					<thead>
						<tr>
							<th className={'main-color-background p-4'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<CheckboxIcon className={'flex justify-center items-center'} />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Title</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Description</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Curriculum</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Trailer</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Free</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Extra</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Order</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Deleted</UserTableTHSpan>
									<FilterDataIcon />
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-stretch items-center gap-1 cursor-pointer'}>
									<UserTableTHSpan className={'font-mona'}>Edit</UserTableTHSpan>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{data && data.ClassesAdminFindMany.map(({ _id, trailer, title, description, cover, curriculum, free, extra, deleted, order }, _) => (
							<StyledTableRow key={_id} id={_id} onClick={() => history.push(`/lessons/${_id}/info`)}>
								<td className={'p-4 cursor-pointer'}>
									<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
										<CheckboxIcon className={'flex justify-center items-center'} />
									</div>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableUserInfo className={'flex justify-start items-center p-1 gap-2'}>
										<div>
											<img
												src={cover ? cover.url : 'https://cdna.artstation.com/p/assets/images/images/029/284/552/large/alexandre-augusto-agumon.jpg?1597090850'}
												alt={title}
												className={'rounded-full w-10rem h-7rem object-cover'}
											/>
										</div>
										<div>
											<div className={'grid-row038'}>
												<span className={'font-mono'}>{title}</span>
											</div>
										</div>
									</UserTableUserInfo>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}>{description ? description : 'No description yet!'}</UserTableSpan>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}><CurriculumFindOne id={curriculum}/></UserTableSpan>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}>{trailer.toString()}</UserTableSpan>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}>{free.toString()}</UserTableSpan>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}>{extra.toString()}</UserTableSpan>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}>{order}</UserTableSpan>
								</td>
								<td className={'p-2 cursor-pointer'}>
									<UserTableSpan className={'font-sans'}>{deleted.toString()}</UserTableSpan>
								</td>
								<td className={'p-2'}>
									<div className={'flex justify-start items-center gap-1 cursor-pointer'}>
										<PencilIcon />
										<TrashIcon />
									</div>
								</td>
							</StyledTableRow>
						))}
					</tbody>
				</LessonsTableMainWrapper>
			</LessonsPageWrapperElements>
			<div className={'flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4 text-white'}>
				<div className={'font-mono'}>
					<span>1 - 10 of {data.ClassesAdminFindMany.length}</span>
				</div>
				<div className={'flex items-center gap-1'}>
					<div className={'flex justify-center items-center cursor-pointer'}>
						<ArrowIndicatorIcon className={'transform rotate-180'} />
					</div>
					<div className={'flex justify-center items-center cursor-pointer'}>
						<ArrowIndicatorIcon />
					</div>
				</div>
			</div>
		</div>
	);
};