import {DocumentForm} from './DocumentForm';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal/Modal';

export const DocumentFormLoad = ({id,isOpen,setIsOpen, uploadDocument})=>{
	const defaultForm = {
		title:'',
		file:undefined
	};

	const [stateForm,setStateForm] =useState({...defaultForm});
	const [isLoading, setIsLoading] = useState(false);
	const handleChange = (e)=>  e.target.type === 'file'?
		setStateForm(prev=>({...prev,[e.target.name]:e.target.files[0]}))
		:
		setStateForm(prev=>({...prev,[e.target.name]:e.target.value}))
	;

	useEffect(() => {
		!isOpen && setStateForm({...defaultForm});
	}, [isOpen]);


	const handleUploadDocument=  ({title, file}) => {
		console.log({title,file});
		setIsLoading(true);
		uploadDocument(file, id, title)
		.then(()=>setIsOpen(false))
		.catch(e=>console.log({e}))
		.finally(()=>setIsLoading(false));
	};
	
	const onSubmit = (e)=>{
		e.preventDefault();
		if( stateForm.title && stateForm.file ){
			handleUploadDocument(stateForm);
		}
	};
    
	return(
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<DocumentForm
				isLoading={isLoading}
				stateForm={stateForm}
				handleChange={handleChange}
				onSubmit={onSubmit}
				close={()=>setIsOpen(false)}
			/>
		</Modal>
	);
};
DocumentFormLoad.prototype={
	id: PropTypes.string.isRequired,
	onCreate:PropTypes.func.isRequired,
	uploadDocument:PropTypes.func.isRequired,
};