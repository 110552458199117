import Button from '../ui/Button';
import Modal from '../ui/Modal/Modal';
import PropTypes from 'prop-types';

export const DangerousActionConfirmModal =({title,label, isOpen,setIsOpen,dangerousAction})=>{

	return(
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<div className={'w-400px flex flex-col gap-4'}>
				<h1 className={'text-2xl'}>{title}</h1>
				<p>{label}</p>
				<div className={'flex'}>
					<Button className={'bg-brightLilac-default'} type={'button'} onClick={()=>setIsOpen(false)}> cancel </Button>
					<Button className={''} type={'button'} onClick={dangerousAction}> Confirm </Button>
				</div>
			</div>
		</Modal>
	);  
};

DangerousActionConfirmModal.prototype ={
	title:PropTypes.string.isRequired,
	label:PropTypes.string.isRequired,

	isOpen:PropTypes.bool.isRequired,
	setIsOpen:PropTypes.func.isRequired,
	dangerousAction:PropTypes.func.isRequired
};
