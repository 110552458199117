import {Response} from './Response';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {DangerousActionConfirmModal} from '../../../../components';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../../actions/mutations';
import {ResponseForm} from '../ResponseForm';

export const ResponseLoad =({response,question,refetchResponses})=>{
	const [isOpenForm,setISOpenForm] = useState(false);
	const [isOpenDelete,setISOpenDelete] = useState(false);

	const openDelete = ()=> setISOpenDelete(true);
	const openForm =()=> setISOpenForm(true);

	const [ questionUpdate, {loading:questionLoading} ] = useMutation(mutations.questionsUpdateOne, {
		refetchQueries: ['responsesFindMany'],
		awaitRefetchQueries: true,
	});

	const handleChangeCorrectResponse = (response)=>{
		questionUpdate({
			variables:{id:question._id, record:{rightAnswers:[response._id]}}
		})
		.catch((e)=>console.log({e}));
	};
	
	const handleDelete = ()=>{
		const newResponses = question.responses.filter(q=>q !== response._id);
		questionUpdate({
			variables:{id:question._id, record:{responses:newResponses}}
		})
		.then(()=>setISOpenDelete(false))
		.catch(e=>console.log({e}));
	};

	return(
		<>
			<Response
				response={response}
				isCorrectAnswer={question.rightAnswers.includes(response._id)}
				setAsCorrectAnswer={handleChangeCorrectResponse}
				openForm={openForm}
				openDelete={openDelete}
			/>
			<DangerousActionConfirmModal
				title={'Delete response'}
				label={response?.label}
				isOpen={isOpenDelete}
				setIsOpen={setISOpenDelete}
				dangerousAction={handleDelete}
			/>
			<ResponseForm
				isOpen={isOpenForm}
				setIsOpen={setISOpenForm}
				refetchResponses={refetchResponses}
				response={response}
				question={question}
			/>
		</>
	);
};

ResponseLoad.prototype={
	response:PropTypes.shape({
		_id:PropTypes.string.isRequired,
		label:PropTypes.string.isRequired,
		identifier:PropTypes.string.isRequired,
	}),
	question:PropTypes.shape({
		_id:PropTypes.string.isRequired,
		label:PropTypes.string.isRequired,
		identifier:PropTypes.string.isRequired,
	}),
};