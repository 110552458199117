import Modal from '../../../../components/ui/Modal/Modal';
import Input from '../../../../components/ui/Input';
import Select from '../../../../components/ui/Select';
import Button from '../../../../components/ui/Button';
import {CKEditorInput, ImageInput, TextArea} from '../../../../components';

export const CreateSparkLibraryFormRender = ({isEdition, isLoading, values,isOpenState,errors, lessonsOptions, curriculumsOptions, handleOnChange,handleOnChangeFile, onSubmit}) =>{
	const [isFormOpen,setIsFormOpen] = isOpenState;

	return (
		<Modal
			isOpen={isFormOpen}
			setIsOpen={setIsFormOpen}
		>
			<div className={'w-600px flex flex-col items-center w-full pb-2'}>
				<h2>Create Spark Library</h2>
				<form
					className={'w-full items-center gap-2'}
					onSubmit={onSubmit}
				>
					<Input
						className={'w-full'}
						label={'Title'}
						placeHolder={'Add a title'}
						name='title'
						defaultValue={values?.title}
						onChange={handleOnChange}
					/>
					<div className={'grid grid-cols-2 gap-4'}>
						<ImageInput
							label={'Cover '}
							imageSize={'w-[8rem]'}
							className={'justify-center'}
							onChange={handleOnChangeFile}
							defaultImage={values?.cover}
						/>
						<div>
							<Input
								label='Slug'
								className={'w-full'}
								name='slug'
								onChange={handleOnChange}
								defaultValue={values?.slug}
							/>
							<TextArea
								className={'w-full'}
								label={'Description'}
								name='description'
								onChange={handleOnChange}
								defaultValue={values?.description}
							/>
						</div>
						<Select
							label='Lesson'
							options={lessonsOptions}
							className={'w-full'}
							name='lesson'
							onChange={handleOnChange}
							defaultValue={values?.lesson}
						/>
						<Select
							label='curriculum'
							options={curriculumsOptions}
							className={'w-full'}
							name='curriculum'
							onChange={handleOnChange}
							defaultValue={values?.curriculum}
						/>
					</div>

					<div className={'grid grid-cols-2 gap-4'}>
						<div>
							<Input
								label='TakeAway label'
								className={'w-full'}
								name='takeAwayLabel'
								onChange={handleOnChange}
								defaultValue={values?.takeAwayLabel}
							/>
							<TextArea
								label={'TakeAway description'}
								className={'w-full'}
								name='takeAwayDescription'
								onChange={handleOnChange}
								defaultValue={values?.takeAwayDescription}
							/>
						</div>
						<div>
							<Input
								label='Article title'
								className={'w-full'}
								name='articleTitle'
								onChange={handleOnChange}
								defaultValue={values?.articleTitle}
							/>
						</div>
					</div>
					<CKEditorInput
						label={'Article body'}
						className={'w-full'}
						name='articleBody'
						onChange={handleOnChange}
						defaultValue={values?.articleBody}
					/>
					<Button
						disabled={isLoading}
						type={'submit'}
						className={`${isLoading ? 'bg-purple-400' : 'bg-brightLilac-default'}`}
					>
						{isEdition ? 'Edit' : 'Create'}
					</Button>
					{errors.submit ? <p className={'text-red-500'}> Error creating spark Library</p> : null}
					{errors.cover ? <p className={'text-red-500'}> {errors.cover}</p> : null}
				</form>
			</div>
		</Modal>
	);
};