import {ThemesCreatePageCard, ThemesCreatePageContainer} from './ThemesCreate.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {query} from '../../../actions/queries';


export const ThemesCreate = () => {
	const history = useHistory();

	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		description: ''
	});

	const [createTheme] = useMutation(mutations.themeCreateOne, {
		refetchQueries: [{ query: query('themesFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await createTheme({
				variables: {
					record: {
						...formState,
					},
				},
			});
			history.push('/themes');
		} catch (error) {
			console.error('Error creating theme:', error);
		}
	};

	const allFieldsFilled = formState.label && formState.identifier && formState.description;

	return (
		<ThemesCreatePageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Theme</h1>
			<ThemesCreatePageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-row justify-start align-center gap-7'} onSubmit={handleSubmit}>
					<div className={'flex self-start'}>
						<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
							<div className={'w-45 h-45 cursor-pointer'}>
								<div className={'flex justify-center align-center p-2 w-44 h-44'}>
									<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
								</div>
							</div>
							<span
								className={'font-sans ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
                            Upload Image
							</span>
						</div>
					</div>
					<div className={'w-100'}>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
							<input
								type='text'
								name='label'
								value={formState.label}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
							<input
								type='text'
								name='identifier'
								value={formState.identifier}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
							<textarea
								name='description'
								value={formState.description}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<button
							type='submit'
							disabled={!allFieldsFilled}
							className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${
								!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							}`}
						>
							Create
						</button>
					</div>
				</form>
			</ThemesCreatePageCard>
		</ThemesCreatePageContainer>
	);
};