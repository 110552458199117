import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {mutations} from '../../actions/mutations';
import {query} from '../../actions/queries';
import {
	CollectiblesPageContainer,
	CollectiblesTitle,
	CollectiblesWrapperElements,
	CollectiblesWrapperElementsAddOne
} from './Collectibles.style';
import {Search} from '../../components';
import {StyledGrid} from '../Mood/Mood.style';
import {ImageIcon, PencilIcon, TrashIcon} from '../../icons';
import DeleteConfirmationModal from '../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';


export const Collectibles = () => {
	const history = useHistory();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [collectibleToDelete, setCollectibleToDelete] = useState(null);

	const { loading, error, data, refetch } = useQuery(query('CollectiblesFindMany'), {
		variables: { filter: { deletedAt: null }, skip: 0, limit: 100 },
		nextFetchPolicy: 'network-only'
	});

	const [deleteCollectible] = useMutation(mutations.CollectiblesDeleteOne);

	const handleDeleteClick = (collectibleId) => {
		setCollectibleToDelete(collectibleId);
		setIsDeleteModalOpen(true);
	};

	const handleDelete = async () => {
		try {
			await deleteCollectible({
				variables: { _id: collectibleToDelete },
			});
			setIsDeleteModalOpen(false);
			await refetch();
		} catch (error) {
			console.error('Error deleting collectible:', error);
		}
	};

	const sortedData = data ? data.CollectiblesFindMany : [];

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	return (
		<CollectiblesPageContainer className={'m-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<CollectiblesTitle className={'font-sans'}>Collectibles</CollectiblesTitle>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<CollectiblesWrapperElementsAddOne
					bgColor='rgba(100, 150, 200, 0.5)'
					className={'flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer'}
					onClick={() => history.push('/collectibles/create')}>
					<div className={'flex gap-3 align-center justify-center'}>
						<span className={'font-sans'}>Add Collectible</span>
					</div>
					<div className={'flex gap-3 align-center justify-center'}>
						<div className={'flex align-center justify-center'}>
							<span className={'font-sans'}>+</span>
						</div>
					</div>
				</CollectiblesWrapperElementsAddOne>
				{sortedData.map(({ _id, title, cover,artwork, price }) => (
					<CollectiblesWrapperElements
						key={_id} bgColor='rgba(100, 150, 200, 0.5)'
						className={'flex justify-between items-center p-3 mb-3'}>
						<div className={'flex justify-start'}>
							<div className={'flex justify-center items-center mr-2'}>
								{
									cover?.url === undefined || cover?.url === null || cover?.url === 'test' || cover?.url === 'artwork' ?
										(
											<div
												className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<ImageIcon width={'1rem'} height={'1rem'}
														   background={'rgba(174, 185, 225, 1)'} />
											</div>
										) :
										(
											<div
												className={'w-22 h-22 flex justify-center align-center rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<img
													className={'rounded-full'}
													src={cover.url}
													alt={title}
												/>
											</div>
										)
								}
							</div>
							<div className={'grid'}>
								<span className={'font-sans'}>{title}</span>
								<p className={'font-mono'}>Price: {price}</p>
							</div>
						</div>
						<div className={'flex justify-center align-center'}>
							<div onClick={() => history.push(`/collectibles/${_id}`)}
								 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<PencilIcon />
							</div>
							<div onClick={() => handleDeleteClick(_id)}
								 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<TrashIcon />
							</div>
						</div>
					</CollectiblesWrapperElements>
				))}
			</StyledGrid>
			<DeleteConfirmationModal
				isOpen={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				onDelete={handleDelete}
				itemName={collectibleToDelete && sortedData.find(collectible => collectible._id === collectibleToDelete)?.title}
			/>
		</CollectiblesPageContainer>
	);
};