import React,{useEffect,useState} from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import {useMutation,useQuery} from '@apollo/client';
import {mutation} from '../../actions/mutations';
import {useParams} from 'react-router-dom';
import {query} from '../../actions/queries';
import {LanguageForm} from './LanguageForm';
import {languages,deleteTypeMutation} from '../../tools';

export const ResponseForm=()=>{
	const {id}=useParams();
	const [form,setForm]=useState({language:{spanish:{},english:{},french:{}}});
	const [saving, setSaving] = useState(false);
	const [createAr]=useMutation(mutation(id==='new' ? 'responsesCreateOne' : 'responsesUpdateOne'));
	
	const {data,loading,error}=useQuery(
		query('responsesFindOne'),
		{
			variables:{id},
			// skip:id==='new',
			// fetchPolicy:'standby'
		}
	);
	
	
	useEffect(()=>{
		if(!loading && !error && data && data.ResponsesFindOne) {
			setForm({
				label:data.ResponsesFindOne.label,
				identifier:data.ResponsesFindOne.identifier,
				language : data.ResponsesFindOne.language || {spanish:{description:'',title:'',identifier:'',label:''},english:{description:'',title:'',identifier:'',label:''},french:{description:'',title:'',identifier:'',label:''}}
			});
		}
	},[data,loading,error]);
	
	const handlerForm=({target})=>{
		let {value,name}=target;
		setForm({...form,[name]:value});
	};
	
	const send=async()=>{
		setSaving(true);
		let data={
			...deleteTypeMutation(form),
		};
		let variables={record:data};
		if(id!=='new')
			variables.id=id;
		
		await createAr({
			variables
		})
		.then(({_})=>{
			setSaving(false);
		})
		.catch(_=>{
			setSaving(false);
		});
	};
	
	return (
		<div className='w-100 h-100'>
			<Input label={'label'}
				   name={'label'}
				   value={form.label}
				   className={'mb-4'}
				   onChange={handlerForm}
				   register={()=>{
				}}/>
			<Input
				label={'identifier'}
				name={'identifier'}
				value={form.identifier}
				className={'mb-4'}
				onChange={handlerForm}
				register={()=>{
				}}/>
			{languages.map(i=>
				<LanguageForm
					setForm={setForm}
					form={form}
					language={i}
				/>
			)}
			<Button onClick={send}>
				{saving ? 'Saving...' : 'Save'}
			</Button>
		</div>
	);
};