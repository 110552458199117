import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import { LabelVideoContainer, VideoElement, VideoWrapper } from '../Sticker.style';
import {PlayIcon} from '../../../icons/svg/PlayIcon';
import {VaultFindMany} from '../../../hoc/VaultFindMany';


export const StickerEditRender = ({
	formState,
	handleChange,
	handleFileChange,
	handleVideoChange,
	handleSubmit,
	preview,
	videoPreview,
	allFieldsFilled,
}) => {
	const history = useHistory();

	const getImageSrc = () => {
		if (preview) {
			return preview;
		} else if (formState?.cover?.url) {
			return formState?.cover?.url;
		} else {
			return null;
		}
	};

	const getVideoSrc = () => {
		if (videoPreview) {
			return videoPreview;
		} else if (formState?.video?.url) {
			return formState?.video?.url;
		} else {
			return null;
		}
	};

	return (
		<div className={'w-full h-full'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Sticker</h1>
			<div className={'flex rounded gap-2 justify-center align-center'} style={{ border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)' }}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<label htmlFor='file-upload'
						   className={'mb-4 flex direction-column gap-2 justify-center align-center cursor-pointer'}>
						<div
							className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'w-44 h-44 flex justify-center align-center'}>
								{preview ? (
									<img src={preview} alt='Preview' className={'rounded-full w-44 h-44 object-cover'}/>
								) : (
									<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
								)}
							</div>
						</div>
						<span className={'font-sans text-text-light dark:text-text-dark active-nav-item-font-color'}>Upload Image</span>
						<input
							id='file-upload'
							type='file'
							accept='image/*'
							onChange={handleFileChange}
							className={'hidden'}
						/>
					</label>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
						<input
							type='text'
							name='label'
							value={formState.label}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Deedly
							Vault</label>
						<VaultFindMany>
							{charityVaults => (
								<select
									name='deedlyVault'
									value={formState.deedlyVault}
									onChange={handleChange}
									className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
								>
									<option value=''>Select a Deedly Vault</option>
									{charityVaults.map(vault => (
										<option key={vault.id} value={vault.id}>
											{vault.title}
										</option>
									))}
								</select>
							)}
						</VaultFindMany>
					</div>
					<LabelVideoContainer htmlFor='video-upload'>
						<VideoWrapper>
							{getVideoSrc() ? (
								<VideoElement
									src={getVideoSrc()}
									controls
									poster={getImageSrc() ? getImageSrc() : ''}
								>
									<p>{formState.title}</p>
								</VideoElement>
							) : (
								<div
									className={'w-100 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
									<div className={'flex justify-center align-center p-2 w-44 h-44 overflow-hidden'}>
										<PlayIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
									</div>
								</div>
							)}
						</VideoWrapper>
						<span
							className={'font-sans mt-3 mb-5 text-text-light text-center dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
                            Edit Sticker Video
						</span>
						<input
							id='video-upload'
							type='file'
							accept='video/mp4'
							onChange={handleVideoChange}
							className={'hidden'}
						/>
					</LabelVideoContainer>
					<div className={'mb-4 flex direction-column justify-between gap-2'}>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Created
							At: </b>{new Date(formState.createdAt).toLocaleString()}</p>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Updated
							At:</b> {new Date(formState.updatedAt).toLocaleString()}</p>
						{formState.deletedAt && (
							<p className={'font-mono text-red-500'}><b>Deleted
								At:</b> {new Date(formState.deletedAt).toLocaleString()}</p>
						)}
					</div>
					<button
						type='submit'
						disabled={!allFieldsFilled}
						className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${
							!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						}`}
					>
						Update
					</button>
				</form>
			</div>
		</div>
	);
};

StickerEditRender.propTypes = {
	formState: PropTypes.shape({
		label: PropTypes.string.isRequired,
		identifier: PropTypes.string,
		deedlyVault: PropTypes.string.isRequired,
		cover: PropTypes.shape({
			url: PropTypes.string,
			type: PropTypes.string,
		}),
		video: PropTypes.shape({
			url: PropTypes.string,
			type: PropTypes.string,
		}),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	handleVideoChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	preview: PropTypes.string,
	videoPreview: PropTypes.string,
	allFieldsFilled: PropTypes.bool.isRequired,
};