import React from 'react';
import {svgPropTypes} from '../../types';

const DropdownArrowIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : background;

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 13 13'
			fill='none'
			className={className}
			style={{ ...style }}
		>
			<g opacity='1'>
				<path
					d='M4.75 9.5L7.75 6.5L4.75 3.5'
					stroke={fillColor}
					strokeWidth='0.8'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);
};

DropdownArrowIcon.propTypes = svgPropTypes;

DropdownArrowIcon.defaultProps = {
	width: '13',
	height: '13',
	background: '#AEB9E1',
	className: '',
	style: {},
	isActive: false
};

export default DropdownArrowIcon;