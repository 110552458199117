import { ApolloClient,InMemoryCache } from '@apollo/client';
import {addHeaders} from '../tools';
import config from '../tools/config';

const {url}=config;

export const client=()=>new ApolloClient({
	uri:`${url}graphql`,
	headers:addHeaders({}),
	cache: new InMemoryCache()
});
