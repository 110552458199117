import React, { useState } from 'react';
import {DropdownArrowIcon} from '../../../icons';
import PropTypes from 'prop-types';
import {DropdownItemsContainer} from './DropdownMenu.styles';
import {NavLink} from 'react-router-dom';

//Types for props:
DropdownMenu.propTypes = {
	title: PropTypes.shape({
		title: PropTypes.string.isRequired,
		iconTitle: PropTypes.element.isRequired
	}).isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			icon: PropTypes.element.isRequired
		})
	).isRequired,
	hide: PropTypes.bool.isRequired
};

export default function DropdownMenu({ title, items, hide }) {
	const [hidden, setHidden] = useState(hide);
	const onClickArrow = () => {
		setHidden(!hidden);
	};

	return (
		<div className='font-mono w-100 mt-3'>
			<div className='flex justify-between items-center p-2 rounded-lg bg-background-light dark:bg-background-dark text-text-light dark:text-text-dark  hover:bg-button-hover-light dark:hover:bg-button-hover-dark cursor-pointer'
				 onClick={onClickArrow}>
				<div className={'flex items-center'}>
					{title.iconTitle}
					<span
						className={`ml-2 ${hidden ? 'text-text-light dark:text-text-dark' : 'font-bold active-nav-item-font-color'}`}>
						{title.title}
					</span>
				</div>
				<div>
					<DropdownArrowIcon
						className={`ml-auto transform transition-transform duration-300 ${hidden ? '' : 'rotate-90'}`}/>
				</div>
			</div>
			<DropdownItemsContainer className={`transition-all duration-300 ease-in-out ${hidden ? 'h-0 opacity-0' : 'h-auto opacity-100'}`}>
				{items && items.length > 0 ? (
					items.map((item, index) => (
						<NavLink
							to={item.to}
							key={index}
							className='flex items-center rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							activeClassName='font-bold'
							style={{ display: hidden ? 'none' : 'flex' }}
						>
							<span className='ml-2 my-2 hover:text-hover-light dark:hover:text-hover-dark'>
								{item.icon}
								{item.name}
							</span>
						</NavLink>
					))
				) : (
					<></>
				)}
			</DropdownItemsContainer>
		</div>
	);
}