import {svgPropTypes} from '../../types';


export const ImageIcon = ({ width, height, background, className, style, isActive }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 13 13'
			 fill='none'
			 className={className}
			 style={{ ...style }}
		>
			<path
				d='M11.5039 0.367188H1.50391C1.23869 0.367188 0.984336 0.472544 0.796799 0.660081C0.609263 0.847617 0.503906 1.10197 0.503906 1.36719V9.36719V11.3672C0.503906 11.6324 0.609263 11.8868 0.796799 12.0743C0.984336 12.2618 1.23869 12.3672 1.50391 12.3672H11.5039C11.7691 12.3672 12.0235 12.2618 12.211 12.0743C12.3985 11.8868 12.5039 11.6324 12.5039 11.3672V1.36719C12.5039 1.10197 12.3985 0.847617 12.211 0.660081C12.0235 0.472544 11.7691 0.367188 11.5039 0.367188ZM9.71016 5.36719C9.52189 5.18169 9.2682 5.0777 9.00391 5.0777C8.73961 5.0777 8.48592 5.18169 8.29766 5.36719L5.50391 8.16094L4.21016 6.86719C4.02189 6.68169 3.76821 6.5777 3.50391 6.5777C3.23961 6.5777 2.98592 6.68169 2.79766 6.86719L1.50391 8.16094V1.36719H11.5039V7.16094L9.71016 5.36719Z'
				fill={background}/>
		</svg>
	);
};

ImageIcon.propTypes = svgPropTypes;

ImageIcon.defaultProps = {
	width: '13',
	height: '13',
	background: '#AEB9E1',
	className: '',
	style: {},
	isActive: false,
};

export default ImageIcon;