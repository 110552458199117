import {svgPropTypes} from '../../types';

export const DotsThreeIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 21 22'
			 fill='none'
			 className={className}
			 style={{ ...style }}
		>
			<path
				d='M11.6651 10.998C11.6651 10.4797 11.2456 10.0595 10.7281 10.0595C10.2105 10.0595 9.79102 10.4797 9.79102 10.998C9.79102 11.5163 10.2105 11.9365 10.7281 11.9365C11.2456 11.9365 11.6651 11.5163 11.6651 10.998Z'
				fill='white'/>
			<path
				d='M11.6651 5.99411C11.6651 5.47578 11.2456 5.05559 10.7281 5.05559C10.2105 5.05559 9.79102 5.47578 9.79102 5.99411C9.79102 6.51243 10.2105 6.93262 10.7281 6.93262C11.2456 6.93262 11.6651 6.51243 11.6651 5.99411Z'
				fill='white'/>
			<path
				d='M11.6651 16.0058C11.6651 15.4875 11.2456 15.0673 10.7281 15.0673C10.2105 15.0673 9.79102 15.4875 9.79102 16.0058C9.79102 16.5242 10.2105 16.9443 10.7281 16.9443C11.2456 16.9443 11.6651 16.5242 11.6651 16.0058Z'
				fill='white'/>
		</svg>
	);
};

DotsThreeIcon.propTypes = svgPropTypes;

DotsThreeIcon.defaultProps = {
	width: '21',
	height: '22',
	background: 'transparent',
	className: '',
	style: {},
	isActive: false,
};

export default DotsThreeIcon;