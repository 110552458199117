import {addHeaders} from '../../../tools';
import config from '../../../tools/config';

const url=config.url;

export const uploadSparkLibraryVideo = async(file, id, title) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);
	formData.append('title', title);

	try {
		const response = await fetch(`${url}admin/sparklibrary-video`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload spark library Video Error: ', err);
		throw new Error(err);
	}
};

export const uploadSparkLibraryVideoThumbnail = async(file, id, title) => {
	console.log({file, id, title});
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);
	formData.append('title', title);

	try {
		const response = await fetch(`${url}admin/sparklibrary-video-thumbnail`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload spark library video thumbnail Error: ', err);
		throw new Error(err);
	}
};

export const UploadSparkLibraryClosedCaptions = async(file, id, available, language) =>{
	const formData = new FormData();
	formData.append('file', file);
	formData.append('id', id);
	formData.append('available', available);
	formData.append('language', language);

	try {
		const response = await fetch(`${url}admin/sparklibrary-video-caption`,{
			method: 'PUT',
			headers: addHeaders({}),
			body: formData,
			credentials: 'include',
			firsTime: false
		});
		return response.json();
	} catch (err) {
		console.log('Upload SparkLibrary ClosedCaption Error: ', err);
		throw new Error(err);
	}
};