import styled from 'styled-components';

export const TagsWrapper = styled.div`
    display: flex;
	justify-items: start;
	justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    margin: 3px;

    span {
        border: 2px solid #ffffff;
        border-radius: 24px;
        background-color: transparent;
        padding: 8px 10px;
        margin: 8px 0 0 3px;
        transition: background 0.3s, color 0.3s;
		&:hover{
            background-color: #000000;
            color: #ffffff;
        }
    }
`;