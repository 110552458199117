import gql from 'graphql-tag';

export const mutations={
	createOne: gql`
        mutation SchoolCreateOne($record:CreateOneschoolsDataInput!){
            SchoolCreateOne(record: $record) {
                recordId
                record {
                    _id
                    name
                    state
                    city
                    district
                    country
                    slug
                    deletedAt
                    createdAt
                    updatedAt
                    settingsObj {
                        _id
                        accessWithEmail
                        accessWithClever
                        accessWithClassLink
                        accessWithGoogle
                        deleted
                        createdAt
                        updatedAt
                    }
                }
                error {
                    message
                }
            }
        }
    `,
	updateOne: gql`
        mutation SchoolUpdateOne($_id: String!, $record: UpdateByIdschoolsDataInput!) {
            SchoolUpdateOne(_id: $_id, record: $record) {
                recordId
                record {
                    _id
                    name
                    state
                    city
                    district
                    country
                    slug
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
};

export const schoolMutations=(value)=>{
	return mutations[value];
};