import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {mutations} from '../../actions/Users.mutations';
import PropTypes from 'prop-types';
import {AddToOrganizationUserRender} from './AddToOrganizationUser.render';

const defaultValues = {
	firstName: '',
	lastName: '',
	userName: '',
	email: '',
	organization: ''
};

export const AddToOrganizationUserLoad = ({isOpenState, currentUser, refetch}) =>{
	const { data: organizationsData, loading: organizationsIsLoading } = useQuery(query('organizationsFindMany'), {variables: {}});
	const [isChanged, setIsChanged] = useState(false);

	const [formData, setFormData] = useState({
		...defaultValues,
		firstName: currentUser?.firstName,
		lastName: currentUser?.lastName,
		userName: currentUser?.userName,
		email: currentUser?.email,
		organization: currentUser?.organization
	});

	const updateForm = (name, value) => {
		setFormData((prev)=>({...prev, [name]: value}));
		setIsChanged(true);
	};


	const [setUserOrganization, { loading: updateUserLoading, error: updateUserError }] = useMutation(mutations.setUserOrganization, {
		onCompleted: () => refetch(),
	});

	const handleUpdateUserOrganization = async () => {
		try {
			await setUserOrganization({
				variables: {
					user: currentUser._id,
					organization: formData.organization,
				}
			});
			const setIsOpen = isOpenState[1];
			setIsOpen(false);
		} catch (err) {
			console.log('Error updating user organization:', err);
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		handleUpdateUserOrganization().then();
	};

	return (
		<AddToOrganizationUserRender
			currentUserId={currentUser}
			isOpenState={isOpenState}
			isLoading={ updateUserLoading || organizationsData }
			values={formData}
			errors={{submit: updateUserError}}
			handleOnChange={updateForm}
			onSubmit={onSubmit}
			isChanged={[isChanged, setIsChanged]}
		/>
	);
};

AddToOrganizationUserLoad.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	isOpenState: PropTypes.array.isRequired,
	currentUser: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		userName: PropTypes.string,
		email: PropTypes.string,
		classLinkId: PropTypes.string,
	}).isRequired,
	refetch: PropTypes.func.isRequired,
};