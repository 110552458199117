import styled from 'styled-components';

export const LessonsCreatePageCard = styled.div`
    border-radius: 12px;
    background: rgba(11, 23, 57, 1);
    border: 1px solid rgba(52, 59, 79, 1);
	
	img{
		border-radius: 50%;
	}
	
	span{
		font-size: 0.752rem;
	}

    input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
		border-radius: 50%;
        border: 1px solid rgba(52, 59, 79, 1);
        background-color: white;
        cursor: pointer;
        position: relative;
    }

    input[type="checkbox"]:checked {
        background-color: rgba(203, 60, 255, 1);
    }
`;

export const LessonsCreatePageContainer = styled.div`
	h1,label, b{
		color: white !important;
	}
	form{
        input, textarea{
            border: 1px solid rgba(52, 59, 79, 1);
        }
	}
`;