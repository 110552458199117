import {MindfulMoment} from './MindfulMoment';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useQuestionsLayout} from '../../../../hooks';
import { sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';
import PropTypes from 'prop-types';

export const MindfulMomentLoad = ({setIsOpen, sparkL}) =>{
	const [ addQuestionModal, setAddQuestionModal ] = useState(false);

	const mindfulMoment = sparkL.mindfulMoment.map(item => ({
		question: item.question,
		points: item.points
	}))||[];


	const [updateSparkLibrary] = useMutation(sparkLibrariesMutations('updateOne'), {
		refetchQueries: ['sparkLibraryFindMany'],
		awaitRefetchQueries: true
	});

	const onChangeQuestionsArray =async (newQuestions)=>{
		await updateSparkLibrary({
			variables:{id:sparkL._id, record:{ mindfulMoment: newQuestions }}
		})
		.then();
	};

	const { questions, onUpdateQuestion, onDeleteQuestion, onCreateQuestion } = useQuestionsLayout({questions:mindfulMoment,onChangeQuestionsArray});

	return (
		
		<MindfulMoment
			addQuestionModal={addQuestionModal}
			questions={questions}
			questionPoints={mindfulMoment}

			setAddQuestionModal={setAddQuestionModal}
			onUpdateQuestion={onUpdateQuestion}
			onDeleteQuestion={onDeleteQuestion}
			onCreateQuestion={onCreateQuestion}
		/>
	
	);
};

MindfulMomentLoad.propTypes = {
	sparkL: PropTypes.shape({
		_id:PropTypes.string.isRequired,
		mindfulMoment:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,

	setIsOpen:PropTypes.func.isRequired
};