import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const SparkLibraryFindOne = ({ children, _id }) => {
	const [retry, setRetry] = useState(false);
	const {data, loading, error, refetch} = useQuery(query('sparkLibraryFindMany'), {
		variables: {filter: {_id}},
		fetchPolicy: 'network-only'
	});

	useEffect(() => {
		if (error && !retry) {
			setRetry(true);
			refetch()
			.then(({data}) => {
				if (!data || !data.SparkLibraryFindMany) {
					setRetry(false);
				}
			})
			.catch(() => {
			});
		}
	}, [error, retry, refetch]);

	if (loading) return null;
	if (error) return null;
	if (!data || !data.SparkLibraryFindMany) return null;

	return children(data.SparkLibraryFindMany);
};