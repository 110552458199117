import {useRef, useState} from 'react';

export const FileInput =({...props})=>{
	const {acceptedFiles,name,label,defaultValue,onChange} = props;
    
	const [file, setFile] = useState(defaultValue|| null);
	const inputRef =useRef(null);
	const fileNme = file?.name;
	console.log({label});
	const handleClick = () => inputRef.current?.click();

	const handleFileChange = (e) => {
		const files = e.target.files;
		if(files){
			setFile(files[0]);
		}
		onChange?.(e);
	};
    
	return (
		<div>
			<input
				name={name}
				hidden
				type={'file'}
				onChange={handleFileChange}
				ref={inputRef}
				accept={acceptedFiles}
			/>
			<button
				className={'border-dotted border-2 border-brightLilac-default rounded px-4 py-0.5'}
				type={'button'}
				onClick={handleClick}
			>
				{fileNme? fileNme : label ? label : 'select file'}
			</button>
		</div>
	);
};