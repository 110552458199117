export const themesDataMock = [
	{
		_id: '02840e60-28e7-11ef-a91b-a7705709ebac',
		label: 'Achieving Your Dreams',
		identifier: 'achieving-your-dreams',
		icon: {
			url: 'https://picsum.photos/id/1018/3200/4800',
			type: null
		},
		description: null,
		deletedAt: null,
		createdAt: '2024-06-12T18:10:11.655Z',
		updatedAt: '2024-06-12T18:10:11.655Z'
	},
	{
		_id: '067f57e0-28e7-11ef-a91b-a7705709ebac',
		label: 'Overcoming Doubts',
		identifier: 'overcoming-doubts',
		icon: {
			url: 'https://picsum.photos/id/1019/3200/4800',
			type: null
		},
		description: null,
		deletedAt: null,
		createdAt: '2024-06-12T18:10:18.334Z',
		updatedAt: '2024-06-12T18:10:18.334Z'
	},
	{
		_id: '0a6a4db0-28e7-11ef-a91b-a7705709ebac',
		label: 'Mindset',
		identifier: 'mindset',
		icon: {
			url: 'https://picsum.photos/id/1019/3200/4800',
			type: null
		},
		description: null,
		deletedAt: null,
		createdAt: '2024-06-12T18:10:24.908Z',
		updatedAt: '2024-06-12T18:10:24.908Z'
	},
	{
		_id: '0ccfb4f0-28e7-11ef-a91b-a7705709ebac',
		label: 'Capabilities',
		identifier: 'capabilities',
		icon: {
			url: 'https://picsum.photos/id/1019/3200/4800',
			type: null
		},
		description: null,
		deletedAt: null,
		createdAt: '2024-06-12T18:10:28.927Z',
		updatedAt: '2024-06-12T18:10:28.927Z'
	}
];