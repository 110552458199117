import {useQuery} from '@apollo/client';
import React from 'react';
import {query} from '../actions/queries';


export const LifeSkillFindMany = ({ children }) => {
	const { data, loading, error } = useQuery(query('lifeSkillsFindMany'), {
		variables: { limit: 1000 },
		fetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.LifeSkillFindMany) return <p className={'font-mona f-900 text-white p-3'}>No Life Skills found</p>;

	return children(data.LifeSkillFindMany);
};