import styled from 'styled-components';

export const UserTableTHSpan = styled.span`
   font-size: 0.625rem;
   font-weight: 600;
   color: rgba(255, 255, 255, 1);
`;
export const UserTableSpan = styled.span`
   font-size: 0.625rem;
   color: rgba(174, 185, 225, 1);
`;

export const UserTableUserInfo = styled.div`
	
	span:first-child{
		font-size: 0.625rem;
		color: rgba(255, 255, 255, 1);
		font-weight: 500;
    }
	
	span:last-child{
		font-size: 0.625rem;
		font-weight: 500;
		color: rgba(174, 185, 225, 1);
    }
	
	div:last-child{
        align-items: start;
        justify-items: start;
	}
	
	img{
        border-radius: 50%;
        max-width: 1.75rem;
        max-height: 1.75rem;
        min-height: 1.75rem;
        min-width: 1.75rem;
        object-fit: cover;
	}
`;

export const LessonsTableMainWrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
	border-radius: 0.75rem;
    border: 1px solid transparent;
	box-decoration-break: unset;
	th, tr, th , tbody, td 	{
		border: transparent;
	}
`;

export const StyledTableRow = styled.tr`
    width: 100%;
    max-width: 1600px;
    height: 100%;
    border-radius: 0.0375rem;
    background-color: ${(props) =>
		props.index % 2 === 0 ? 'rgba(10, 19, 48, 1)' : 'rgba(11, 23, 57, 1)'};

    &:hover {
        background: rgb(18, 29, 61)
    }
`;

export const LessonsPageWrapperElements = styled.div`
	border-radius: 19px;
    border: 1px solid rgba(52, 59, 79, 1);
	overflow: hidden;
`;