import {refreshToken} from '../actions/restactions';
import { jwtDecode } from 'jwt-decode';
export const addHeaders=(value)=>
	localStorage.getItem('accessToken') ?
		{...value,
			'access-token':localStorage.getItem('accessToken')
		}
		:
		value;

export const error=(value,message)=>{
	if(value && value.includes('E11000')){
		return message || 'The username or email already exists';
	}
	return value;
};

export const deleteTypeMutation=(data)=>{
	let aux=Object.assign({},data);

	for(let prop in aux){
		if(typeof aux[prop]==='object' && !Array.isArray(aux[prop])){
			aux[prop]=deleteTypeMutation(aux[prop]);
		}else{
			if(prop==='__typename')
				delete aux[prop];
		}
	}

	return aux;
};

export const verifyCode=(value)=>{
	return /^([a-zA-Z0-9]{6})$/g.test(value);
};

export const authResponse=(response)=>{
	if(!response.ok && response?.headers?.get('content-type').indexOf('application/json')=== -1){
		return response.text().then(res=>{
			throw new Error(res);
		});
	}
	return response.json().then(res=>{
		localStorage.setItem('accessToken',res['token']);
		return res;
	});
};

export const verifyDate=(value)=>{
	console.log(value);
	return /(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/g.test(value);

};

export const removeTypename = (obj) => {
	if (Array.isArray(obj)) {
		return obj.map((item) => removeTypename(item));
	} else if (typeof obj === 'object' && obj !== null) {
		const newObj = {};
		for (let key in obj) {
			if (key !== '__typename') {
				newObj[key] = removeTypename(obj[key]);
			}
		}
		return newObj;
	}
	return obj;
};

export const languages=['spanish','english','french'];

export const isTokenExpired =  ()=>{
	const token = localStorage.getItem('accessToken');
	const decoded = jwtDecode(token);
	const exp = decoded.exp *1000;
	const now = Date.now();
	return  exp <= now;
};