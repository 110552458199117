import PropTypes from 'prop-types';
import {Question} from '../../components';
import {QuestionForm} from '../../components/QuestionForm';

export const ExtraQuestionView = ({ addQuestionModal, questions,questionPoints, setAddQuestionModal,onDeleteQuestion,onUpdateQuestion,onCreateQuestion }) => {

	return (
		<div className={'font-mono w-[600px] flex flex-col gap-3 text-white'}>

			<button
				type={'button'}
				className={'flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer rounded border border-b-cloudyBlue-default hover:bg-background-dark'}
				onClick={()=>setAddQuestionModal(true)}
			>
				<p className={'font-sans text-2xl'}>Add Question</p>
				<div className={'w-[40px] h-[40px] flex justify-center items-center rounded-3xl bg-brightLilac-default'}>
					<p className={'font-sans text-5xl'}>+</p>
				</div>

			</button>

			{questions.map((item, index) =>
				<Question
					key={item._id}
					question={item}
					points={questionPoints.find(qp=>qp.question===item._id).points}
					questionIndex={index+1}
					onDeleteQuestion={onDeleteQuestion}
					onEditQuestion={onUpdateQuestion}

				/>
			)}

			<QuestionForm
				onCreate={onCreateQuestion}

				isOpen={addQuestionModal}
				setIsOpen={setAddQuestionModal}
			/>
		</div>
	);
};

ExtraQuestionView.propTypes = {
	addQuestionModal:PropTypes.bool.isRequired,

	questions: PropTypes.arrayOf(
		PropTypes.shape({
			question: PropTypes.string.isRequired,
			points: PropTypes.string.isRequired
		})
	).isRequired,
	questionPoints:PropTypes.shape({
		question:PropTypes.string,
		points:PropTypes.number,
	}).isRequired,

	setAddQuestionModal: PropTypes.func.isRequired,
	onUpdateQuestion:PropTypes.func.isRequired,
	onDeleteQuestion:PropTypes.func.isRequired,
	onCreateQuestion:PropTypes.func.isRequired,
};