import {svgPropTypes} from '../../types';


export const LessIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 6 1'
			 fill='none'
			 className={className}
			 style={{...style}}
			 >
			<path d='M0.996826 0.509827H5.19683'
				  stroke='white'
				  strokeLinecap='round'
				  strokeLinejoin='round'
			/>
		</svg>
	);
};

LessIcon.propTypes = svgPropTypes;

LessIcon.defaultProps = {
	width: '6',
	height: '1',
	background: 'transparent',
	className: '',
	style: {},
	isActive: false,
};

export default LessIcon;