import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import PropTypes from 'prop-types';
import React from 'react';
import {Loader} from '../components';

export const OrganizationFindOneByUser = ({ children, _id, organization,onAddOrganization }) => {
	const ORGANIZATION_FIND_MANY = query('organizationsFindMany');
	const { data, loading, error } = useQuery(ORGANIZATION_FIND_MANY, {
		variables: { filter: { _id: organization } },
	});

	if (loading) return <p className={'font-mona f-600 text-white p-3'}><Loader/></p>;
	if (error) return <p className={'font-mona f-600 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.OrganizationsFindMany || data.OrganizationsFindMany.length === 0) {
		return (
			<div
				className={'font-mona f-600 button t-white text-xs br-quarter hover:grey900 br-quarter hover:grey900 f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default'}
				onClick={() => onAddOrganization(_id)}
			>
				+ Organization
			</div>
		);
	}

	const organizationObj = data.OrganizationsFindMany[0];

	return children({ organizationObj });
};

OrganizationFindOneByUser.prototype = {
	children: PropTypes.func.isRequired,
	_id: PropTypes.string.isRequired,
	organization: PropTypes.string,
	onAddOrganization: PropTypes.func.isRequired
};