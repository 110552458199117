import {LessonsEdit} from './LessonsEdit';
import {useHistory, useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {useEffect, useState} from 'react';

export const LessonsEditLoad = () => {
	const { id, tab } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('classesAdminFindOne'), {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	const [lesson, setLesson] = useState({
		title: '',
		description: '',
		curriculum: '',
		order: 0,
		lifeSkill: [],
		skillSet: [],
		theme: [],
		cover: { url: '', type: '' },
		episodeTime: 0,
		quizTime: 0,
		journalTime: 0,
		classActivityTime: 0,
		tapLibraryTime: 0,
		extendTime: 0,
		lessonLength: '',
		overview: '',
		extraActivities: '',
		bigIdea: '',
		discussion: [{ title: '', description: '' }],
		extend: [{ title: '', description: '' }],
		activity: [{ title: '', description: '' }],
		reflection: [{ title: '', description: '' }],
		resources: [{ title: '', url: '', type: '' }],
		extracurricular: [],
		questions: [],
		learningGoal: ''
	});

	useEffect(() => {
		if (data) {
			const lessonData = data.ClassesAdminFindOne;
			setLesson({
				...lessonData,
				questions: lessonData.questions || [],
				lifeSkill: lessonData.lifeSkill || [],
				skillSet: lessonData.skillSet || [],
				theme: lessonData.theme || []
			});
		}
	}, [data]);

	if (loading) return <h2>Loading...</h2>;
	if (error) return <p>Error: {error.message}</p>;

	return (
		<LessonsEdit
			id={id}
			history={history}
			lesson={lesson}
		/>
	);
};