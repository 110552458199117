import {svgPropTypes} from '../../types';


export const EmailIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 13 13'
			 fill='none'
			 className={className}
			 style={{ ...style }}>
			<path fillRule='evenodd' clipRule='evenodd'
				  d='M0.579428 3.12534C0.530234 3.29898 0.503906 3.48223 0.503906 3.67163V9.06266C0.503906 10.1672 1.39934 11.0627 2.50391 11.0627H10.5039C11.6085 11.0627 12.5039 10.1672 12.5039 9.06266V3.67163C12.5039 3.48281 12.4777 3.30009 12.4288 3.12692L7.62411 7.12302C6.97558 7.66239 6.03458 7.66239 5.38606 7.12302L0.579428 3.12534ZM11.5082 1.94166C11.213 1.76996 10.8699 1.67163 10.5039 1.67163H2.50391C2.13844 1.67163 1.79587 1.76966 1.50105 1.94085L6.34522 5.96976C6.43787 6.04681 6.5723 6.04681 6.66494 5.96976L11.5082 1.94166Z'
				  fill={background}/>
		</svg>
	);
};

EmailIcon.propTypes = svgPropTypes;

EmailIcon.defaultProps = {
	width: '13',
	height: '13',
	background: 'rgba(255, 255, 255, 1)',
	className: '',
	style: {},
	isActive: false
};

export default EmailIcon;