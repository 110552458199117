import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import AuthState from './contexts/auth/AuthState';
import './app.css';
import {BrowserRouter} from 'react-router-dom';
ReactDOM.render(
	<React.StrictMode>
		<AuthState>
			<BrowserRouter>
				<Routes/>
			</BrowserRouter>
		</AuthState>
	</React.StrictMode>,
	document.getElementById('root')
);