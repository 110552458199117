import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {query} from '../../../../actions/queries';
import PropTypes from 'prop-types';
import {DistrictFormRender} from './DistrictForm.render';
import {districtMutations} from '../../actions/district.mutations';

export const DistrictFormLoad = ({onSuccess, currentDistrict})=>{
	const history = useHistory();
	const defaultValues = {
		name: 		currentDistrict?.name ||'',
		state: 		currentDistrict?.state || '',
		country: 	currentDistrict?.country || '',
	}; 
	const [formState, setFormState] = useState(defaultValues);

	const [createDistrict, {loading:createLoading}] = useMutation(districtMutations('createOne'), {
		refetchQueries: [{ query: query('districtFindMany') }],
		awaitRefetchQueries: true,
	});

	const [editDistrict, {loading:editLoading}] = useMutation(districtMutations('updateOne'), {
		refetchQueries: [{ query: query('districtFindMany') }],
		awaitRefetchQueries: true,
	});


	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (!!currentDistrict){
				await editDistrict({
					variables: {id: currentDistrict._id ,record: formState,},
				});
			}else {
				await createDistrict({
					variables: {record: formState,},
				});
			}
			onSuccess();
		} catch (error) {
			console.error('Error creating school:', error);
		}
	};

	return (
		<DistrictFormRender
			edit={!!currentDistrict}
			isLoading={createLoading || editLoading}
			formState={formState}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
		/>
	);
};

DistrictFormLoad.propTypes = {
	onSuccess:PropTypes.func.isRequired,
	currentDistrict:PropTypes.shape({
		name:PropTypes.string.isRequired,
		state:PropTypes.string.isRequired,
		country:PropTypes.string.isRequired,
	}),

};