import {svgPropTypes} from '../../types';


export const BillingIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 13 13'
			 fill='none'
			 className={className}
			 style={{ ...style }}
		>
			<path fillRule='evenodd' clipRule='evenodd'
				  d='M0.358887 2.71849C0.358887 2.08922 0.873119 1.5791 1.50746 1.5791H11.2103C11.8447 1.5791 12.3589 2.08922 12.3589 2.71849V3.59358L0.358887 3.59358V2.71849ZM0.358887 5.04286V9.32932C0.358887 9.95859 0.873119 10.4687 1.50746 10.4687H11.2103C11.8447 10.4687 12.3589 9.95859 12.3589 9.32932V5.04286L0.358887 5.04286Z'
				  fill={background}/>
		</svg>
	);
};

BillingIcon.propTypes = svgPropTypes;

BillingIcon.defaultProps = {
	width: '13',
	height: '13',
	background: '#AEB9E1',
	className: '',
	style: {},
	isActive: false,
};

export default BillingIcon;