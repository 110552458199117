import styled from 'styled-components';

export const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.$cols}, 1fr);

    row-gap: ${props => (props.$rowGap ? props.$rowGap : props.$gap)};
    column-gap: ${props => (props.$colGap ? props.$colGap : props.$gap)};
    place-items: ${props => (props.$placeItems ? props.$placeItems : 'normal')};

    >* {
        place-self: stretch;
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }
`;


export const AvatarCreatePageCard = styled.div`
    border-radius: 12px;
    background: rgba(11, 23, 57, 1);
    border: 1px solid rgba(52, 59, 79, 1);
	
	form:first-child{
		div:first-child{
			div:first-child{
				border-radius: 50%;
				padding: 0.2rem;
			}
		}
	}
	
	img{
		border-radius: 50%;
	}
	
	span{
		font-size: 0.752rem;
	}
`;


export const AvatarCreatePageContainer = styled.div`
	h1,label, b{
		color: white !important;
	}
	form{
        input, textarea{
            border: 1px solid rgba(52, 59, 79, 1);
        }
	}
`;