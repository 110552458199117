import {PinEditRender} from './PinEditRender';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {mutations} from '../../../actions/mutations';
import {useEffect, useState} from 'react';
import {uploadPinCover, uploadPinVideo} from '../../../actions/restactions';

export const PinEditLoad = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('pinFindMany'), {
		variables: { filter: { _id: id } },
	});

	const [formState, setFormState] = useState({
		label: '',
		class: null,
		sparkLibrary: '',
		cover: { url: '', type: '' },
		video: { url: '', type: '' },
		createdAt: '',
		updatedAt: '',
		deletedAt: null
	});
	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);

	const [updatePin, { loading: isLoading }] = useMutation(mutations.PinUpdateOne, {
		refetchQueries: [{ query: query('pinFindMany') }],
		awaitRefetchQueries: true,
	});

	useEffect(() => {
		if (data) {
			const pin = data.PinFindMany[0];
			setFormState({
				label: pin.label,
				class: pin.classId,
				sparkLibrary: pin.sparkLibrary,
				cover: pin.cover,
				video: pin.video,
				createdAt: pin.createdAt,
				updatedAt: pin.updatedAt
			});
			setPreview(pin.cover?.url || null);
			setVideoPreview(pin.video?.url || null);
		}
	}, [data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await updatePin({
				variables: {
					id: id,
					record: {
						label: formState.label,
						class: formState.class,
						sparkLibrary: formState.sparkLibrary,
					},
					filter: {
						_id: id,
					}
				},
			});

			if (selectedFile) {
				await uploadPinCover(selectedFile, id);
			}

			if (videoFile) {
				await uploadPinVideo(videoFile, id);
			}

			setIsChanged(false);
			history.push('/pin');
		} catch (error) {
			console.error('Error updating pin:', error);
		}
	};

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	const allFieldsFilled = formState.label || formState.class || formState.sparkLibrary;

	return (
		<PinEditRender
			formState={formState}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleVideoChange={handleVideoChange}
			handleSubmit={handleSubmit}
			preview={preview}
			videoPreview={videoPreview}
			allFieldsFilled={allFieldsFilled}
			isLoading={isLoading}
		/>
	);
};