import React from 'react';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import Input from '../ui/Input';
import {Link} from 'react-router-dom';

export const Questions=({onChange,onClick,values,name,label,remove,changesPoints})=>{
	let {data,loading,error}=useQuery(query('questionsFindMany'));
	if(loading) return <p>Loading..</p>;
	if(error) return <p>Error</p>;
	
	const addQuestion=({target:{value}})=>{
		let aux=values;
		aux.push({question:value,points:100});
		onChange({values:aux,name});
	};
	
	return (
		<div>
			<p
				style={{textTransform:'capitalize'}}
				className='f-700 f-roboto '>
				{label}
			</p>
			<select onChange={addQuestion} value={''} name={name}>
				<option value=''>Select One</option>
				{
					data.QuestionsFindMany.map(i=>
						<option key={i._id} value={i._id}>{i.label}</option>
					)
				}
			</select>
			<ul>
				{values && values.map(i=>
					<Question key={i.question} changesPoints={changesPoints} name={name} {...i} remove={remove}/>
				)}
			</ul>
		</div>
	);
};

const Question=({question,points,remove,name,changesPoints})=>{
	let {data,loading,error}=useQuery(query('questionsFindOne'),{
		variables:{id:question},
	});
	if(loading) return <p>Loading..</p>;
	if(error) return <p>Error</p>;
	
	return (
		<li>
			{data.QuestionsFindOne.label}
			<Link to={`/question/${question}`}>go to</Link>
			<Input label={'points'} value={points}
				   onChange={({target:{value}})=>changesPoints({id:question,name,points:value})}/>
			<div onClick={()=>remove({id:question,name})}>remove</div>
		</li>
	);
};