import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {query} from '../../../actions/queries';
import {mutations} from '../../../actions/mutations';
import {useMutation, useQuery} from '@apollo/client';
import {LifeSkillDataMock} from '../../../test/mock/lifeSkillDataMock';
import {LifeSkillEditPageCard, LifeSkillEditPageContainer} from './LifeSkillEdit.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';

export const LifeSkillEdit = () => {
	const { id } = useParams();
	const history = useHistory();
	const location = useLocation();

	const { loading, error, data } = useQuery(query('lifeSkillFindOne'), {
		variables: { id },
	});

	const [lifeSkill, setLifeSkill] = useState(LifeSkillDataMock[0]);
	const [formState, setFormState] = useState({
		label: lifeSkill.label,
		identifier: lifeSkill.identifier,
		description: lifeSkill.description,
	});
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (data) {
			setLifeSkill(data.LifeSkillFindOne);
			setFormState({
				label: data.LifeSkillFindOne.label || '',
				identifier: data.LifeSkillFindOne.identifier || '',
				description: data.LifeSkillFindOne.description || '',
			});
		}
	}, [data]);

	const [updateLifeSkill] = useMutation(mutations.lifeSkillUpdateOne);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await updateLifeSkill({
				variables: {
					id: lifeSkill._id,
					record: {
						...formState,
					},
				},
			});
			setIsChanged(false);
		} catch (error) {
			console.error('Error updating life skill:', error);
		}
	};

	if (loading) return <h2 className={'font-mona f-900 white p-3'}>Loading...</h2>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error: {error.message}</p>;
	if (!data) return <p className={'font-mona f-900 white p-3'}>No data available</p>;

	return (
		<LifeSkillEditPageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Life Skills edit one</h1>
			<LifeSkillEditPageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
						<div className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'flex justify-center align-center p-2 w-44 h-44'}>
								{
									lifeSkill.icon?.url || lifeSkill.icon?.url === undefined || lifeSkill.icon?.url === null || lifeSkill.icon?.url === '' ?
										<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
										:
										<img src={lifeSkill.icon?.url} alt={lifeSkill.identifier}
											 className={'rounded-full w-44 h-44 object-cover'}/>
								}
							</div>
						</div>
						<span
							className={'font-sans ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>Change Image</span>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Label</label>
						<input
							type='text'
							name='label'
							value={formState.label}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Identifier</label>
						<input
							type='text'
							name='identifier'
							value={formState.identifier}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-center align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
						<textarea
							name='description'
							value={formState.description}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 flex direction-column justify-between gap-2'}>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Created
							At: </b>{new Date(lifeSkill.createdAt).toLocaleString()}</p>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Updated At:</b> {new Date(lifeSkill.updatedAt).toLocaleString()}</p>
						{lifeSkill.deletedAt && (
							<p className={'font-mono text-red-500'}><b>Deleted At:</b> {new Date(lifeSkill.deletedAt).toLocaleString()}</p>
						)}
					</div>
					<button
						type='submit'
						disabled={!isChanged}
						className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${
							!isChanged ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						}`}
					>
						Update
					</button>
				</form>
			</LifeSkillEditPageCard>
		</LifeSkillEditPageContainer>
	);
};