import {useMutation} from '@apollo/client';
import {mutations} from '../../../../actions/mutations';
import {ResponseForm} from './ResponseForm';
import {useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal/Modal';
import {query} from '../../../../actions/queries';

export const ResponseFormLoad = ({response,question,isOpen,setIsOpen,refetchResponses})=>{
	const defaultForm = {
		label: response?.label ||'',
		identifier: response?.identifier ||''
	};
	const [responseForm,setResponseForm] = useState(defaultForm);
	const [createResponse,{loading:createLoading} ] = useMutation(mutations.responsesCreateOne,{
		refetchQueries: ['responsesFindMany']
	});
	const [updateResponse, {loading:updateLoading}] = useMutation(mutations.responsesUpdateOne,{
		onCompleted:()=>refetchResponses()
	});
	const [ questionUpdate, {loading:questionLoading} ] = useMutation(mutations.questionsUpdateOne, {
		refetchQueries: ['responsesFindMany','questionsFindMany'],
		awaitRefetchQueries: true,
	});

	const handleChange = (e)=>setResponseForm(prev=>(
		{...prev,[e.target.name]:e.target.value}
	));

	const handleSuccess = ()=>{
		setResponseForm(defaultForm);
		setIsOpen(false);
	};

	const handleUpdateResponse =async()=>{
    	if (responseForm.label && responseForm.identifier){
    		await updateResponse({
    			variables:{
    				id:response._id,
    				record:{...responseForm}
    			}
    		}).then(handleSuccess);
    	}
	};
    
	const handleCreateResponse =async()=>{
		const currentResponses = question.responses;
		if (responseForm.label){
			const res =await createResponse({
				variables:{record:{...responseForm}}
			});
			const recordId= res.data.ResponsesCreateOne.recordId;

			const newResponsesArray = [...currentResponses,recordId];
			questionUpdate({
				variables:{
					id:question._id,
					record:{responses:newResponsesArray}
				}
			}).then(handleSuccess);
		}
	};
    
	const onSubmit = (e)=>{
		e.preventDefault();
		console.log({responseForm});
		response ? handleUpdateResponse() : handleCreateResponse();
	};
    
	return(
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<ResponseForm
				isLoading={updateLoading||createLoading}
				question={question}
				formState={responseForm}
				handleChange={handleChange}
				onSubmit={onSubmit}
				close={()=>setIsOpen(false)}
			/>
		</Modal>
	);
};
ResponseFormLoad.prototype={
	question: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		question: PropTypes.string.isRequired,
		points: PropTypes.string.isRequired,
	}).isRequired,
	response: PropTypes.shape({
		question: PropTypes.string.isRequired,
		points: PropTypes.string.isRequired,
	}),

	handleChange:PropTypes.func.isRequired,
	onSubmit:PropTypes.func.isRequired,
};