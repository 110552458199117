import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';
import PropTypes from 'prop-types';


export const DistrictFindOneByID = ({ id, children }) => {
	const { loading, error, data } = useQuery(query('districtFindOne'), {
		variables: { filter: { _id: id } },
	});

	if (loading) return <p className={'font-mono f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mono f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.DistrictFindOne) return <p className={'font-mona f-900 text-white p-3'}>District not found</p>;

	return children(data.DistrictFindOne);
};

DistrictFindOneByID.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.func.isRequired,
};