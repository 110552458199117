import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import PropTypes from 'prop-types';

export const VaultFindMany = ({ children, limit = 100, skip = 0  }) => {
	const { data, loading, error } = useQuery(query('vaultFindMany'), {
		variables: { limit, skip },
		fetchPolicy: 'network-only',
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.VaultFindMany || !data.VaultFindMany.charityVaults.length) return <p className={'font-mona f-900 text-white p-3'}>No vaults found</p>;

	return children(data.VaultFindMany.charityVaults);
};

VaultFindMany.propTypes = {
	children: PropTypes.func.isRequired,
	limit: PropTypes.number,
	skip: PropTypes.number
};