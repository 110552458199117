import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import {ModalButton, ModalButtons, ModalCloseButton} from '../Modal.style';

const DeleteConfirmationModal = ({ isOpen, setIsOpen, onDelete, itemName }) => {
	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<h2 className={'font-mono mr-4'}>Are you sure you want to delete "{itemName}"?</h2>
			<ModalButtons className={'font-sans'}>
				<ModalButton onClick={onDelete}>Yes, delete</ModalButton>
				<ModalButton onClick={() => setIsOpen(false)}>Cancel</ModalButton>
			</ModalButtons>
		</Modal>
	);
};

DeleteConfirmationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	itemName: PropTypes.string
};

export default DeleteConfirmationModal;