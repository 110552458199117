import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import PropTypes from 'prop-types';


export const SparkLibraryFindMany = ({ children }) => {
	const { loading, error, data } = useQuery(query('sparkLibraryFindMany'), {
		variables: {}
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;

	const sparkLibraries = data?.sparkLibraryFindMany ?? [];

	return children({ sparkLibraries });
};

SparkLibraryFindMany.propTypes = {
	children: PropTypes.func.isRequired
};