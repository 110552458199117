import Input from '../../../../components/ui/Input';
import PropTypes from 'prop-types';
import {
	ArrowIndicatorIcon,
	BriefcaseIcon,
	EmailIcon,
	ImageIcon,
	LocationIcon,
	UserIcon,
	WebsiteIcon
} from '../../../../icons';
import {useHistory} from 'react-router-dom';

export const PersonalInfoRender = ({ userData, handleChange, handleSubmit, loading, allFieldsFilled, isChanged}) => {
	const history = useHistory();
	return (
		<div className={'w-full h-full font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'}/>
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back to Users</h2>
			</div>
			<form
				className={'p-5 w-100 flex direction-column justify-center align-start'}
				onSubmit={handleSubmit}
			>
				<h2 className={'font-sans mb-5 text-start'}>Personal Information</h2>
				<div className={'p-5 mb-12 rounded-lg'}
					 style={{border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)'}}
				>
					<div className={'flex justify-between align-center gap-4 mb-4'}>
						<div className={'flex justify-center align-center'} style={{minWidth: '80px'}}>
							<UserIcon width={'14px'} height={'14px'} background={'rgba(255, 255, 255, 1)'}/>
							<span className={'font-mono ml-1'}>Full Name</span>
						</div>
						<Input
							className={'w-full'}
							label={'First Name'}
							type='text'
							name='firstName'
							value={userData.firstName}
							onChange={handleChange}
						/>
						<Input
							className={'w-full'}
							label={'Last Name'}
							type='text'
							name='lastName'
							value={userData.lastName}
							onChange={handleChange}
						/>
					</div>

					<div className={'w-full mt-2 mb-4'}
						 style={{height: '1px', background: 'rgba(52, 59, 79, 1)'}}></div>

					<div className={'flex justify-between align-center gap-3 mb-4'}>
						<EmailIcon background={'rgba(255, 255, 255, 1)'}/>
						<Input
							className={'w-full'}
							label={'Email'}
							type={'text'}
							name='email'
							value={userData.email}
							onChange={handleChange}
						/>
					</div>
					{/* Additional fields like phone, position, etc. If is needed */}
				</div>
				<div className={'flex align-middle justify-center center'}>
					<button
						type='submit'
						className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
							!allFieldsFilled || loading || !isChanged ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
						disabled={!allFieldsFilled || loading || !isChanged}
					>
						{loading ? 'Updating User...' : 'Update User'}
					</button>
				</div>
			</form>
		</div>
	);
};

PersonalInfoRender.propTypes = {
	userData: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		email: PropTypes.string,
		profilePicture: PropTypes.shape({
			url: PropTypes.string,
		}),
		phone: PropTypes.string,
		position: PropTypes.string,
		location: PropTypes.string,
		website: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
	allFieldsFilled: PropTypes.bool.isRequired,
	isChanged: PropTypes.bool.isRequired
};