import React from 'react';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Context, Essentials, Bold, Italic, Paragraph, Heading, Link, List, Alignment, FontSize, FontFamily, FontColor, FontBackgroundColor, Underline, Strikethrough, ContextWatchdog } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

export const CKEditorInput = ({ label, name, value, onChange }) => {
	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		onChange({ target: { name, value: data } });
	};

	return (
		<div className='w-full mb-4'>
			{label && <label className='font-sans mb-4 text-cloudyBlue-default dark:text-text-dark self-start'>{label}</label>}
			<CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
				<CKEditor
					editor={ClassicEditor}
					config={{
						plugins: [
							Essentials, Bold, Italic, Paragraph, Heading, Link, List, Alignment,
							FontSize, FontFamily, FontColor, FontBackgroundColor, Underline, Strikethrough
						],
						toolbar: [
							'undo', 'redo', '|', 'heading', 'bold', 'italic', 'underline', 'strikethrough',
							'link', 'numberedList', 'bulletedList', 'alignment', '|', 'fontSize', 'fontFamily',
							'fontColor', 'fontBackgroundColor'
						]
					}}
					data={value}
					onChange={handleEditorChange}
					onReady={(editor) => {
						console.log('CKEditor is ready.');
					}}
				/>
			</CKEditorContext>
		</div>
	);
};