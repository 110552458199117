import Modal from '../../../../components/ui/Modal/Modal';
import {OrganizationsFindMany, UsersFindOneById} from '../../../../hoc';
import Button from '../../../../components/ui/Button';
import PropTypes from 'prop-types';

export const AddToOrganizationUserRender = ({
	currentUserId,
	isOpenState,
	isLoading,
	values,
	errors,
	handleOnChange,
	onSubmit,
	isChanged
}) => {
	const [isFormOpen, setIsFormOpen] = isOpenState;
	return (
		<Modal isOpen={isFormOpen} setIsOpen={setIsFormOpen}>
			<div className={'w-600px flex flex-col items-center w-full'}>
				<h2>Edit User: </h2>
				<UsersFindOneById id={currentUserId._id}>
					{(userData) => (
						<form className={'w-full items-center gap-2'} onSubmit={onSubmit}>
							<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
								<label className={'font-sans mt-3 ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
									Select an Organization for
									to <strong>{userData.firstName} {userData.lastName}</strong>
								</label>
								<OrganizationsFindMany>
									{(organizations) => (
										<select
											name='organization'
											value={values.organization}
											onChange={(e) => handleOnChange(e.target.name, e.target.value)}
											className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
											required
										>
											<option value=''>Select an Organization</option>
											{organizations.map((organization) => (
												<option key={organization._id} value={organization._id}>
													{organization.name}, {organization.code}
												</option>
											))}
										</select>
									)}
								</OrganizationsFindMany>
							</div>
							<Button
								disable={isChanged[0]}
								type={'submit'}
								className={`${isLoading || !values.organization ? 'bg-purple-400' : 'bg-brightLilac-default'}`}
							>
								{isLoading ? 'Update' : 'Updating...'}
							</Button>
							{errors.submit ? <p className={'text-red-500'}>Error updating user</p> : null}
						</form>
					)}
				</UsersFindOneById>
			</div>
		</Modal>
	);
};

AddToOrganizationUserRender.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	isOpenState: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	values: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		userName: PropTypes.string,
		email: PropTypes.string,
		organization: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		submit: PropTypes.object,
	}),
	handleOnChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	isChanged: PropTypes.arrayOf([
		PropTypes.bool.isRequired,
		PropTypes.func.isRequired
	]).isRequired
};