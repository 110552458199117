import {CoursesTableMainWrapper} from '../Courses/Courses.style';
import React from 'react';
import Select from '../../components/ui/Select';
import Button from '../../components/ui/Button';
import {SparkLibraryRow} from './components';
import {CreateSparkLibraryForm} from './forms';
import {DeleteSparkLibraryModal} from './components/DeleteSparkLibraryModal';

export const SparkLibrariesRender = ({ isOpen,setIsOpen, isDeleteOpen, setIsDeleteOpen, sparkLibraries, currentSparkLibrary, handleCreate, handleEdit,handleDelete, refetch ,lessonOptions,handleLessonChange}) =>{

	return (
		<div className={'flex text-text-dark flex-col items-start gap-4'}>
			{ isDeleteOpen?
				<DeleteSparkLibraryModal isOpenState={[isDeleteOpen, setIsDeleteOpen]} currentSparkLibrary={currentSparkLibrary}/>
				: null
			}
			{isOpen ?
				<CreateSparkLibraryForm isOpenState={[isOpen, setIsOpen]} currentSparkLibrary={currentSparkLibrary} refetch={refetch}/>
				: null
			}

			<h1>Sparks Libraries</h1>
			<div className={'w-full flex items-center gap-3 justify-between'}>
				<Select
					className={'bg-background-dark br'}
					options={lessonOptions}
					onChange = {handleLessonChange}
				/>
				<Button
					type={'button'}
					color={'bg-brightLilac-default'}
					className={'font-bold w-[200px]'}
					onClick = {handleCreate}
				> Add new +</Button>
			</div>
			<div className={'w-full br border-text-dark border overflow-hidden'}>
				<CoursesTableMainWrapper
					className={'w-100 mt rounded-lg p2.5'}
					style={{border: '1px solid transparent', boxDecorationBreak: 'unset'}}
				>
					<thead>
						<tr className={'t-white'}>
							<th className={'left main-color-background p-4'}/>
							<th className={'left main-color-background p-4'}>Name</th>
							<th className={'left font-mono main-color-background p-2'}>Description</th>
							<th className={'left font-mono main-color-background p-2'}>slug</th>

							{/*action*/}
							<th className={'left font-mono main-color-background p-2'}>Video</th>
							<th className={'left font-mono main-color-background p-2'}>Video Questions</th>
							<th className={'left font-mono main-color-background p-2'}>Article</th>
							<th className={'left font-mono main-color-background p-2'}>Fun fact</th>
							<th className={'left font-mono main-color-background p-2'}>Journals</th>
							<th className={'left font-mono main-color-background p-2'}>Mindful</th>
							<th className={'left font-mono main-color-background p-2'}>actions</th>
						</tr>
					</thead>
					<tbody className={'font-sans text-xs text-text-dark'}>
						{
							sparkLibraries.map((sparkL, index) => (
								<SparkLibraryRow
									key={sparkL._id} index={index}
									sparkL={sparkL}
									handleEdit={handleEdit}
									handleDelete={handleDelete}
									refetch={refetch}
								/>
							))
						}
					</tbody>
				</CoursesTableMainWrapper>
			</div>
		</div>
	);
};