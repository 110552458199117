import React from 'react';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {Link} from 'react-router-dom';

export const Responses=({onChange,onClick,values,name,label})=>{
	let {data,loading,error}=useQuery(query('responsesFindMany'));
	if(loading) return <p>Loading..</p>;
	if(error) return <p>Error</p>;
	
	const addResponse=({target:{value}})=>{
		let aux=[...values];
		aux.push(JSON.parse(value));
		onChange({values:aux,name});
	};
	
	return (
		<div>
			<p
				style={{textTransform:'capitalize'}}
				className='font-mono mt-4 f-700 text-text-light dark:text-text-dark'>
				{label}
			</p>
			<select className={'flex justify-center align-center text-white'}
				style={{background:'rgba(10, 19, 48, 1)', border:'1px solid rgba(11, 23, 57, 1)'}}
				onChange={addResponse} value={''}
				name={name}>
				<option className={'ml-2 p-1'} value=''>Select One</option>
				{
					data.ResponsesFindMany.map(i=>
						<option className={'text-text-light dark:text-text-dark'}
							key={i._id}
							value={JSON.stringify(i)}>{i.label}
						</option>
					)
				}
			</select>
			<ul className={'mt-3'}>
				{values && values.map(i=>
					<li
						className={'font-mono f-500 text-text-light dark:text-text-dark'}
						onClick={onClick}
						key={i._id}>{i.label}
						<Link to={`/response/${i._id}`}>got to</Link>
					</li>
				)}
			</ul>
		</div>
	);
};