import React from 'react';
import {svgPropTypes} from '../../types';

const FavoriteIcon = ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 14 15'
			fill='none'
			className={className}
			style={{ ...style }}
		>
			<path
				d='M6.57786 1.06151C6.76631 0.73796 7.23373 0.737959 7.42219 1.06151L9.41871 4.48932C9.48774 4.60785 9.60342 4.69189 9.73747 4.72092L13.6145 5.56047C13.9804 5.63972 14.1249 6.08426 13.8754 6.36347L11.2323 9.32153C11.1409 9.42381 11.0967 9.55979 11.1105 9.69626L11.5101 13.6429C11.5479 14.0155 11.1697 14.2902 10.8271 14.1392L7.19703 12.5396C7.07152 12.4843 6.92853 12.4843 6.80302 12.5396L3.17298 14.1392C2.83034 14.2902 2.45219 14.0155 2.48991 13.6429L2.8895 9.69626C2.90332 9.55979 2.85914 9.42381 2.76775 9.32153L0.124663 6.36347C-0.124818 6.08426 0.0196233 5.63972 0.385574 5.56047L4.26257 4.72092C4.39663 4.69189 4.51231 4.60785 4.58134 4.48932L6.57786 1.06151Z'
				fill={fillColor}
			/>
		</svg>
	);
};

FavoriteIcon.propTypes = svgPropTypes;

FavoriteIcon.defaultProps = {
	width: '14',
	height: '15',
	background: 'transparent',
	className: '',
	style: {},
	isActive: false,
};

export default FavoriteIcon;