import PropTypes from 'prop-types';
import Input from './Input';
import React from 'react';

export const CaptionsInput = ({ captions, onChangeFile, subtitleLanguage }) => {
	return (
		<div className='w-full'>
			{captions.length > 0 ? (
				<>
					<label className={'font-sans text-text-light dark:text-text-dark self-start f-600'}>
						Subtitles already uploaded:
					</label>
					<table className='min-w-full border-collapse mb-4 rounded-lg' style={{borderColor: 'rgba(174, 185, 225, 1)'}}>
						<thead>
							<tr className='text-left text-sm font-medium text-text-light dark:text-text-dark'>
								<th className='py-2 px-4 bg-background-light dark:bg-background-dark'>Title</th>
								<th className='py-2 px-4 bg-background-light dark:bg-background-dark'>Available</th>
								<th className='py-2 px-4 bg-background-light dark:bg-background-dark'>Language</th>
							</tr>
						</thead>
						<tbody>
							{captions.map((caption, index) => (
								<tr
									key={index}
									className={`border-t border-gray-200 ${index % 2 === 0 ? 'bg-midnight-default' : 'bg-gray-800'} hover:bg-gray-700`}>
									<td className='py-2 px-4 text-sm text-gray-300'>
									caption N#{index + 1}
									</td>
									<td className='py-2 px-4 text-sm text-gray-300'>{caption.available ? 'Yes' : 'No'}</td>
									<td className='py-2 px-4 text-sm text-gray-300'>{caption.language}</td>
								</tr>
							))}
						</tbody>
					</table>
					<label className={'font-sans text-text-light dark:text-text-dark self-start  f-600 mt-3'}>
						Also you can upload new subtitles:
					</label>
				</>
			) : (
				<Input
					label='Upload Closed Captions'
					type='file'
					name='subtitles'
					accept='.vtt'
					onChange={onChangeFile}
					className='file:bg-brightLilac-default file:text-white file:py-2 file:px-4 file:rounded file:cursor-pointer file:font-mono'
				/>
			)}
			{captions.length > 0 && (
				<Input
					label='Upload Closed Captions'
					type='file'
					name='subtitles'
					accept='.vtt'
					onChange={onChangeFile}
					className='file:bg-brightLilac-default file:text-white file:py-2 file:px-4 file:rounded file:cursor-pointer file:font-mono'
				/>
			)}
		</div>
	);
};

CaptionsInput.propTypes = {
	captions: PropTypes.arrayOf(
		PropTypes.shape({
			file: PropTypes.shape({
				url: PropTypes.string.isRequired,
			}).isRequired,
			language: PropTypes.string.isRequired,
			available: PropTypes.bool.isRequired,
		})
	).isRequired,
	onChangeFile: PropTypes.func.isRequired,
	subtitleLanguage: PropTypes.string.isRequired,
};