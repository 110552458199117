import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

// questions array of {question:string, points :number} objects
// onChangeQuestionsArray : update father function args : {id:string, newQuestions : string[]}
export const useQuestionsLayout =({questions,onChangeQuestionsArray})=>{
	const questionsIds =questions.map(q => q.question);

	// findQuestions query
	const { data:dataQuestions, loading: loadingQuestions, error:errorQuestions } = useQuery(query('questionsFindMany'), {
		variables: {
			filter: {
				_operators: {
					_id: {
						in: questionsIds || [],
					},
				},
			},
		}
	});

	// get a {points:number,question:idString} object
	const onUpdateQuestion= async(question)=>{
		const editedIndex = questions.indexOf( questions.filter(q=>q.question === question.question)[0] );
		const newQuestions = questions;
		newQuestions[editedIndex].points=question.points;
		onChangeQuestionsArray && await onChangeQuestionsArray(newQuestions);
	};
	// get a {points:number,question:idString} object
	const onCreateQuestion = async (question)=>{
		const newQuestions = [
			...questions,
			question
		];
		onChangeQuestionsArray && await onChangeQuestionsArray(newQuestions);
	};

	const onDeleteQuestion = async(id) =>{
		const deletedId = id;
		const newQuestions =questions.filter(q=>q.question !==deletedId);
		try {
			onChangeQuestionsArray && await onChangeQuestionsArray(newQuestions);
		} catch (error) {
			console.error('Error creating QuestionsView', error);
		}
	};
    
	const filteredQuestions = dataQuestions ?dataQuestions['QuestionsFindMany'] : [];
    
	return {
		questions: filteredQuestions,

		onUpdateQuestion,
		onDeleteQuestion,
		onCreateQuestion,
	};
};