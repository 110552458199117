import React, {useEffect, useState} from 'react';
import {Separator, TagsWrapper} from './TagsSelector.style';
import {EyeIcon} from '../../../icons';
import PropTypes from 'prop-types';

export default function TagsSelector({ selectedTags, setSelectedTags, HOC, label }) {
	const [visibleCount, setVisibleCount] = useState(10);

	useEffect(() => {
		setVisibleCount(1000);
	}, [selectedTags]);

	const handleTagClick = (tag) => {
		const isSelected = selectedTags.some(selected => selected._id === tag._id);
		if (isSelected) {
			setSelectedTags(selectedTags.filter(selected => selected._id !== tag._id));
		} else {
			setSelectedTags([...selectedTags, tag]);
		}
	};

	const handleShowMore = () => {
		setVisibleCount(prevCount => prevCount + 10);
	};

	const filterAvailableTags = (allTags, selectedTags) => {
		const selectedIds = selectedTags.map(tag => tag._id);
		return allTags.filter(tag => !selectedIds.includes(tag._id));
	};

	if (!selectedTags || !Array.isArray(selectedTags)) return <div>Error: Invalid selected tags</div>;

	return (
		<div className={'w-100 mb-4 flex direction-column justify-start align-start self-start'}>
			<label className={'font-sans font-black ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>{label}</label>
			<HOC>
				{tags => {
					const availableTags = filterAvailableTags(tags, selectedTags);
					return (
						<>
							<TagsWrapper>
								{selectedTags.map(tag => (
									<span
										key={tag._id}
										onClick={() => handleTagClick(tag)}
										className={'font-sans f-600 flex gap-1 align-center justify-center text-white text-center cursor-pointer bg-gray-200 border-gray-400'}
										style={{backgroundColor: '#071033', borderColor: '#ff0000'}}
									>
										<EyeIcon />{tag.label}
									</span>
								))}
							</TagsWrapper>
							<div className={'w-full mt-3 mb-3'} style={{height: '3px',backgroundColor: 'rgba(8, 16, 40, 1)'}}></div>
							<TagsWrapper>
								{availableTags.slice(0, visibleCount).map(tag => (
									<span
										key={tag._id}
										onClick={() => handleTagClick(tag)}
										className={'font-sans f-400 text-white text-center cursor-pointer'}
										style={{backgroundColor: selectedTags.some(selected => selected._id === tag._id) ? '#071033' : 'transparent', borderColor: selectedTags.some(selected => selected._id === tag._id) ? '#ff0000' : '#ffffff'}}
									>
										{tag.label}
									</span>
								))}
								{availableTags.length > visibleCount && (
									<span
										className={'font-sans f-600 text-white text-center cursor-pointer'}
										onClick={handleShowMore}
									>
                                        + {Math.min(availableTags.length - visibleCount, 10)} more
									</span>
								)}
							</TagsWrapper>
						</>
					);
				}}
			</HOC>
		</div>
	);
}

TagsSelector.propTypes = {
	selectedTags: PropTypes.array.isRequired,
	setSelectedTags: PropTypes.func.isRequired,
	HOC: PropTypes.elementType.isRequired,
	label: PropTypes.string.isRequired
};