import React, {useState} from 'react';
import {CoursesTableMainWrapper} from '../../../Courses/Courses.style';
import Button from '../../../../components/ui/Button';
import {DocumentForm} from '../../../LessonsEdit/components';
import PropTypes from 'prop-types';
import {uploadCurriculumDocument} from '../../../../actions/restactions';

export const DocsTab = ({id,documents}) => {
	const [showDocumentForm,setShowDocumentForm] = useState(false);

	return (
		<div className={'w-full text-white px-4'}>
			<div className={'flex justify-between gap-4 items-center mb-4'}>
				<h2 className={'h2'}>Lesson Documents</h2>
				<Button type={'button'} className={'bg-brightLilac-default'} onClick={()=>setShowDocumentForm(true)}>
					New Document +
				</Button>
			</div>

			<div className={'w-full br border-text-dark border overflow-hidden px-2 pb-4'}>
				<CoursesTableMainWrapper
					className={'w-100 mt rounded-lg p2.5 '}
					style={{border: '1px solid transparent', boxDecorationBreak: 'unset'}}
				>
					<thead>
						<tr className={'t-white'}>
							<th className={'left main-color-background p-2'}>Title</th>
							<th className={'left font-mono main-color-background p-2'}>Link</th>
							<th className={'left font-mono main-color-background p-2'}>type</th>
						</tr>
					</thead>
					<tbody className={'font-sans text-xs text-text-dark '}>
						{documents.map((d, id)=>
							<tr className={'hover:bg-midnight-default my-2'} key={d.url+id}>
								<td className={'pr-4 py-1'}> {d.title}</td>
								<td className={'px-4 truncate max-w-2xl'}>
									<a href={d.url}>url: {d.url}</a>
								</td>
								<td>{d.type}</td>
							</tr>
						)}

					</tbody>
				</CoursesTableMainWrapper>
			</div>
			

			{!documents.length?
				<div className={'pt-10 w-100'}>
					<p className={'h4 center'}>This lesson do not have documents yet</p>
				</div>
				: null
			}

			<DocumentForm
				id={id}
				isOpen={showDocumentForm}
				setIsOpen={setShowDocumentForm}
				uploadDocument={uploadCurriculumDocument}
			/>
		</div>
	);
};

DocsTab.prototype ={
	id:PropTypes.string.isRequired,
	documents:PropTypes.arrayOf(PropTypes.shape({
		title:PropTypes.string.isRequired,
		url:PropTypes.string.isRequired,
		type:PropTypes.string.isRequired,
	}))
};