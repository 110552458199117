import gql from 'graphql-tag';

export const mutations = {
	userCreateOne: gql`
        mutation CreateUser(
            $firstName: String!,
            $lastName: String!,
            $email: String!,
            $organization: String!,
            $type: String!,
            $password: String!
        ) {
            CreateUser(
                firstName: $firstName,
                lastName: $lastName,
                email: $email,
                organization: $organization,
                type: $type,
                password: $password
            )
        }
	`,
	userUpdateOne: gql`
        mutation UserUpdateOne(
            $_id: String!,
			$record: UpdateByIdusersInput!
        ){
           UserUpdateOne(_id: $_id, record: $record) {
               recordId
               record {
                   _id
                   id
                   firstName
                   lastName
                   userName
                   tag
                   bio
                   birthday
                   email
                   profilePicture{
                       url
                       type
                   }
                   points
                   type
                   lastLogin
                   organization
                   cleverId
                   classLinkId
                   cleverSync
                   classLinkSync
                   deedlyAuthenticationToken
                   deedlyUserId
                   createdAt
                   updatedAt
               }
               error {
                   message
               }
		   }
        }
	`,
	setUserOrganization: gql`mutation SetUserOrganization($user: String!, $organization: String!){
  			SetUserOrganization(user: $user, organization: $organization)
  	}`
};

export const usersMutations = ( value ) => {
	return mutations[ value ];
};