import {Search} from '../../components';
import {
	UserCounterTitleTable,
	UserPageWrapperElements, UserTableMainWrapper,
} from './District.style';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../components/ui/Button';
import React, {useState} from 'react';
import Modal from '../../components/ui/Modal/Modal';
import {DistrictForm} from './forms';
import {DistrictArrow} from './components';


export const DistrictRender = ({ Districts }) => {
	const history = useHistory();
	const [showCreate, setShowCreate] = useState(false);
	const handleCreateDistrict= ()=>setShowCreate(true);
	return (
		<div className={'w-full h-full m-2'}>
			{setShowCreate ?
				<Modal isOpen={showCreate} setIsOpen={setShowCreate}>
					<DistrictForm onSuccess={()=>setShowCreate(false)}/>
				</Modal>
				: null
			}
			<div className={'w-full flex justify-between items-center p-1 mb-3 gap-6'}>
				<div className={'flex'}>
					<h1 className={'font-sans text-white f-500 text-2xl'}>Districts</h1>
					<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
				</div>
				<Button
					type={'button'}
					className={'font-bold text-sm border-2 bg-brightLilac-default'}
					onClick = {handleCreateDistrict}
				> Add District +</Button>
			</div>
			<div className={'flex justify-end items-center mb-3'}>
				<div className={'font-mono f-500 text-sm text-white'}>
					<span><strong style={{color: 'rgba(203, 60, 255, 1)'}}>{Districts.length} </strong></span>
					<span>of {Districts.length}</span>
				</div>
			</div>
			<div className={'w-full mb-4'} style={{ height: '1px', border: '1px solid rgba(52, 59, 79, 1)' }} />
			<UserPageWrapperElements className={''}>
				<UserTableMainWrapper className='mt rounded-lg p-2.5'>
					<thead>
						<tr>
							<th className={'left font-mono main-color-background p-2 t-white'}>Name</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>State</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Country</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Created At</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Updated At</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Actions</th>
						</tr>
					</thead>
					<tbody>
						{Districts.map((district,index) => (
							<DistrictArrow district={district} index={index} key={district?._id}/>
						))}
					</tbody>
				</UserTableMainWrapper>
			</UserPageWrapperElements>
			<div className={'flex justify-end items-center mt-3 mb-3'}>
				<UserCounterTitleTable className={'font-mono'}>
					<span>1 - {Districts.length} </span>
					<span>of {Districts.length}</span>
				</UserCounterTitleTable>
			</div>
		</div>
	);
};

DistrictRender.propTypes = {
	Districts: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		state: PropTypes.string,
		country: PropTypes.string,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string
	})).isRequired
};