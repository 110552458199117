export const LifeSkillDataMock = [
	{
		'_id': '5bba0cc0-1391-11ef-b959-99b0f59cfbe1',
		'label': 'Self Management',
		'identifier': 'self-management',
		'icon': {
			'url': 'https://picsum.photos/id/1018/3200/4800',
			'type': null
		},
		'description': 'self-management description here',
		'deletedAt': null,
		'createdAt': '2024-05-16T14:34:10.188Z',
		'updatedAt': '2024-05-16T14:34:10.188Z'
	},
	{
		'_id': '67e596e0-1391-11ef-b959-99b0f59cfbe1',
		'label': 'Relationship Skills',
		'identifier': 'relationship-skills',
		'icon': {
			'url': 'https://picsum.photos/id/1018/3200/4800',
			'type': null
		},
		'description': null,
		'deletedAt': null,
		'createdAt': '2024-05-16T14:34:30.607Z',
		'updatedAt': '2024-05-16T14:34:30.607Z'
	},
	{
		'_id': '73124ea0-1391-11ef-b959-99b0f59cfbe1',
		'label': 'Social Awareness',
		'identifier': 'social-awareness',
		'icon': {
			'url': 'https://picsum.photos/id/1018/3200/4800',
			'type': null
		},
		'description': null,
		'deletedAt': null,
		'createdAt': '2024-05-16T14:34:49.356Z',
		'updatedAt': '2024-05-16T14:34:49.356Z'
	},
	{
		'_id': '7f4308e0-1391-11ef-b959-99b0f59cfbe1',
		'label': 'Responsible Decision-Making',
		'identifier': 'responsible-decision-making',
		'icon': {
			'url': 'https://picsum.photos/id/1018/3200/4800',
			'type': null
		},
		'description': null,
		'deletedAt': null,
		'createdAt': '2024-05-16T14:35:09.806Z',
		'updatedAt': '2024-05-16T14:35:09.806Z'
	},
	{
		'_id': 'fa91c410-1390-11ef-b959-99b0f59cfbe1',
		'label': 'Self Awareness',
		'identifier': 'self-awareness',
		'icon': {
			'url': 'https://picsum.photos/id/1018/3200/4800',
			'type': null
		},
		'description': null,
		'deletedAt': null,
		'createdAt': '2024-05-16T14:31:27.187Z',
		'updatedAt': '2024-05-16T14:31:27.187Z'
	}
];