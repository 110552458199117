import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';


export const  SchoolFindMany = ({children}) => {

	const { loading, error, data } = useQuery(query('schoolFindMany'),{
		variables: {}
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.SchoolFindMany ) return <p className={'font-mona f-900 text-white p-3'}>School not found</p>;

	return children(data.SchoolFindMany);
};

SchoolFindMany.propTypes = {
	children: PropTypes.func.isRequired,
};