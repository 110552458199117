import {ExtraQuestionView} from './ExtraQuestionView';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../../actions/mutations';
import PropTypes, {string} from 'prop-types';
import {useQuestionsLayout} from '../../../../hooks';

export const ExtraQuestionViewLoad = ({lesson})=>{
	const [ addQuestionModal, setAddQuestionModal ] = useState(false);

	const lessonExtraQuestions = lesson.extraQuestions?.map(item => ({
		question: item.question,
		points: item.points
	}))||[];
	
	
	const [updateLesson] = useMutation(mutations.classesUpdateOne, {
		refetchQueries: ['ClassesAdminFindOne'],
		awaitRefetchQueries: true
	});

	const onChangeQuestionsArray =async (newQuestions)=>{
		await updateLesson({
			variables:{id:lesson._id, record:{ extraQuestions: newQuestions }}
		});
	};

	const { questions, onUpdateQuestion, onDeleteQuestion, onCreateQuestion } = useQuestionsLayout({questions:lessonExtraQuestions,onChangeQuestionsArray});

	return (
		<ExtraQuestionView
			addQuestionModal={addQuestionModal}
			questions={questions}
			questionPoints={lessonExtraQuestions}

			setAddQuestionModal={setAddQuestionModal}
			onUpdateQuestion={onUpdateQuestion}
			onDeleteQuestion={onDeleteQuestion}
			onCreateQuestion={onCreateQuestion}
		/>
	);
};

ExtraQuestionViewLoad.propTypes = {
	lesson: PropTypes.shape({
		_id:string,
	}).isRequired,
};