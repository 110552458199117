import {BadgesEditRender} from './BadgesEditRender';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {useEffect, useState} from 'react';
import {mutations} from '../../../actions/mutations';
import {uploadBadgeCover, uploadBadgeVideo} from '../../../actions/restactions';


export const BadgesEditLoad = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('badgesFindOne'), {
		variables: { filter: { _id: id } },
	});

	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		curriculum: '',
		cover: { url: '', type: '' },
		video: { url: '', type: '' },
		createdAt: '',
		updatedAt: '',
		deletedAt: null,
	});
	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);

	const [updateBadge] = useMutation(mutations.BadgesUpdateOne, {
		refetchQueries: [{ query: query('badgesFindMany') }],
		awaitRefetchQueries: true
	});

	useEffect(() => {
		if (data) {
			const badge = data.BadgesFindOne;
			setFormState({
				label: badge.label,
				identifier: badge.identifier,
				curriculum: badge.curriculum,
				cover: badge.cover,
				video: badge.video,
				createdAt: badge.createdAt,
				updatedAt: badge.updatedAt,
				deletedAt: badge.deletedAt,
			});
			setPreview(badge.cover?.url || null);
			setVideoPreview(badge.video?.url || null);
		}
	}, [data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await updateBadge({
				variables: {
					_id: id,
					record: {
						label: formState.label,
						identifier: formState.identifier,
						curriculum: formState.curriculum,
					},
				},
			});

			if (selectedFile) {
				await uploadBadgeCover(selectedFile, id);
			}

			if (videoFile) {
				await uploadBadgeVideo(videoFile, id);
			}

			setIsChanged(false);
			history.push('/badges');
		} catch (error) {
			console.error('Error updating badge:', error);
		}
	};

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	const allFieldsFilled = !!(formState.label && formState.identifier && formState.curriculum);

	return (
		<BadgesEditRender
			formState={formState}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleVideoChange={handleVideoChange}
			handleSubmit={handleSubmit}
			preview={preview}
			videoPreview={videoPreview}
			allFieldsFilled={allFieldsFilled}
		/>
	);
};