import React from 'react';
import Input from '../../../../components/ui/Input';
import PropTypes  from 'prop-types';
import {CKEditorInput} from '../../../../components';

export const QuestionForm=({isLoading,stateForm,onSubmit,handleChange,close})=>{
	
	return (
		<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
			<h1 className={'text-2xl'}>Add Question</h1>
			<CKEditorInput
				value={stateForm.label}
				name={'label'}
				onChange={handleChange}
				label='Type your new question'
			/>
			<Input value={stateForm.identifier} name={'identifier'} type='text' onChange={handleChange} placeholder='Set a unique identifier for this response'/>
			<Input value={stateForm.points} type ='number' min={0} max={500} name={'points'} onChange={handleChange} placeholder='Set the points value for this answer'/>
			<div className={'flex gap-2'}>
				<button
					type = 'button'
					onClick={close}
					className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}
				>
					cancel
				</button>

				<button
					disabled={isLoading}
					type = 'submit'
					className={'font-mono px-2 py-0.5  text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
				>
					{isLoading? 'loading...' : 'save' }
				</button>
			</div>
		</form>
	);
};

QuestionForm.prototype={
	isLoading:PropTypes.bool.isRequired,
	stateForm: PropTypes.shape({
		label:PropTypes.string.isRequired,
		identifier:PropTypes.string.isRequired,
	}),
	handleChange:PropTypes.func.isRequired,
	onSubmit:PropTypes.func.isRequired,
	close:PropTypes.func.isRequired,
};