import styled from 'styled-components';

export const CollectiblesWrapperElementsAddOne = styled.div`
	width: 100%;
	height: 100%;
    border: 1px solid rgba(52, 59, 79, 1);
	border-radius: 8px;
	background-color: rgba(11, 23, 57, 1);

    span:first-child{
        font-size: 1rem;
        color: rgba(255, 255, 255, 1);
    }
	
	div:last-child>div{
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background-color: rgba(203, 60, 255, 0.5);
		span{
            font-size: 2rem;
		}
	}
    &:hover {
        background: rgb(18, 29, 61)
    }
`;

export const CollectiblesWrapperElements = styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid rgba(52, 59, 79, 1);
    border-radius: 8px;
    background-color: rgba(11, 23, 57, 1);

    span:first-child{
        font-size: 1rem;
        color: rgba(255, 255, 255, 1);
    }
	
    p:last-child{
        font-size: 0.725rem;
        color: rgba(174, 185, 225, 1);
    }


    &:hover {
        background: rgb(18, 29, 61)
    }
	
	img{
		width: 42px;
		height: 42px;
		object-fit: cover;
		aspect-ratio: 16/9;
		border-radius: 50%;
	}
`;

export const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.$cols}, 1fr);

    row-gap: ${props => (props.$rowGap ? props.$rowGap : props.$gap)};
    column-gap: ${props => (props.$colGap ? props.$colGap : props.$gap)};
    place-items: ${props => (props.$placeItems ? props.$placeItems : 'normal')};

    >* {
        place-self: stretch;
    }

    @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }
`;


export const CollectiblesTitle = styled.h1`
	font-size: 1rem;
	font-weight: 500;
    color: rgba(255, 255, 255, 1);
`;

export const CollectiblesPageContainer = styled.div`
	width: 100%;
	height: 100%;
`;