import gql from 'graphql-tag';

export const mutations={
	createOne:gql`
        mutation DistrictCreateOne($record:CreateOnedistrictInput!){
            DistrictCreateOne(record: $record) {
                recordId
                record {
                    _id
                    name
                    state
                    country
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	updateOne:gql`
    mutation DistrictUpdateOne($id:String!, $record:UpdateByIddistrictInput!){
        DistrictUpdateOne(_id: $id, record: $record) {
            recordId
            record {
                _id
                name
                state
                country
                createdAt
                updatedAt
            }
            error {
                message
            }
        }
    }`
};

export const districtMutations=(value)=>{
	return mutations[value];
};



