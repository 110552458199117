export const skillSetDataMock = [
	{
		_id: '23a27c60-fbf3-11ee-8e2c-c9de515ec1a6',
		label: 'Skill Set Update Test',
		identifier: 'id',
		icon: {
			url: 'https://picsum.photos/id/1018/3200/4800',
			type: 'test'
		},
		description: 'Description Update',
		deletedAt: null,
		createdAt: '2024-04-16T13:13:38.857Z',
		updatedAt: '2024-04-16T13:18:16.656Z'
	},
	{
		_id: 'e6a907c0-fe9a-11ee-a162-95074534017e',
		label: 'Skill Set Test',
		identifier: 'id',
		icon: {
			url: 'https://picsum.photos/id/1018/3200/4800',
			type: 'test'
		},
		description: 'Random Description',
		deletedAt: null,
		createdAt: '2024-04-19T22:19:34.334Z',
		updatedAt: '2024-04-19T22:19:34.334Z'
	},
	{
		_id: 'f56621c0-fbf3-11ee-bf7d-678ad6e187c9',
		label: 'Skill Set Test',
		identifier: 'id',
		icon: {
			url: 'https://picsum.photos/id/1018/3200/4800',
			type: 'test'
		},
		description: 'Random Description',
		deletedAt: null,
		createdAt: '2024-04-16T13:19:30.794Z',
		updatedAt: '2024-04-16T13:19:30.794Z'
	}
];
