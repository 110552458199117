import {useState} from 'react';
import {PinCreateRender} from './PinCreateRender';
import {useMutation} from '@apollo/client';
import {query} from '../../../actions/queries';
import {mutations} from '../../../actions/mutations';
import {uploadPinCover, uploadPinVideo} from '../../../actions/restactions';
import {useHistory} from 'react-router-dom';

export const PinCreateLoad = () => {
	const history = useHistory();
	const [formState, setFormState] = useState({
		label: '',
		class: '',
		sparkLibrary: '',
	});
	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);

	const [createPin, { loading: isLoading }] = useMutation(mutations.PinCreateOne, {
		refetchQueries: [{ query: query('pinFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createPin({
				variables: {
					record: {
						label: formState.label,
						identifier: formState.identifier,
						class: formState.class,
						sparkLibrary: formState.sparkLibrary,
					},
				},
			});

			const createdPinId = data.PinCreateOne.recordId;

			if (selectedFile) {
				await uploadPinCover(selectedFile, createdPinId);
			}

			if (videoFile) {
				await uploadPinVideo(videoFile, createdPinId);
			}

			setIsChanged(false);
			history.push('/pin');
		} catch (error) {
			console.error('Error creating pin:', error);
		}
	};

	const allFieldsFilled = formState.label && (formState.class || formState.sparkLibrary);

	return (
		<PinCreateRender
			formState={formState}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleVideoChange={handleVideoChange}
			handleSubmit={handleSubmit}
			preview={preview}
			videoPreview={videoPreview}
			allFieldsFilled={allFieldsFilled}
			isLoading={isLoading}
		/>
	);
};