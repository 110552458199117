import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {themesDataMock} from '../../test/mock/themesDataMock';
import React from 'react';
import {
	ThemesPageContainer,
	ThemesStyledGrid,
	ThemesTitle,
	ThemesWrapperElements,
	ThemesWrapperElementsAddOne
} from './Theme.style';
import {PencilIcon} from '../../icons';
import {Search} from '../../components';

export const Themes=()=>{
	const history = useHistory();

	const { loading, error, data } = useQuery(query('ThemeFindMany'), {
		variables: { limit: 1000 },
		nextFetchPolicy: 'network-only'
	});

	const sortedData = data && data.ThemeFindMany ? data.ThemeFindMany : themesDataMock;

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	return (
		<ThemesPageContainer className={'m-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<ThemesTitle className={'font-sans'}>Themes</ThemesTitle>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<ThemesStyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<ThemesWrapperElementsAddOne
					className={'flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer'}
					onClick={() => history.push('/themes/create')}>
					<div className={'flex gap-3 align-center justify-center'}>
						<span className={'font-sans text-base f-600 text-white'}>Add Theme</span>
					</div>
					<div className={'flex gap-3 align-center justify-center'}>
						<div className={'flex align-center justify-center'}>
							<span className={'font-sans f-600'}>+</span>
						</div>
					</div>
				</ThemesWrapperElementsAddOne>
				{sortedData.map(({ _id, label, icon, description }) => (
					<ThemesWrapperElements
						key={_id}
						className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
						onClick={() => history.push(`/themes/${_id}`)}>
						<div className={'flex justify-start'}>
							<div className={'grid'}>
								<span className={'font-sans text-base f-600 text-white'}>{label}</span>
								<p className={'font-mono text-sm truncate overflow-ellipsis'}
								   style={{color: 'rgba(174, 185, 225, 1)'}}
								>{description ? description : 'No description yet'}</p>
							</div>
						</div>
						<div
							className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<PencilIcon className={'ml-auto'}/>
						</div>
					</ThemesWrapperElements>
				))}
			</ThemesStyledGrid>
		</ThemesPageContainer>
	);
};