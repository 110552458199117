import {svgPropTypes} from '../../types';

export const ArrowIndicatorIcon = ({ width, height, background, className, style }) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 14 15'
			 fill='none'
			 className={className}
			 style={{...style}}>
			<path d='M7.31445 2.4268L12.25 7.36231L7.31445 12.2978' stroke='white' strokeLinecap='round'
				  strokeLinejoin='round'/>
			<path d='M12.2499 7.36231L1.75 7.3623' stroke='white' strokeLinecap='round' strokeLinejoin='round'/>
		</svg>
	);
};

ArrowIndicatorIcon.propTypes = svgPropTypes;

ArrowIndicatorIcon.defaultProps = {
	width: '14',
	height: '15',
	background: 'none',
	className: '',
	style: {}
};

export default ArrowIndicatorIcon;