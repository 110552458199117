export const TextArea =({ color,label,className, ...props}) =>{
	return (
		<div className={`my-2  ${className}`}>
			<label className={'font-sans mb-4 text-cloudyBlue-default dark:text-text-dark self-start'}>
				{label}
			</label>
			<textarea
				{...props}
				className={'font-mono w-100 p-2 text-text-light dark:text-text-dark rounded-lg dark:bg-midnight-default border border-cloudyBlue-dark'}
			/>
		</div>
	);
};