import React from 'react';
import PropTypes from 'prop-types';
import {SelectContainer, SelectWrapper} from './SelectDropDown.styles';

export default function SelectDropDown({ minValue, onChange, maxValue}) {
	return (
		<SelectContainer className={'flex justify-center align-center'}>
			<h2 className={'font-sans'}>Rows per page: </h2>
			<SelectWrapper
				className='ml-2 p-1 border rounded'
				defaultValue={minValue}
				onChange={(e) => onChange(Number(e.target.value))}
			>
				<option value='10'>10</option>
				<option value='50'>50</option>
				<option value='100'>100</option>
				<option value='200'>200</option>
				<option value='300'>300</option>
				<option value='400'>400</option>
				<option value={'1000'}>1000</option>
			</SelectWrapper>
		</SelectContainer>
	);
}

//Types for props:
SelectDropDown.propTypes = {
	minValue: PropTypes.number.isRequired,
	maxValue: PropTypes.number,
	onChange: PropTypes.func.isRequired
};