import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';

const UsersTypeFindOne=({type})=>{
	const {data,loading}=useQuery(query('userTypesFindMany'),
		{variables: {filter: {_id: type}},
			fetchPolicy:'network-only'}
	);
	if(loading) return <p>Loading...</p>;
	if(!data) return <p>No-type</p>;
	const userTypeLabel = data.UserTypesFindMany[0]?.label || 'No Label';

	return <p>{userTypeLabel}</p>;
};

export default UsersTypeFindOne;