import gql from 'graphql-tag';

export const mutations={
	createOne:gql`
        mutation SparkLibraryCreateOne($record: CreateOnesparklibraryInput!) {
            SparkLibraryCreateOne(record: $record) {
                recordId
                record {
                    _id
                    title
                    description
                    lesson
                    curriculum
                    type
                    slug
                    deleted
                    order
                    times
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	updateOne:gql`
        mutation SparkLibraryUpdateOne($record:UpdateOnesparklibraryInput!, $id:String!) {
            SparkLibraryUpdateOne(record: $record filter:{_id:$id}) {
                recordId
                record {
                    _id
                    title
                    description
                    lesson
                    curriculum
                    type
                    slug
                    deleted
                    order
                    times
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `
};

export const sparkLibrariesMutations=(value)=>{
	return mutations[value];
};



