import {InfoRender} from './Info.render';
import {LifeSkillFindMany, SkillSetFindMany, ThemesFindMany} from '../../../../hoc';
import {uploadClassCover} from '../../../../actions/restactions';
import {removeTypename as removeTypenameFunction} from '../../../../tools';
import {useMutation, useQuery} from '@apollo/client';
import {mutations} from '../../../../actions/mutations';
import {query} from '../../../../actions/queries';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useParams} from 'react-router-dom';

export const InfoLoad = ({lesson}) => {
	const { id } = useParams();
	const history = useHistory();

	const { data: lifeSkillsData } = useQuery(query('lifeSkillsFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const { data: skillSetsData } = useQuery(query('skillSetFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	const { data: themesData } = useQuery(query('ThemeFindMany'), {
		variables:{ limit: 1000 },
		fetchPolicy: 'network-only'
	});

	console.log('Lesson Learning Goal;', lesson.learningGoal);

	const [isLoading ,setIsLoading] = useState(false);
	const [formState, setFormState] = useState({ ...lesson });
	const [questions, setQuestions] = useState([]);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [selectedLifeSkills, setSelectedLifeSkills] = useState([]);
	const [selectedSkillSets, setSelectedSkillSets] = useState([]);
	const [selectedThemes, setSelectedThemes] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (lesson && lifeSkillsData && skillSetsData && themesData) {
			setFormState({
				...lesson,
				lifeSkill: lesson.lifeSkill || [],
				skillSet: lesson.skillSet || [],
				theme: lesson.theme || [],
				discussion: lesson.discussion ?? [{ title: '', description: '' }],
				extend: lesson.extend ?? [{ title: '', description: '' }],
				reflection: lesson.reflection ?? [{ title: '', description: '' }],
				activity: lesson.activity ?? [{ title: '', description: '' }],
			});
			setQuestions(lesson.questions || []);

			const mappedLifeSkills = lesson.lifeSkill.map(skillId =>
				lifeSkillsData.LifeSkillFindMany.find(skill => skill._id === skillId)
			);
			setSelectedLifeSkills(mappedLifeSkills);

			const mappedSkillSets = lesson.skillSet.map(skillSetId =>
				skillSetsData.SkillSetFindMany.find(skillSet => skillSet._id === skillSetId)
			);
			setSelectedSkillSets(mappedSkillSets);

			const mappedThemes = lesson.theme.map(themeId =>
				themesData.ThemeFindMany.find(theme => theme._id === themeId)
			);
			setSelectedThemes(mappedThemes);
		}
	}, [lesson, lifeSkillsData, skillSetsData, themesData]);

	const [updateLesson] = useMutation(mutations.classesUpdateOne, {
		refetchQueries: [{ query: query('classesAdminFindMany') }],
		awaitRefetchQueries: true
	});

	const removeTypename = (obj) => removeTypenameFunction(obj);

	const handleChange = (e) => {
		const { name, value } = e.target;
		const parsedValue = parseFloat(value);
		setFormState((prev) => ({
			...prev,
			[name]: name === 'order' || name.endsWith('Time') ? Math.max(0, parsedValue) : value
		}));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleLifeSkillsChange = (skills) => {
		setSelectedLifeSkills(skills);
		setIsChanged(true);
	};

	const handleSkillSetsChange = (skills) => {
		setSelectedSkillSets(skills);
		setIsChanged(true);
	};

	const handleThemesChange = (themes) => {
		setSelectedThemes(themes);
		setIsChanged(true);
	};

	const handleArrayChange = (arrayName, index, field, value) => {
		setFormState((prev) => {
			const updatedArray = [...prev[arrayName]];
			updatedArray[index] = { ...updatedArray[index], [field]: value };
			return {
				...prev,
				[arrayName]: updatedArray
			};
		});
		setIsChanged(true);
	};

	const handleAddArrayItem = (arrayName) => {
		setFormState((prev) => ({
			...prev,
			[arrayName]: [...prev[arrayName], { title: '', description: '' }]
		}));
		setIsChanged(true);
	};

	const handleRemoveArrayItem = (arrayName, index) => {
		setFormState((prev) => {
			const updatedArray = prev[arrayName].filter((_, i) => i !== index);
			return {
				...prev,
				[arrayName]: updatedArray.length > 0 ? updatedArray : [{ title: '', description: '' }]
			};
		});
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsSubmitLoading(true);
		try {
			const cleanedFormState = removeTypename(formState);
			const { _id, createdAt, updatedAt, students, trailer, categories, skills, questions, videos, tags, extraQuestions, subcategories, slug, extracurricular, language, learningGoal, resources, ...record } = cleanedFormState;

			const lifeSkillIds = selectedLifeSkills.map(skill => skill?._id).filter(Boolean);
			const skillSetIds = selectedSkillSets.map(skill => skill?._id).filter(Boolean);
			const themeIds = selectedThemes.map(theme => theme?._id).filter(Boolean);

			await updateLesson({
				variables: {
					id: id,
					record: {
						...record,
						learningGoal: formState.learningGoal,
						lifeSkill: lifeSkillIds,
						skillSet: skillSetIds,
						theme: themeIds,
						questions: questions.map(item => ({
							question: item.question,
							points: item.points
						}))
					},
				},
			});

			if (selectedFile) {
				await uploadClassCover(selectedFile, id);
			}

			setIsChanged(false);
			history.push('/lessons');
		} catch (error) {
			console.error('Error updating lesson:', error);
		} finally {
			setIsSubmitLoading(false);
		}
	};

	const getImageSrc = () => {
		if (preview) {
			return preview;
		} else if (formState.cover.url) {
			return formState.cover.url;
		} else {
			return null;
		}
	};

	return (
		<InfoRender
			isSubmitLoading={isSubmitLoading}
			isChanged={isChanged}
			formState={formState}
			setFormState={setFormState}
			selectedLifeSkills={selectedLifeSkills}
			selectedThemes={selectedThemes}
			selectedSkillSets={selectedSkillSets}
			LifeSkillFindMany={LifeSkillFindMany}
			SkillSetFindMany={SkillSetFindMany}
			ThemesFindMany={ThemesFindMany}
			getImageSrc={getImageSrc}
			handleSubmit={handleSubmit}
			handleFileChange={handleFileChange}
			handleChange={handleChange}
			handleThemesChange={handleThemesChange}
			handleLifeSkillsChange={handleLifeSkillsChange}
			handleSkillSetsChange={handleSkillSetsChange}
			handleArrayChange={handleArrayChange}
			handleAddArrayItem={handleAddArrayItem}
			handleRemoveArrayItem={handleRemoveArrayItem}
			isLoading={isLoading}
		/>
	);
};

InfoLoad.propTypes = {
	lesson: PropTypes.object.isRequired
};