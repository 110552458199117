import PropTypes from 'prop-types';
import {LessonsButton} from './LessonsTab.style';
import {Link} from 'react-router-dom';

export const LessonsTab = ({ label, to }) => {
	return (
		<Link to={to}>
			<LessonsButton
				type='button'
				className={'font-mono px-4 py-2 mt-4 font-medium text-white rounded'}
			>
				{label}
			</LessonsButton>
		</Link>
	);
};

LessonsTab.propTypes = {
	label: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

LessonsTab.defaultProps = {
	label: null,
	to: '#'
};