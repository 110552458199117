import {BadgesCreateRender} from './BadgesCreateRender';
import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {query} from '../../../actions/queries';
import {uploadBadgeCover, uploadBadgeVideo} from '../../../actions/restactions';

export const BadgesCreateLoad = () => {
	const history = useHistory();
	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		curriculum: ''
	});

	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);

	const [createBadge] = useMutation(mutations.BadgesCreateOne, {
		refetchQueries: [{ query: query('badgesFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createBadge({
				variables: {
					label: formState.label,
					identifier: formState.identifier,
					curriculum: formState.curriculum,
				},
			});

			const createdBadgeId = data.BadgesCreateOne._id;

			if (selectedFile) {
				await uploadBadgeCover(selectedFile, createdBadgeId);
			}

			if (videoFile) {
				await uploadBadgeVideo(videoFile, createdBadgeId);
			}

			history.push('/badges');
		} catch (error) {
			console.error('Error creating badge:', error);
		}
	};

	const allFieldsFilled = !!(formState.label && formState.identifier && formState.curriculum);

	return (
		<BadgesCreateRender
			formState={formState}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleVideoChange={handleVideoChange}
			handleSubmit={handleSubmit}
			preview={preview}
			videoPreview={videoPreview}
			allFieldsFilled={allFieldsFilled}
		/>
	);
};