import React,{useEffect,useState} from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import {useMutation,useQuery} from '@apollo/client';
import {mutation} from '../../actions/mutations';
import {useParams} from 'react-router-dom';
import {query} from '../../actions/queries';
import {Questions} from '../class/Questions';
import {languages,deleteTypeMutation} from '../../tools';
import {LanguageForm} from './LanguageForm';

export const ClassForm=()=>{
	const {id}=useParams();
	const [form,setForm]=useState({questions:[],extraQuestions:[],language:{spanish:{},english:{},french:{}}});
	const [saving, setSaving] = useState(false);
	const [createAr]=useMutation(mutation(id==='new' ? 'classesCreateOne' : 'classesUpdateOne'));
	
	const {data,loading,error}=useQuery(
		query('classesAdminFindOne'),
		{
			variables:{id},
			// skip:id==='new',
			// fetchPolicy:'cache-and-network'
		}
	);
	
	useEffect(()=>{
		if(!loading && !error && data && data.ClassesAdminFindOne) {
			setForm({
				title:data.ClassesAdminFindOne.title,
				description:data.ClassesAdminFindOne.description,
				questions:data.ClassesAdminFindOne.questions,
				extraQuestions:data.ClassesAdminFindOne.extraQuestions,
				language : data.ClassesAdminFindOne.language || {spanish:{description:'',title:'',identifier:'',label:''},english:{description:'',title:'',identifier:'',label:''},french:{description:'',title:'',identifier:'',label:''}}
			});
		}
	},[data,loading,error]);
	
	const handlerForm=({target})=>{
		let {value,name}=target;
		setForm({...form,[name]:value});
	};
	
	const handlerQuestions=({values,name})=>{
		setForm({...form,[name]:values});
	};
	
	const removeQuestions=({id,name})=>{
		let questions = form[name];
		questions=questions.filter(({question})=>question!==id);
		setForm({...form,[name]:questions});
	};
	
	const pointsQuestions=({id,name,points})=>{
		let questions = form[name];
		questions=questions.map(i=>i.question===id?{...i,points}:i);
		setForm({...form,[name]:questions});
	};
	
	const send=async()=>{
		setSaving(true);
		
		let data={
			...deleteTypeMutation(form),
			questions:form.questions.map(i=>({question:i.question,points:i.points.toString()})),
			extraQuestions:form.extraQuestions.map(i=>({question:i.question,points:i.points.toString()}))
		};
		
		let variables={record:data};
		if(id!=='new')
			variables.id=id;
		
		await createAr({
			variables
		})
		.then(({_})=>{
			setSaving(false);
		})
		.catch(_=>{
			setSaving(false);
		});
	};
	
	return (
		<div className=''>
			<Input label={'title'} name={'title'} value={form.title} onChange={handlerForm} register={()=>{
			}}/>
			<Input label={'description'} name={'description'} value={form.description} onChange={handlerForm} register={()=>{
			}}/>
			
			<Questions label={'questions'} values={form.questions} name={'questions'} remove={removeQuestions}
					   changesPoints={pointsQuestions} onChange={handlerQuestions}/>
			
			<Questions label={'Extra Questions'} values={form.extraQuestions} name={'extraQuestions'} remove={removeQuestions}
					   changesPoints={pointsQuestions} onChange={handlerQuestions}/>
			
			{languages.map(i=><LanguageForm setForm={setForm} form={form} language={i}/>)}
			
			<Button onClick={send}>{saving ? 'Saving...' : 'Save'}</Button>
		</div>
	);
};
