import DeleteConfirmationModal from '../../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';
import {
	CollectiblesEditPageCard,
	CollectiblesEditPageContainer,
	LabelVideoContainer, VideoElement,
	VideoWrapper
} from './CollectiblesEdit.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {CurriculumFindMany} from '../../../hoc/CurriculumFindMany';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {query} from '../../../actions/queries';
import {uploadCollectiblesCover, uploadCollectiblesVideo} from '../../../actions/restactions';
import {PlayIcon} from '../../../icons/svg/PlayIcon';

export const CollectiblesEdit = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('CollectiblesFindMany'), {
		variables: { filter: { _id: id }, limit: 1 },
	});

	const [collectible, setCollectible] = useState(null);
	const [formState, setFormState] = useState({
		title: '',
		description: '',
		price: '',
		cover: {
			url: '',
			type: '',
		},
		artwork: {
			url: '',
			type: '',
		},
		video:{
			url:'',
			type: ''
		},
		curriculum: '',
		editions: '',
		createdAt: '',
		updatedAt: '',
	});

	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	useEffect(() => {
		if (data && data.CollectiblesFindMany.length > 0) {
			const collectibleData = data.CollectiblesFindMany[0];
			setCollectible(collectibleData);
			setFormState({
				title: collectibleData.title,
				description: collectibleData.description,
				price: collectibleData.price.toString(),
				cover: {
					url: collectibleData.cover?.url || '',
					type: collectibleData.cover?.type || '',
				},
				artwork: {
					url: collectibleData.artwork?.url || '',
					type: collectibleData.artwork?.type || '',
				},
				curriculum: collectibleData.curriculum?._id || '',
				editions: collectibleData.editions.toString(),
				video: {
					url: collectibleData.video?.url || '',
					type: collectibleData.video?.type || '',
				},
				createdAt: collectibleData.createdAt,
				updatedAt: collectibleData.updatedAt,
			});
		}
	}, [data]);

	const [updateCollectible] = useMutation(mutations.CollectiblesUpdateOne);
	const [deleteCollectible] = useMutation(mutations.CollectiblesDeleteOne);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const removeTypename = (obj) => {
		if (Array.isArray(obj)) {
			return obj.map((item) => removeTypename(item));
		} else if (typeof obj === 'object' && obj !== null) {
			const newObj = {};
			for (let key in obj) {
				if (key !== '__typename') {
					newObj[key] = removeTypename(obj[key]);
				}
			}
			return newObj;
		}
		return obj;
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const cleanedFormState = removeTypename(formState);
			await updateCollectible({
				variables: {
					_id: collectible._id,
					title: cleanedFormState.title,
					description: cleanedFormState.description,
					price: parseInt(cleanedFormState.price),
					curriculum: cleanedFormState.curriculum,
					editions: parseInt(cleanedFormState.editions),
				},
			});

			if (selectedFile) {
				await uploadCollectiblesCover(selectedFile, collectible._id);
			}

			if (videoFile) {
				await uploadCollectiblesVideo(videoFile, collectible._id);
			}

			setIsChanged(false);
			history.push('/collectibles');
		} catch (error) {
			console.error('Error updating collectible:', error);
		}
	};

	const handleDelete = async () => {
		try {
			await deleteCollectible({
				variables: { _id: collectible._id },
			});
			setIsDeleteModalOpen(false);
			history.push('/collectibles');
		} catch (error) {
			console.error('Error deleting collectible:', error);
		}
	};

	const getImageSrc = () => {
		if (preview) {
			return preview;
		} else if (formState.cover.url) {
			return formState.cover.url;
		} else {
			return null;
		}
	};

	const getVideoSrc = () => {
		if (videoPreview) {
			return videoPreview;
		} else if (formState.video.url) {
			return formState.video.url;
		} else {
			return null;
		}
	};

	if (loading) return <h2 className={'font-mona f-900 white p-3'}>Loading...</h2>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error: {error.message}</p>;
	if (!data || data.CollectiblesFindMany.length === 0) return <p className={'font-mona f-900 white p-3'}>No data available</p>;

	return (
		<CollectiblesEditPageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Collectible</h1>
			<CollectiblesEditPageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<label htmlFor='file-upload'
						   className={'mb-4 flex direction-column gap-2 justify-center align-center cursor-pointer'}>
						<div
							className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'flex justify-center align-center p-2 w-44 h-44'}>
								{getImageSrc() ? (
									<img src={getImageSrc()} alt='Preview'
										 className={'rounded-full w-44 h-44 object-cover'}/>
								) : (
									<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
								)}
							</div>
						</div>
						<span className={'font-sans text-text-light dark:text-text-dark active-nav-item-font-color'}>
                            Upload Image
						</span>
						<input
							id='file-upload'
							type='file'
							accept='image/*'
							onChange={handleFileChange}
							className={'hidden'}
						/>
					</label>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Title</label>
						<input
							type='text'
							name='title'
							value={formState.title}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
						<textarea
							name='description'
							value={formState.description}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Price</label>
						<input
							type='number'
							name='price'
							value={formState.price}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Curriculum
							(Optional)</label>
						<CurriculumFindMany>
							{curriculums => (
								<select
									name='curriculum'
									value={formState.curriculum}
									onChange={handleChange}
									className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
								>
									<option value=''>Select a Curriculum</option>
									{curriculums.map(curriculum => (
										<option key={curriculum._id} value={curriculum._id}>
											{curriculum.title}
										</option>
									))}
								</select>
							)}
						</CurriculumFindMany>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Editions</label>
						<input
							type='number'
							name='editions'
							value={formState.editions}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<LabelVideoContainer htmlFor='video-upload'>
						<VideoWrapper>
							{getVideoSrc() ? (
								<VideoElement
									src={getVideoSrc()}
									controls
									poster={getImageSrc()? getImageSrc() : ''}
								>
									<p>{formState.title}</p>
								</VideoElement>
							) : (
								<div
									className={'w-100 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
									<div className={'flex justify-center align-center p-2 w-44 h-44 overflow-hidden'}>
										<PlayIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
									</div>
								</div>
							)}
						</VideoWrapper>
						<span className={'font-sans mt-3 mb-5 text-text-light text-center dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
                            Edit Collectible Video
						</span>
						<input
							id='video-upload'
							type='file'
							accept='video/mp4'
							onChange={handleVideoChange}
							className={'hidden'}
						/>
					</LabelVideoContainer>
					<div className={'mb-4 flex direction-column justify-between gap-2'}>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Created
							At: </b>{new Date(formState.createdAt).toLocaleString()}</p>
						<p className={'font-mono text-text-light dark:text-text-dark'}><b>Updated
							At:</b> {new Date(formState.updatedAt).toLocaleString()}</p>
					</div>
					<button
						type='submit'
						disabled={!isChanged}
						className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${
							!isChanged ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						}`}
					>
						Update
					</button>
					<button
						type='button'
						onClick={() => setIsDeleteModalOpen(true)}
						className={'font-mono px-4 py-2 mt-4 font-medium text-white bg-red-600 rounded hover:bg-red-700'}
					>
						Delete
					</button>
				</form>
				<DeleteConfirmationModal
					isOpen={isDeleteModalOpen}
					setIsOpen={setIsDeleteModalOpen}
					onDelete={handleDelete}
					itemName={formState.title}
				/>
			</CollectiblesEditPageCard>
		</CollectiblesEditPageContainer>
	);
};