
import {svgPropTypes} from '../../types';


export const FilterDataIcon = ({ width, height, background, className, style }) => (
	<svg xmlns='http://www.w3.org/2000/svg'
		 width={width}
		 height={height}
		 viewBox='0 0 9 15'
		 fill='none'
		className={className}
		style={{...style}}>
		<g opacity='0.8'>
			<path d='M6.14258 5.09375L4.14258 3.09375L2.14258 5.09375' stroke='#AEB9E1' strokeWidth='0.8'
				  strokeLinecap='round' strokeLinejoin='round'/>
		</g>
		<g opacity='0.8'>
			<path d='M2.14258 9.76172L4.14258 11.7617L6.14258 9.76172' stroke='#AEB9E1' strokeWidth='0.8'
				  strokeLinecap='round' strokeLinejoin='round'/>
		</g>
	</svg>
);

FilterDataIcon.propTypes = svgPropTypes;

FilterDataIcon.defaultProps = {
	width: '9',
	height: '15',
	background: 'none',
	className: '',
	style: {}
};

export default FilterDataIcon;