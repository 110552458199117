import React from 'react';
import {svgPropTypes} from '../../types';

const UserIcon =  ({ width, height, background, className, style, isActive }) => {
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : background;

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 14 15'
			 fill='none'
			className={className}
			style={{ ...style }}
		>
			<path
				d='M0.943604 12.7389C0.943604 10.5944 2.68203 8.85602 4.8265 8.85602H9.17356C11.318 8.85602 13.0565 10.5944 13.0565 12.7389C13.0565 13.4537 12.477 14.0332 11.7622 14.0332H2.2379C1.52308 14.0332 0.943604 13.4537 0.943604 12.7389Z'
				fill={fillColor}/>
			<path
				d='M7.00236 7.86966C8.90855 7.86966 10.4538 6.32439 10.4538 4.4182C10.4538 2.51201 8.90855 0.966736 7.00236 0.966736C5.09617 0.966736 3.5509 2.51201 3.5509 4.4182C3.5509 6.32439 5.09617 7.86966 7.00236 7.86966Z'
				fill={fillColor}/>
		</svg>
	);
};

UserIcon.propTypes = svgPropTypes;

UserIcon.defaultProps = {
	width: '15',
	height: '15',
	background: '#AEB9E1',
	className: '',
	style: {},
	isActive: false
};

export default UserIcon;