import React from 'react';

export default function Input({label,placeHolder,type,register,required,pattern,errors,className,onBlur,...props}){
	return (
		<div className={`select my-2 ${className}`}>
			<label className={'font-sans text-text-light dark:text-text-dark self-start'}>
				{label}
			</label>
			<input
				className='font-mono w-100 p-2 text-text-light dark:text-cloudyBlue-default rounded-lg dark:bg-midnight-default border border-cloudyBlue-dark'
				placeholder={placeHolder}
				type={type}
				{...props}
			/>
		</div>
	);
}