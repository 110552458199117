import styled from 'styled-components';

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
`;

export const ModalCloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
`;

export const ModalButtons = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

export const ModalButton = styled.button`
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:first-child {
        background: gray;
        color: white;

        &:hover {
            background: #6c6c6c;
        }
    }

    &:last-child {
        background: red;
        color: white;

        &:hover {
            background: #ff4141;
        }
    }
`;