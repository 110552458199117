import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {useState} from 'react';
import {mutations} from '../../../../actions/mutations';
import {AddSchoolToTeacherFormRender} from './AddSchoolToTeacherForm.render';
import PropTypes from 'prop-types';

const defaultValues = {
	firstName: '',
	lastName: '',
	userName: '',
	email: '',
	classLinkId: '',
};

export const AddSchoolToTeacherFormLoad = ({ isOpenState, currentUser, refetch }) => {
	const { data: schoolsData, loading: schoolLoading } = useQuery(query('schoolFindMany'), { variables: {} });

	const [formData, setFormData] = useState({
		...defaultValues,
		firstName: currentUser?.firstName,
		lastName: currentUser?.lastName,
		userName: currentUser?.userName,
		email: currentUser?.email,
		classLinkId: currentUser?.classLinkId,
	});

	const updateForm = (name, value) => {
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const [updateUserSchool, { loading: updateUserLoading, error: updateErrorUser }] = useMutation(mutations.SetUserSchool, {
		onCompleted: () => refetch(),
	});

	const handleUpdateUser = async () => {
		try {
			await updateUserSchool({
				variables: {
					user: currentUser,
					school: formData.classLinkId,
				},
			});
			const setIsOpen = isOpenState[1];
			setIsOpen(false);
		} catch (err) {
			console.log(err);
		}
	};
	const onSubmit = async (e) => {
		e.preventDefault();
		handleUpdateUser().then();
	};

	return (
		<AddSchoolToTeacherFormRender
			currentUserId={currentUser}
			isOpenState={isOpenState}
			isLoading={updateUserLoading || schoolLoading}
			values={formData}
			errors={{ submit: updateErrorUser }}
			schoolsOptions={schoolsData?.schoolFindMany || []}
			handleOnChange={updateForm}
			onSubmit={onSubmit}
		/>
	);
};

AddSchoolToTeacherFormLoad.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	isOpenState: PropTypes.array.isRequired,
	currentUser: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		userName: PropTypes.string,
		email: PropTypes.string,
		classLinkId: PropTypes.string,
	}).isRequired,
	refetch: PropTypes.func.isRequired,
};