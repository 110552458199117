import {PencilIcon, TrashIcon} from '../../../icons';
import PropTypes from 'prop-types';

export const EditDeleteActions=({onEdit,onDelete})=>{

	return (
		<div className={'flex gap-2'}>
			<button
				type ='button'
				onClick={onEdit}
			>
				<PencilIcon className={'cursor-pointer'}/>
			</button>
			<button
				type='button'
				onClick={onDelete}
			>
				<TrashIcon className={'cursor-pointer'}/>
			</button>
		</div>  
	);
};

EditDeleteActions.prototype={
	onEdit:PropTypes.func,
	onDelete:PropTypes.func,  
};