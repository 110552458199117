import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
//This High Order Component needs the ID from the Curriculums Teacher field
export const UserForCurriculumFindOne = ({ id }) => {
	const [retry, setRetry] = useState(false);
	const { data, loading, error, refetch } = useQuery(query('usersFindOne'), {
		variables: { id: id },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (error && !retry) {
			setRetry(true);
			refetch().then(({ data }) => {
				if (data && data.UsersFindOne) {
					setRetry(false);
				}
			}).catch(() => {});
		}
	}, [error, retry, refetch]);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error.message}</p>;
	if (!data || !data.UsersFindOne) return <p>No teacher selected yet</p>;

	const user = data.UsersFindOne;
	return <span>{user.firstName} {user.lastName}</span>;
};