import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {CollectiblesCreatePageCard, CollectiblesCreatePageContainer} from './CollectiblesCreate.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {CurriculumFindMany} from '../../../hoc/CurriculumFindMany';
import {useHistory} from 'react-router-dom';
import {uploadCollectiblesCover, uploadCollectiblesVideo} from '../../../actions/restactions';
import {query} from '../../../actions/queries';
import {PlayIcon} from '../../../icons/svg/PlayIcon';
import {LabelVideoContainer, VideoElement, VideoWrapper} from '../CollectiblesEdit/CollectiblesEdit.style';

export const CollectiblesCreate = () => {
	const history = useHistory();

	const [formState, setFormState] = useState({
		title: '',
		description: '',
		price: '',
		curriculum: '',
		editions: '',
		cover: {
			url: '',
			type: '',
		},
		artwork: {
			url: '',
			type: '',
		},
		video:{
			url:'',
			type: ''
		},
	});
	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);
	const [preview, setPreview] = useState(null);

	const [createCollectible] = useMutation(mutations.CollectiblesCreateOne, {
		refetchQueries: [{ query: query('CollectiblesFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createCollectible({
				variables: {
					title: formState.title,
					description: formState.description,
					price: parseInt(formState.price),
					curriculum: formState.curriculum,
					editions: parseFloat(formState.editions)
				},
			});

			const createdCollectibleId = data.CollectiblesCreateOne._id;

			if (selectedFile) {
				await uploadCollectiblesCover(selectedFile, createdCollectibleId);
			}

			if (videoFile) {
				await uploadCollectiblesVideo(videoFile, createdCollectibleId);
			}

			setIsChanged(false);
			history.push('/collectibles');
		} catch (error) {
			console.error('Error creating collectible:', error);
		}
	};

	const getImageSrc = () => {
		if (preview) {
			return preview;
		} else if (formState.cover.url) {
			return formState.cover.url;
		} else {
			return null;
		}
	};

	const getVideoSrc = () => {
		if (videoPreview) {
			return videoPreview;
		} else if (formState.video.url) {
			return formState.video.url;
		} else {
			return null;
		}
	};

	const allFieldsFilled = formState.title && formState.description && formState.price && formState.editions;

	return (
		<CollectiblesCreatePageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Collectible</h1>
			<CollectiblesCreatePageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<label htmlFor='file-upload'
						   className={'mb-4 flex direction-column gap-2 justify-center align-center cursor-pointer'}>
						<div
							className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<div className={'flex justify-center align-center p-2 w-44 h-44'}>
								{preview ? (
									<img src={preview} alt='Preview' className={'rounded-full w-44 h-44 object-cover'}/>
								) : (
									<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
								)}
							</div>
						</div>
						<span className={'font-sans text-text-light dark:text-text-dark active-nav-item-font-color'}>
                            Upload Image
						</span>
						<input
							id='file-upload'
							type='file'
							accept='image/*'
							onChange={handleFileChange}
							className={'hidden'}
						/>
					</label>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Title</label>
						<input
							type='text'
							name='title'
							value={formState.title}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
						<textarea
							name='description'
							value={formState.description}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Price</label>
						<input
							type='number'
							name='price'
							value={formState.price}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Curriculum
							(Optional)</label>
						<CurriculumFindMany>
							{
								curriculums => (
									<select
										name='curriculum'
										value={formState.curriculum}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									>
										<option value=''>Select a Curriculum</option>
										{curriculums.map(curriculum => (
											<option key={curriculum._id} value={curriculum._id}>
												{curriculum.title}
											</option>
										))}
									</select>
								)
							}
						</CurriculumFindMany>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Editions</label>
						<input
							type='number'
							name='editions'
							value={formState.editions}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<LabelVideoContainer htmlFor='video-upload'>
						<VideoWrapper>
							{getVideoSrc() ? (
								<VideoElement
									src={getVideoSrc()}
									controls
									poster={getImageSrc()? getImageSrc() : ''}
								>
									<p>{formState.title}</p>
								</VideoElement>
							) : (
								<div
									className={'w-100 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
									<div className={'flex justify-center align-center p-2 w-44 h-44 overflow-hidden'}>
										<PlayIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
									</div>
								</div>
							)}
						</VideoWrapper>
						<span className={'font-sans mt-3 mb-5 text-text-light text-center dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
                            Edit Collectible Video
						</span>
						<input
							id='video-upload'
							type='file'
							accept='video/mp4'
							onChange={handleVideoChange}
							className={'hidden'}
						/>
					</LabelVideoContainer>
					<button
						type='submit'
						disabled={!allFieldsFilled}
						className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${
							!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						}`}
					>
						Create
					</button>
				</form>
			</CollectiblesCreatePageCard>
		</CollectiblesCreatePageContainer>
	);
};