import { useQuery } from '@apollo/client';
import { query } from '../actions/queries';
import React from 'react';
import PropTypes from 'prop-types';

export const ClassesFindMany = ({ children }) => {
	const { loading, error, data } = useQuery(query('classesAdminFindMany'), {
		variables: {}
	});

	if (loading) return <p className={'font-mona f-900 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.message}</p>;

	const classes = data?.ClassesAdminFindMany ?? [];

	return children({ classes });
};

ClassesFindMany.propTypes = {
	children: PropTypes.func.isRequired
};