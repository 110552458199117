import Input from '../../../../components/ui/Input';
import PropTypes from 'prop-types';

export const DistrictFormRender = ({edit , formState, handleChange, handleSubmit, isLoading})=> {
    
	return (
		<div className={'w-600px flex flex-col items-center w-full pb-2'}>
			<h2 className={'text-2xl'}>{edit? 'Edit District' : 'Create District'}</h2>
			<form className={'p-5 w-100'} onSubmit={handleSubmit}>
				<Input
					label={'Name'}
					type='text'
					name='name'
					value={formState.name}
					onChange={handleChange}
				/>
				<div className={' w-100 grid grid-cols-2 gap-x-4'}>
					<Input
						label='State'
						type='text'
						name='state'
						value={formState.state}
						onChange={handleChange}
					/>

					<Input
						label={'Country'}
						type='text'
						name='country'
						value={formState.country}
						onChange={handleChange}
					/>
				</div>
				<button
					disabled={isLoading}
					type='submit'
					className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${isLoading ? 'opacity-50' : ''}`}
				>
                    Save
				</button>
			</form>
		</div>
	);
};

DistrictFormRender.propTypes = {
	formState: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};