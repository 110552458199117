import {PencilIcon, TrashIcon} from '../../../../icons';
import PropTypes from 'prop-types';

export const Response =({response,setAsCorrectAnswer,isCorrectAnswer,openForm,openDelete})=>{
	return (
		<div className={'flex gap-3 py-1'}>
			<input
				type={'radio'}
				readOnly
				className={' px-0.5 cursor-pointer'}
				onClick={()=>setAsCorrectAnswer(response)}
				checked={isCorrectAnswer}
			/>

			<p className={isCorrectAnswer ? 'font-bold text-blue-400' : ''}>
				{response?.label}
			</p>
			<button type={'button'} onClick={openForm}><PencilIcon/></button>
			<button type={'button'} onClick={openDelete}> <TrashIcon/></button>

		</div>
	);
};

Response.prototype ={
	response:PropTypes.shape({
		label:PropTypes.string.isRequired,
		identifier:PropTypes.string.isRequired,
	}),
	setAsCorrectAnswer:PropTypes.func.isRequired,
	isCorrectAnswer:PropTypes.func.isRequired,
	openForm:PropTypes.func.isRequired,
	openDelete:PropTypes.func.isRequired,
};