import styled from 'styled-components';


export const UserCounterTableRowsFooter = styled.div`
    font-weight: 500;
    font-size: 0.756rem;
	color: rgba(255, 255, 255, 1);
	
	div:nth-child(2){
		margin-left: 2rem;
	}
	
	div:last-child{
		div:nth-child(2) , div:nth-child(3){
            width: 26px;
			height: 26px;
			border-radius: 4px;
            background: rgba(10, 19, 48, 1);
            border: 1px solid rgba(11, 23, 57, 1);
        }
	}
`;

export const UserCounterTitleTable = styled.span`
	font-weight: 500;
	font-size: 0.756rem;
	span:first-child{
		color: rgba(203, 60, 255, 1);
	}
	span:last-child{
        color: rgba(174, 185, 225, 1);
	}
`;

export const UserCheckBox = styled.div`
	width: 12px;
	height: 12px;
	border: 1px solid rgba(126, 137, 172, 1);
	border-radius: 2px;
	background-color: rgba(203, 60, 255, 1);
`;

export const UserTopContainerInfo = styled.div`
	width: 243px;
	height: 80px;
    border: 1px solid rgba(52, 59, 79, 1);
	border-radius: 8px;
	background-color: rgba(11, 23, 57, 1);

	div:first-child{
		div:first-child{
            width: 40px;
            height: 40px;
            border-radius: 80px;
            background-color: rgba(203, 60, 255, 0.5)
		}
	}
	
	
	span:first-child{
		font-size: 0.725rem;
        color: rgba(255, 255, 255, 1);
	}
	
	span:last-child{
		font-size: 0.725rem;
        color: rgba(174, 185, 225, 1);
    }

    &:hover {
        background: rgb(18, 29, 61)
    }
`;

export const Separator = styled.div`
	width: 100%;
	height: 1px;
	border: 1px solid rgba(52, 59, 79, 1);
`;

export const UserTableTHSpan = styled.span`
   font-size: 0.625rem;
   font-weight: 600;
   color: rgba(255, 255, 255, 1);
`;
export const UserTableSpan = styled.span`
   font-size: 0.625rem;
   color: rgba(174, 185, 225, 1);
`;

export const UserTableUserInfo = styled.div`
	
	span:first-child{
		font-size: 0.625rem;
		color: rgba(255, 255, 255, 1);
		font-weight: 500;
    }
	
	span:last-child{
		font-size: 0.625rem;
		font-weight: 500;
		color: rgba(174, 185, 225, 1);
    }
	
	div:last-child{
        align-items: start;
        justify-items: start;
	}
	
	img{
        border-radius: 50%;
        max-width: 1.75rem;
        max-height: 1.75rem;
        min-height: 1.75rem;
        min-width: 1.75rem;
        object-fit: cover;
	}
`;

export const UserTitle = styled.h1`
	font-size: 1rem;
	font-weight: 500;
    color: rgba(255, 255, 255, 1);
`;

export const UserTableMainWrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
	border-radius: 0.75rem;
    border: 1px solid transparent;
	box-decoration-break: unset;
	th, tr, th , tbody, td 	{
		border: transparent;
	}
`;

export const StyledTableRow = styled.tr`
    width: 100%;
    max-width: 1600px;
    height: 100%;
    border-radius: 0.0375rem;
    background-color: ${(props) =>
		props.index % 2 === 0 ? 'rgba(10, 19, 48, 1)' : 'rgba(11, 23, 57, 1)'};

    &:hover {
        background: rgb(18, 29, 61)
    }
`;

export const UserPageWrapperElements = styled.div`
	border-radius: 19px;
    border: 1px solid rgba(52, 59, 79, 1);
	overflow: hidden;
`;

export const UserPageContainer = styled.div`
	width: 100%;
	height: 100%;
`;