import React, {useState} from 'react';

export const ImageInput = ({defaultImage,className,name, label, onChange, imageSize,...props})=>{
	const [image,setImage] = useState(defaultImage);

	const handleOnchange =(e)=>{
		const files = e.target.files;
		if (!files.length) return;

		const file = files[0];
		console.log({files,file});
		setImage(URL.createObjectURL(file));
		onChange &&onChange(e);
	};
    
	return (
		<div className={`flex flex-col ${className}`}>
			<input
				className={'hidden'}
				id={`image-id-${name}`}
				name={name}
				type = 'file'
				onChange={handleOnchange}
				{...props}
			/>
			<label className={'font-sans mb-1 text-text-light dark:text-text-dark self-start '}>
				{label}
			</label>
			<label 
				className={`${imageSize? imageSize :'w-200px h-200px'} ${!image && 'border border-text-dark'} bg-midnight-default aspect-square cursor-pointer rounded `}
				htmlFor={`image-id-${name}`}
			>
				{image?
					<img
						className={'w-fll h-full object-cover rounded br border-text-dark '}
						alt={'image'}
						src={image}
					/>
					:<div/>
				}
			</label>
		</div>
	);
};