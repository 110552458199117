import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import {query} from '../../../actions/queries';
import {uploadClassCover} from '../../../actions/restactions';
import {LessonsCreateRender} from './LessonsCreate.render';

export const LessonsCreate = ( ) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedLifeSkills, setSelectedLifeSkills] = useState([]);
	const [selectedSkillSets, setSelectedSkillSets] = useState([]);
	const [selectedThemes, setSelectedThemes] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setTimeout(() => setIsMounted(true), 0);
	}, []);

	const [formState, setFormState] = useState({
		title: '',
		description: '',
		curriculum: '',
		order: 0,
		lifeSkill: [],
		skillSet: [],
		theme: [],
		cover: { url: '', type: '' },
		episodeTime: 0,
		quizTime: 0,
		journalTime: 0,
		classActivityTime: 0,
		tapLibraryTime: 0,
		extendTime: 0,
		lessonLength: '',
		overview: '',
		extraActivities: '',
		bigIdea: '',
		learningGoal: '',
		discussion: [{ title: '', description: '' }],
		extend: [{ title: '', description: '' }],
		reflection: [{ title: '', description: '' }],
		activity: [{ title: '', description: '' }],
	});

	const [createClass] = useMutation(mutations.classesCreateOne, {
		refetchQueries: [{ query: query('classesFindMany') }],
		awaitRefetchQueries: true
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		const parsedValue = parseFloat(value);
		setFormState((prev) => ({
			...prev,
			[name]: name === 'order' || name.endsWith('Time') ? Math.max(0, parsedValue) : value
		}));
	};


	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleArrayChange = (arrayName, index, field, value) => {
		setFormState((prev) => {
			const updatedArray = [...prev[arrayName]];
			const finalValue = value?.target?.value || value;
			updatedArray[index] = {
				...updatedArray[index],
				[field]: typeof finalValue === 'string' ? finalValue : ''
			};
			return {
				...prev,
				[arrayName]: updatedArray
			};
		});
	};

	const handleAddArrayItem = (arrayName) => {
		setFormState((prev) => ({
			...prev,
			[arrayName]: [...prev[arrayName], { title: '', description: '' }]
		}));
	};

	const handleRemoveArrayItem = (arrayName, index) => {
		setFormState((prev) => {
			const updatedArray = prev[arrayName].filter((_, i) => i !== index);
			return {
				...prev,
				[arrayName]: updatedArray.length > 0 ? updatedArray : [{ title: '', description: '' }]
			};
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const { data } = await createClass({
				variables: {
					record: {
						...formState,
						learningGoal: formState.learningGoal,
						lifeSkill: selectedLifeSkills.map(skill => skill._id),
						skillSet: selectedSkillSets.map(skill => skill._id),
						theme: selectedThemes.map(theme => theme._id)
					},
				},
			});

			const createdClassId = data.ClassesCreateOne.recordId;

			if (selectedFile && createdClassId != null) {
				await uploadClassCover(selectedFile, createdClassId);
			}

			history.push('/lessons');
		} catch (error) {
			console.error('Error creating class:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const allFieldsFilled = !!(formState.title && formState.description && formState.curriculum);

	return <LessonsCreateRender
		formState={formState}
		handleChange={handleChange}
		handleFileChange={handleFileChange}
		handleArrayChange={handleArrayChange}
		handleAddArrayItem={handleAddArrayItem}
		handleRemoveArrayItem={handleRemoveArrayItem}
		handleSubmit={handleSubmit}
		isLoading={isLoading}
		selectedLifeSkills={selectedLifeSkills}
		selectedSkillSets={selectedSkillSets}
		selectedThemes={selectedThemes}
		setSelectedLifeSkills={setSelectedLifeSkills}
		setSelectedSkillSets={setSelectedSkillSets}
		setSelectedThemes={setSelectedThemes}
		preview={preview}
		allFieldsFilled={allFieldsFilled}
		isMounted={isMounted}
	/>;
};