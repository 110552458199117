import {CKEditorInput, TagsSelector} from '../../../components';
import {LessonsCreatePageCard, LessonsCreatePageContainer} from './LessonsCreate.style';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {LifeSkillFindMany, SkillSetFindMany, ThemesFindMany} from '../../../hoc';
import {CurriculumFindMany} from '../../../hoc/CurriculumFindMany';
import {LessonsCreateTypes} from './Lessons.types';

export const LessonsCreateRender = ({
	formState,
	handleChange,
	handleFileChange,
	handleArrayChange,
	handleAddArrayItem,
	handleRemoveArrayItem,
	handleSubmit,
	isLoading,
	selectedLifeSkills,
	selectedSkillSets,
	selectedThemes,
	setSelectedLifeSkills,
	setSelectedSkillSets,
	setSelectedThemes,
	preview,
	allFieldsFilled,
	isMounted
}) => {
	return (
		<LessonsCreatePageContainer className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Lesson</h1>
			<LessonsCreatePageCard className={'flex gap-2 justify-center align-center'}>
				<form className={'p-5 w-100 flex direction-row justify-start align-center gap-7'} onSubmit={handleSubmit}>
					<div className={'flex self-start'}>
						<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
							<label htmlFor='file-upload' className={'w-45 h-45 cursor-pointer flex align-center justify-center direction-column'}>
								<div className={'flex justify-center align-center p-2 w-44 h-44'} style={{borderRadius: '50%', padding: '0.2rem', backgroundColor: 'rgba(52, 59, 79, 1)'}}>
									{preview ? (
										<img src={preview} alt='Preview'
											 className={'rounded-full w-44 h-44 object-cover'}/>
									) : (
										<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
									)}
								</div>
								<span
									className={'font-sans mt-3 ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
									{preview? 'Edit image' : 'Upload image'}
								</span>
								<input
									id='file-upload'
									type='file'
									accept='image/*'
									onChange={handleFileChange}
									className={'hidden'}
								/>
							</label>
						</div>
					</div>
					<div className={'w-100'}>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Title</label>
							<input
								type='text'
								name='title'
								value={formState.title}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
							<textarea
								name='description'
								value={formState.description}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Curriculum</label>
							<CurriculumFindMany>
								{curriculum => (
									<select
										name='curriculum'
										value={formState.curriculum}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									>
										<option value=''>Select a Curriculum</option>
										{curriculum.map(curriculum => (
											<option key={curriculum._id} value={curriculum._id}>
												{curriculum.title}
											</option>
										))}
									</select>
								)}
							</CurriculumFindMany>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Order</label>
							<input
								type='number'
								name='order'
								value={formState.order}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Episode
								Time</label>
							<input
								type='number'
								name='episodeTime'
								value={formState.episodeTime}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Quiz
								Time</label>
							<input
								type='number'
								name='quizTime'
								value={formState.quizTime}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Journal
								Time</label>
							<input
								type='number'
								name='journalTime'
								value={formState.journalTime}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Class
								Activity Time</label>
							<input
								type='number'
								name='classActivityTime'
								value={formState.classActivityTime}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Tap
								Library Time</label>
							<input
								type='number'
								name='tapLibraryTime'
								value={formState.tapLibraryTime}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Extend
								Time</label>
							<input
								type='number'
								name='extendTime'
								value={formState.extendTime}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Lesson
								Length</label>
							<input
								type='text'
								name='lessonLength'
								value={formState.lessonLength}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Overview</label>
							<textarea
								name='overview'
								value={formState.overview}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Extra
								Activities</label>
							<textarea
								name='extraActivities'
								value={formState.extraActivities}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Big
								Idea</label>
							<textarea
								name='bigIdea'
								value={formState.bigIdea}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Learning
								Goal</label>
							<textarea
								name='learningGoal'
								value={formState.learningGoal}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						{['discussion', 'extend', 'reflection', 'activity'].map((arrayName) => (
							<div key={arrayName} className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
								<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
									{arrayName.charAt(0).toUpperCase() + arrayName.slice(1)}
								</label>
								{formState[arrayName].map((item, index) => (
									<div key={index} className={'mb-4'}>
										<input
											type='text'
											placeholder='Title'
											value={item.title}
											onChange={(e) => handleArrayChange(arrayName, index, 'title', e.target.value)}
											className={'font-mono mb-3 w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
										/>
										{arrayName === 'reflection' && isMounted ? (
											<CKEditorInput
												label={arrayName.charAt(0).toUpperCase() + arrayName.slice(1) + ' Description'}
												data={typeof item.description === 'string' ? item.description : ''}
												onChange={(data) => handleArrayChange(arrayName, index, 'description', data)}
												name={`reflection-${index}`}
											/>
										) : (
											<textarea
												placeholder='Description'
												value={item.description}
												onChange={(e) => handleArrayChange(arrayName, index, 'description', e.target.value)}
												className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
											/>
										)}
										<div className={'flex gap-2'}>
											<button
												type='button'
												onClick={() => handleRemoveArrayItem(arrayName, index)}
												className={'font-mono bg-red-500 text-white rounded-full px-2'}
												style={{width: '1.6rem', height: '1.6rem'}}
											>
												X
											</button>
											{index === formState[arrayName].length - 1 && (
												<button
													type='button'
													onClick={() => handleAddArrayItem(arrayName)}
													className={'font-mono px-4 py-2 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}
												>
													Add {arrayName.charAt(0).toUpperCase() + arrayName.slice(1)}
												</button>
											)}
										</div>
									</div>
								))}
							</div>
						))}
						<TagsSelector
							selectedTags={selectedLifeSkills}
							setSelectedTags={setSelectedLifeSkills}
							HOC={LifeSkillFindMany}
							label='Life Skills'
						/>
						<TagsSelector
							selectedTags={selectedSkillSets}
							setSelectedTags={setSelectedSkillSets}
							HOC={SkillSetFindMany}
							label='Skill Sets'
						/>
						<TagsSelector
							selectedTags={selectedThemes}
							setSelectedTags={setSelectedThemes}
							HOC={ThemesFindMany}
							label='Themes'
						/>
						<div className={'flex align-middle justify-center center'}>
							<button
								onClick={handleSubmit}
								className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
									!allFieldsFilled && !isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
								} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
								disabled={!allFieldsFilled}
							>
								{isLoading ? 'Creating Lessons...' : 'Create'}
							</button>
						</div>
					</div>
				</form>
			</LessonsCreatePageCard>
		</LessonsCreatePageContainer>
	);
};

LessonsCreateRender.propTypes = LessonsCreateTypes;