import {StickerRender} from './StickerRender';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';


export const StickerLoad = () =>{
	const { loading, error, data, refetch } = useQuery(query('stickerFindMany'),{
		variables: {},
		nextFetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;
	if(!data || !data.StickerFindMany) return <p className={'font-mona f-900 white p-3'}>No Data</p>;

	return <StickerRender Stickers={data ? data.StickerFindMany : []}/>;
};