import {CaptionsInput, ImageInput, VideoInput} from '../../../../components';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {
	UploadSparkLibraryClosedCaptions,
	uploadSparkLibraryVideo,
	uploadSparkLibraryVideoThumbnail
} from '../../actions/sparkLibraries.rest';
import Input from '../../../../components/ui/Input';
import Button from '../../../../components/ui/Button';


export const VideoForm = ({sparkL, refetch})=>{
	const defaultFiles = {
		video:sparkL.video,
		video_thumbnail:sparkL?.video.thumbnail,
		captions: sparkL?.video.captions || []
	};

	const [ formState, setFormState ] = useState({
		videoTitle:'', thumbnailTitle:'', subtitleLanguage: 'en'
	});

	const handleOnChange = ({target})=>{
		setFormState(prev=>({
			...prev,[target.name]:target.value
		}));
	};


	const [videoState,setVideoState] = useState({ file:null, error:'', loading:false });
	const [thumbnailState,setThumbnailState] = useState({ file:null, error:'', loading:false });
	const [subtitleState, setSubtitleState] = useState({
		file: null,
		error: '',
		loading: false,
	});

	const updateVideo = (video,id, title)=>{
		setVideoState(prev=> ({ ...prev,loading:true }));
		uploadSparkLibraryVideo(video,id, title)
		.then(refetch)
		.catch((e)=>setVideoState(prev=> ({ ...prev,error: e })))
		.finally(()=>setVideoState( prev => ({...prev,loading:false }) ));
	};

	const updateThumbnail = (thumbnail,id,title)=>{
		setThumbnailState(prev=> ({ ...prev,loading:true }));
		uploadSparkLibraryVideoThumbnail(thumbnail,id,title)
		.then(refetch)
		.catch((e)=>setThumbnailState(prev=> ({ ...prev,error: e })))
		.finally(()=>setThumbnailState( prev => ({...prev,loading:false }) ));
	};

	const updateClosedCaptions = (file, id, available, language) => {
		setSubtitleState(prev => ({ ...prev, loading: true }));
		UploadSparkLibraryClosedCaptions(file, id, available, language)
		.then(refetch)
		.catch(e => setSubtitleState(prev => ({ ...prev, error: e })))
		.finally(() => setSubtitleState(prev => ({
			...prev,
			loading: false,
			url: URL.createObjectURL(file)
		})));
	};

	const handleOnChangeFile = ({target})=>{
		const file = target.files[0];
		if (target.name==='video'){
			setVideoState(prevState => ({...prevState,file}));
		}
		if (target.name==='thumbnail'){
			setThumbnailState(prevState => ({...prevState,file}));
		}
		if (target.name === 'subtitles') {
			setSubtitleState((prevState) => ({ ...prevState, file }));
		}
	};

	const onSubmit = (e)=>{
		e.preventDefault();
		if (videoState.file && formState.videoTitle){
			updateVideo(videoState.file,sparkL._id,formState.videoTitle);
		}

		if (thumbnailState.file && formState.thumbnailTitle) {
			updateThumbnail(thumbnailState.file,sparkL._id,formState.thumbnailTitle);
		}

		if (subtitleState.file) {
			updateClosedCaptions(
				subtitleState.file,
				sparkL._id,
				true,
				formState.subtitleLanguage
			);
		}
	};


	const isLoading = videoState.loading || thumbnailState.loading || subtitleState.loading;

	return(
		<div className={'flex p-3 flex-col justify-center items-center'}>
			<form onSubmit={onSubmit} className={'w-[28rem]  flex flex-col items-center justify-center gap-4'}>
				<h1 className={'center h1 block'}>Video </h1>
				<VideoInput
					label={'Video '}
					name={'video'}
					disable={videoState.loading}
					accept={'video/mp4'}
					className={'w-full'}
					onChange={handleOnChangeFile}
					defaultVideo={defaultFiles?.video?.url}
					subtitles={
						subtitleState.url
							? [{url: subtitleState.url, label: 'English', language: formState.subtitleLanguage}]
							: defaultFiles.captions.map(caption => ({
								url: caption.file.url,
								label: caption.language,
								language: caption.language
							}))
					}
				/>
				<Input
					label='video Title'
					className={'w-full'}
					name='videoTitle'
					onChange={handleOnChange}
					defaultValue={formState?.videoTitle}
				/>

				<div className={'flex gap-6 w-full'}>
					<ImageInput
						label={'video thumbnail '}
						name={'thumbnail'}
						videoSize={'w-[10rem]'}
						disable={thumbnailState.loading}
						accept={'image/jpeg'}
						imageSize={'w-[8rem]'}
						className={'justify-center'}
						onChange={handleOnChangeFile}
						defaultImage={defaultFiles?.video_thumbnail?.url}
					/>
					<div className={''}>
						<Input
							label='thumbnail Title'
							className={'w-full'}
							name='thumbnailTitle'
							onChange={handleOnChange}
							defaultValue={formState?.thumbnailTitle}
						/>
					</div>
				</div>

				<CaptionsInput
					captions={defaultFiles.captions}
					onChangeFile={handleOnChangeFile}
					subtitleLanguage={formState.subtitleLanguage}
				/>

				<Button
					type={'submit'}
					disabled={isLoading}
					className={`w-full  max-w-[30rem] ${isLoading ? 'bg-purple-400 cursor-wait' : 'bg-brightLilac-default'}`}
				>{isLoading ? 'Loading...' : 'Edit'}</Button>
			</form>
		</div>
	);
};

VideoForm.propTypes = {
	sparkL: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		video: PropTypes.shape({
			url: PropTypes.string,
			thumbnail: PropTypes.shape({
				url: PropTypes.string,
			}),
			captions: PropTypes.arrayOf(
				PropTypes.shape({
					file: PropTypes.shape({
						url: PropTypes.string,
					}),
					language: PropTypes.string,
					available: PropTypes.bool,
				})
			),
		}),
	}).isRequired,
};