import React, {useState} from 'react';
import Button from './Button';

export const VideoInput = ({ defaultVideo, className, name, label, onChange, videoSize, videoRatio, subtitles, ...props }) => {
	const [video, setVideo] = useState(defaultVideo || '');

	const handleOnchange = (e) => {
		const files = e.target.files;
		if (!files.length) return;

		const file = files[0];
		setVideo(URL.createObjectURL(file));
		onChange && onChange(e);
	};

	return (
		<div className={`flex flex-col min-w-[10rem] ${className}`}>
			<input
				className={'hidden'}
				id={`video-id-${name}`}
				name={name}
				type='file'
				onChange={handleOnchange}
				{...props}
			/>
			<label className={`w-full ${video ? 'flex' : ''} justify-between font-sans mb-1 text-text-light dark:text-text-dark self-start`}>
				{label}
				<label
					className={`${video ? 'bg-brightLilac-default' : ''} px-1 rounded text-white cursor-pointer`}
					htmlFor={`video-id-${name}`}
				>
					{video ? 'Change' : (
						<div
							className={`bg-midnight-default aspect-square cursor-pointer rounded border border-text-dark ${videoRatio ? videoRatio : 'aspect-video'}`}
						/>
					)}
				</label>
			</label>

			{video && (
				<video
					controls
					key={video}
					className={`w-full object-cover aspect-square cursor-pointer rounded border border-text-dark ${videoRatio ? videoRatio : 'aspect-video'}`}
				>
					<source src={video} />
					{subtitles && subtitles.map((subtitle, index) => (
						<track
							key={index}
							label={subtitle.label || 'Subtitles'}
							kind='subtitles'
							srcLang={subtitle.language || 'en'}
							src={subtitle.url}
							default={index === 0}
						/>
					))}
				</video>
			)}
		</div>
	);
};