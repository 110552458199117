import PropTypes from 'prop-types';

export const  UserPropTypes = {
	Users: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		username: PropTypes.string,
		email: PropTypes.string.isRequired,
		bio: PropTypes.string,
		type: PropTypes.string.isRequired,
		profilePicture: PropTypes.shape({
			type: PropTypes.string,
			url: PropTypes.string,
			title: PropTypes.string
		})
	})).isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number,
	setLimit: PropTypes.func.isRequired,
	onSearch: PropTypes.func.isRequired,
	onNextPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	allUsersCount: PropTypes.number.isRequired,
	studentsCount: PropTypes.number.isRequired,
	teachersCount: PropTypes.number.isRequired
};


export const studentTypeId = 'cae9c4f0-b804-11eb-87d9-ab38d9aa58aa';
export const teacherTypeId = 'e4475192-3c69-11ee-be56-0242ac120002';