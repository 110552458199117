import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {coursesQueries} from './actions/Courses.queries';
import {UserForCurriculumFindOne} from '../../hoc';
import {Loader, Search, SelectDropDown} from '../../components';
import {
	CoursesCheckBox, CoursesTableMainWrapper, CoursesTopContainerInfo, Separator, StyledTableRow
} from './Courses.style';
import {ArrowIndicatorIcon, CheckboxIcon, DotsThreeIcon,
	FilterDataIcon, UserIcon, BillingIcon} from '../../icons';

export const Courses = ()=>{
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const sort = searchParams.get('sort') || '';

	const request = () => {
		let aux;
		aux = {
			variables: {
				sort: sort!== '' ? sort : '_ID_DESC'
			},
			nextFetchPolicy: 'network-only'
		};
		return useQuery(coursesQueries('curriculumsFindMany'), aux);
	};

	const { loading, error, data } = request();

	if (loading) return <div className={'font-mona f-600 text-white p-3'}><Loader/></div>;
	if (error) return <p className={'font-mona f-900 text-white p-3'}>Error: {error.name}</p>;

	return (
		<div className={'w-100 h-100 p-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<h1 className={'font-sans f-1rem text-white f-600'}>Curriculums</h1>
				<Search onSearch={() => {
				}} style={{marginLeft: '3rem', maxWidth: '350px'}}/>
			</div>
			<div className={'flex justify-between items-start gap-3 mb-3'}>
				<CoursesTopContainerInfo
					className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}>
					<div className={'flex justify-start'}>
						<div className={'flex justify-center items-center mr-2'}>
							<BillingIcon background={'rgba(253, 181, 42, 1)'}/>
						</div>
						<div className={'grid'}>
							<span className={'font-sans'}>Total Curriculums</span>
							<span className={'font-sans'}>
								{data.CurriculumsFindMany.length > 0 ? data.CurriculumsFindMany.length : '0'}
							</span>
						</div>
					</div>
					<div>
						<DotsThreeIcon className={'ml-auto'}/>
					</div>
				</CoursesTopContainerInfo>
				<div className={'flex align-middle justify-center center'}>
					<button
						style={{height: '80px', maxWidth: '243px'}}
						onClick={() => history.push('/courses/create')}
						className={`flex self-center align-center justify-center button t-white button-large w-100 mb-3 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default`}
					>
						Create Course
					</button>
					{error && <p className={'text-red-500'}> {error} </p>}
				</div>
			</div>
			<div
				className={'overflow-hidden rounded-lg'}
				style={{border: '1px solid rgba(52, 59, 79, 1)'}}
			>
				<div className={'flex justify-between items-center mb-6 mt-6 p-2.5 pr-4'}>
					<h1 className={'font-sans f-1rem text-white f-600'}>Curriculums</h1>
					<div className={'font-mono f-1rem f-500'}>
						<span style={{color: 'rgba(203, 60, 255, 1)'}}>{data.CurriculumsFindMany.length}</span>
						<span style={{color: 'rgba(174, 185, 225, 1)'}}> of {data.CurriculumsFindMany.length}</span>
					</div>
				</div>
				<Separator/>
				<CoursesTableMainWrapper className={'w-full mt rounded-lg'}
					style={{border: '1px solid transparent', boxDecorationBreak: 'unset'}}
				>
					<thead style={{borderBottom:'1px solid rgb(52, 59, 79)'}}>
						<tr>
							<th className={'w-12 main-color-background p-4 text-sm tracking-wide font-semibold text-left'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<CoursesCheckBox className={'flex justify-center items-center'}>
										<CheckboxIcon/>
									</CoursesCheckBox>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2 text-sm tracking-wide font-semibold text-left'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'}/>
										<span className={'font-mona text-white'}
										  style={{fontSize: '0.625rem'}}>Title</span>
									</div>
									<div>
										<FilterDataIcon/>
									</div>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2 text-sm tracking-wide font-semibold text-left'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'}/>
										<span className={'font-mona text-white'}
										  style={{fontSize: '0.625rem'}}>Teacher</span>
									</div>
									<div>
										<FilterDataIcon/>
									</div>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2 text-sm tracking-wide font-semibold text-left'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'}/>
										<span className={'font-mona text-white'}
										  style={{fontSize: '0.625rem'}}>Active</span>
									</div>
									<div>
										<FilterDataIcon/>
									</div>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2 text-sm tracking-wide font-semibold text-left'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'}/>
										<span className={'font-mona text-white'}
										  style={{fontSize: '0.625rem'}}>Date</span>
									</div>
									<div>
										<FilterDataIcon/>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody className={'font-sans divide-y'}
						   style={{fontSize: '0.625rem', color: 'rgba(174, 185, 225, 1)'}}>
						{
							data.CurriculumsFindMany.map((curriculum, index) => (
								<StyledTableRow className={'cursor-pointer'}
									key={curriculum._id} index={index}
									onClick={() => history.push(`/courses/${curriculum._id}`)}>
									<td className={'p-4 text-sm text-left whitespace-nowrap'}>
										<CoursesCheckBox className={'flex justify-center items-center'}>
											<CheckboxIcon/>
										</CoursesCheckBox>
									</td>
									<td className={'p-2 text-start whitespace-nowrap'}><div>{curriculum.title}</div></td>
									<td className={'p-2 text-start whitespace-nowrap'}>
										{curriculum.teacher ? (
											<UserForCurriculumFindOne id={curriculum.teacher}/>
										) : (
											<span>No teacher selected yet</span>
										)}
									</td>
									<td className={'p-2 text-start whitespace-nowrap'}>
										{
											curriculum.active ?
												(<div className={'flex justify-start align-center gap-2'}>
													<div className={'radio'}
													 style={{
														 width: '0.625rem',
														 height: '0.625rem',
														 background: '#00FF2AFF'
													 }}></div>
													<span>Yes</span>
												</div>)
												:
												(<div className={'flex justify-start align-center gap-2'}>
													<div className={'radio'}
													 style={{
														 width: '0.625rem',
														 height: '0.625rem',
														 background: '#FF0000FF'
													 }}></div>
													<span>No</span>
												</div>)
										}
									</td>
									<td className={'p-2 text-start whitespace-nowrap'}>
										<div className={'flex direction-column gap-2'}>
											<span>{new Date(curriculum.createdAt).toLocaleDateString()}</span>
										</div>
									</td>
								</StyledTableRow>
							))
						}
					</tbody>
				</CoursesTableMainWrapper>
			</div>
			<div style={{fontSize:'0.756rem'}}
				className={'flex justify-between items-center f-500 mb-6 mt-6 p-2.5 pr-4 pl-4'}>
				<div className={'font-mono'}>
					<span className={'text-white'}>{data.CurriculumsFindMany.length} of {data.CurriculumsFindMany.length}</span>
				</div>
				<div className={'flex items-center gap-1'}>
					<SelectDropDown minValue={10} maxValue={1000} onChange={()=>{}}/>
					<div className={'flex justify-center items-center cursor-pointer ml-3'}>
						<ArrowIndicatorIcon className={'transform rotate-180'}/>
					</div>
					<div className={'flex justify-center items-center cursor-pointer'}>
						<ArrowIndicatorIcon/>
					</div>
				</div>
			</div>
		</div>
	);
};