import {StyledTableRow} from '../../../Users/Users.style';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {PencilIcon} from '../../../../icons';
import {DistrictForm} from '../../../District/forms';
import Modal from '../../../../components/ui/Modal/Modal';

export const DistrictArrow = ({district,index})=>{
	const {name, state, country, createdAt, updatedAt, _id } = district;

	const [showEditForm,setShowEditForm] = useState(false);
	const handleEdit = ()=>setShowEditForm(true);
    
	return(
		<>
			<Modal isOpen={showEditForm} setIsOpen={setShowEditForm}>
				<DistrictForm 
					currentDistrict={district}  
					onSuccess={()=>setShowEditForm(false)}
			    />
			</Modal>
	
			<StyledTableRow index={index}>
				<td className={'p-2'}>
					<div className={'flex justify-start items-center p-1 gap-2'}>
						<span className={'font-mono text-text-light dark:text-text-dark text-xs'}>{name}</span>
					</div>
				</td>
				<td className={'justify-start items-center text-start p-2'}>
					<p className={'font-mono truncate overflow-ellipsis'}
						style={{ color: 'rgba(174, 185, 225, 1)' }}>
						{state ? (
							<span className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{state}</span>
						) : (
							<span className={'font-mono text-text-light dark:text-text-dark  text-sm'}>Not Available</span>
						)}
					</p>
				</td>
				<td className={'justify-start items-center text-start p-2'}>
					<p className={'font-mono truncate overflow-ellipsis'}
						style={{ color: 'rgba(174, 185, 225, 1)' }}>
						{country ? (
							<span className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{country}</span>
						) : (
							<span>Not Available</span>
						)}
					</p>
				</td>
				<td className={'justify-start items-center text-start p-2'}>
					<p className={'font-mono truncate overflow-ellipsis'}
						style={{ color: 'rgba(174, 185, 225, 1)' }}>
						{createdAt ? (
							<span className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{new Date(createdAt).toLocaleString('en-GB')}</span>
						) : (
							<span>Not Available</span>
						)}
					</p>
				</td>
				<td className={'justify-start items-center text-start p-2'}>
					<p className={'font-mono truncate overflow-ellipsis'}
						style={{ color: 'rgba(174, 185, 225, 1)' }}>
						{updatedAt ? (
							<span className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{new Date(updatedAt).toLocaleString('en-GB')}</span>
						) : (
							<span>Not Available</span>
						)}
					</p>
				</td>
				<td>
					<button
						type ='button'
						onClick={handleEdit}
					>
						<PencilIcon className={'cursor-pointer'}/>
					</button>
				</td>
			</StyledTableRow>
		</>
	);
};

DistrictArrow.prototype={
	district:PropTypes.shape({
		name:PropTypes.string.isRequired,
		state:PropTypes.string.isRequired,
		country:PropTypes.string.isRequired,
		createdAt:PropTypes.string.isRequired,
		updatedAt:PropTypes.string.isRequired,
	})
};