import {StickerEditRender} from './StickerEditRender';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {useEffect, useState} from 'react';
import {mutations} from '../../../actions/mutations';
import {uploadStickerCover, uploadStickerVideo} from '../../../actions/restactions';

export const StickerEditLoad = () => {
	const { id } = useParams();
	const history = useHistory();

	const { loading, error, data } = useQuery(query('stickerFindMany'), {
		variables: { filter: { _id: id } },
	});

	const [formState, setFormState] = useState({
		label: '',
		identifier: '',
		deedlyVault: '',
		cover: { url: '', type: '' },
		video: { url: '', type: '' },
		createdAt: '',
		updatedAt: '',
		deletedAt: null
	});
	const [isChanged, setIsChanged] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [videoPreview, setVideoPreview] = useState(null);

	const [updateSticker] = useMutation(mutations.StickerUpdateOne, {
		refetchQueries: [{ query: query('stickerFindMany') }],
		awaitRefetchQueries: true,
	});

	useEffect(() => {
		if (data) {
			const sticker = data.StickerFindMany[0];
			setFormState({
				label: sticker.label,
				identifier: sticker.identifier,
				deedlyVault: sticker.deedlyVault,
				cover: sticker.cover,
				video: sticker.video,
				createdAt: sticker.createdAt,
				updatedAt: sticker.updatedAt,
				deletedAt: sticker.deletedAt
			});
			setPreview(sticker.cover?.url || null);
			setVideoPreview(sticker.video?.url || null);
		}
	}, [data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleVideoChange = (e) => {
		const file = e.target.files[0];
		setVideoFile(file);
		setVideoPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await updateSticker({
				variables: {
					_id: id,
					record: {
						label: formState.label,
						identifier: formState.identifier,
						deedlyVault: formState.deedlyVault,
					},
				},
			});

			if (selectedFile) {
				await uploadStickerCover(selectedFile, id);
			}

			if (videoFile) {
				await uploadStickerVideo(videoFile, id);
			}

			setIsChanged(false);
			history.push('/stickers');
		} catch (error) {
			console.error('Error updating sticker:', error);
		}
	};

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	const allFieldsFilled = !!(formState.label && formState.deedlyVault);

	return (
		<StickerEditRender
			formState={formState}
			handleChange={handleChange}
			handleFileChange={handleFileChange}
			handleVideoChange={handleVideoChange}
			handleSubmit={handleSubmit}
			preview={preview}
			videoPreview={videoPreview}
			allFieldsFilled={allFieldsFilled}
		/>
	);
};