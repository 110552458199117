import gql from 'graphql-tag';

const queries ={
	sparkLibraryFindMany:gql`
        query sparkLibraryFindMany($filter:FilterFindManysparklibraryInput,$skip:Int,$limit:Int,$sort:SortFindManysparklibraryInput){
            sparkLibraryFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                title
                description
                lesson
                curriculum
                type
                articleTitle
                articleBody
                takeAwayDescription
                takeAwayLabel
                videoQuestions {
                    question
                    points
                }
                articleQuestion {
                    question
                    points
                }
                cover {
                    url
                    type
                }
                video {
                    url
                    thumbnail {
                        type
                        url
                    }
                    type
                    captions {
                        language
                        file {
                            url
                        }
                        available
                    }
                }
                article {
                    url
                    type
                }
                journals {
                    question
                    points
                }
                mindfulMoment {
                    question
                    points
                }
                funFact {
                    question
                    points
                }
                slug
                deleted
                order
                times
                createdAt
                updatedAt
            }
        }
    `
};

export const sparkLibrariesQueries=(value)=>{
	return queries[value];
};
