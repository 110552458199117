import React from 'react';
import Input from '../ui/Input';

export const LanguageForm=({form,language,setForm})=>{
	const handlerForm=({target})=>{
		let {value,name}=target;
		setForm({...form,language:{...form.language,[language]:{...form.language[language],[name]:value}}});
	};
	return (
		<div className='mb-3 mt-3'>
			<label className={'font-mono f-900 text-text-light dark:text-text-dark mb-4'}>Language {language}</label>
			<Input label={'title'} name={'title'} value={form.language[language].title} onChange={handlerForm} register={()=>{
			}}/>
			<Input label={'description'} name={'description'} value={form.language[language].description} onChange={handlerForm} register={()=>{
			}}/>
			<Input label={'label'} name={'label'} value={form.language[language].label} onChange={handlerForm} register={()=>{
			}}/>
			<Input label={'identifier'} name={'identifier'} value={form.language[language].identifier} onChange={handlerForm} register={()=>{
			}}/>
		</div>
	);
};