import {query} from '../actions/queries';
import {useQuery} from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';

export const OrganizationsFindMany = ({ children }) => {
	const ORGANIZATION_FIND_MANY = query('organizationsFindMany');

	const { data, loading, error } = useQuery(ORGANIZATION_FIND_MANY, {
		variables: { }
	});
	if (loading) return <p className={'font-mona f-600 text-white p-3'}>Loading...</p>;
	if (error) return <p className={'font-mona f-600 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.OrganizationsFindMany) {
		return (
			<div
				className={'font-mona f-600 button t-white text-xs br-quarter hover:grey900 br-quarter hover:grey900 f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default'}
				onClick={() => onAddOrganization(_id)}
			>
				+ School
			</div>
		);
	}
	return children(data.OrganizationsFindMany);
};

OrganizationsFindMany.propTypes = {
	children: PropTypes.func.isRequired,
};