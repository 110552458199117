import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {
	AvatarPageContainer,
	AvatarTitle,
	AvatarWrapperElements,
	AvatarWrapperElementsAddOne,
	StyledGrid
} from './Avatar.style';
import {Search} from '../../components';
import {ImageIcon, PencilIcon, TrashIcon} from '../../icons';
import {mutations} from '../../actions/mutations';
import {useState} from 'react';
import DeleteConfirmationModal from '../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';


export const Avatar = () => {
	const history = useHistory();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [avatarToDelete, setAvatarToDelete] = useState(null);

	const { loading, error, data, refetch } = useQuery(query('AvatarFindMany'), {
		variables: { filter: { deletedAt: null } },
		nextFetchPolicy: 'network-only'
	});

	const [deleteAvatar] = useMutation(mutations.AvatarDeleteOne, {
		refetchQueries: ['AvatarFindMany'],
		awaitRefetchQueries: true
	});

	const handleDeleteClick = (avatarId) => {
		setAvatarToDelete(avatarId);
		setIsDeleteModalOpen(true);
	};

	const handleDelete = async () => {
		try {
			await deleteAvatar({
				variables: { _id: avatarToDelete },
			});
			setIsDeleteModalOpen(false);
			await refetch();
		} catch (error) {
			console.error('Error deleting avatar:', error);
		}
	};

	const sortedData = data ? data.AvatarFindMany : [];

	if (loading) return <p className={'font-mona f-900 white p-3'}>Loading....</p>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	return (
		<AvatarPageContainer className={'m-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<AvatarTitle className={'font-sans'}>Avatars</AvatarTitle>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<button
					style={{maxWidth: '340px'}}
					onClick={() => history.push('/avatar/create')}
					className={`h-full flex self-center align-center justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default`}
				>
					Create Avatar
				</button>
				{sortedData.map(({_id, label, artwork, price}) => (
					<AvatarWrapperElements
						key={_id}
						className={'flex justify-between items-center p-3 mb-3'}>
						<div className={'flex justify-start'}>
							<div className={'flex justify-center items-center mr-2'}>
								{
									artwork?.url === undefined || artwork?.url === null || artwork?.url === 'test' || artwork?.url === 'artwork' ?
										(
											<div
												className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<ImageIcon width={'1rem'} height={'1rem'}
														   background={'rgba(174, 185, 225, 1)'}/>
											</div>
										) :
										(
											<div
												className={'w-22 h-22 flex justify-center align-center rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<img
													className={'rounded-full'}
													src={artwork.url}
													alt={label}
												/>
											</div>
										)
								}
							</div>
							<div className={'grid'}>
								<span className={'font-sans'}>{label}</span>
								<p className={'font-mono'}>Price: {price}</p>
							</div>
						</div>
						<div className={'flex justify-center align-center'}>
							<div onClick={() => history.push(`/avatar/${_id}`)}
								 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<PencilIcon/>
							</div>
							<div onClick={() => handleDeleteClick(_id)}
								className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<TrashIcon/>
							</div>
						</div>
					</AvatarWrapperElements>
				))}
			</StyledGrid>
			<DeleteConfirmationModal
				isOpen={isDeleteModalOpen}
				setIsOpen={setIsDeleteModalOpen}
				onDelete={handleDelete}
				itemName={avatarToDelete && sortedData.find(avatar => avatar._id === avatarToDelete)?.label}
			/>
		</AvatarPageContainer>
	);
};