import {Search} from '../../components';
import {StyledGrid} from '../District/District.style';
import {ImageIcon, PencilIcon} from '../../icons';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {StickerImgCover} from './Sticker.style';
import {VaultFindMany} from '../../hoc/VaultFindMany';

export const StickerRender = ({ Stickers }) => {
	const history = useHistory();

	return (
		<VaultFindMany>
			{charityVaults => {
				const getVaultNameById = (vaultId) => {
					const vault = charityVaults.find(v => v.id === vaultId);
					return vault ? vault.charity.name : 'No vault';
				};

				return (
					<div className={'w-full h-full'}>
						<div className={'flex justify-stretch items-center p-1 mb-3'}>
							<h1 className={'font-sans text-base text-white f-500'}>Stickers</h1>
							<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
						</div>
						<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
							<button
								style={{maxWidth: '340px'}}
								onClick={() => history.push('/stickers/create')}
								className={` justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							>
								Create Sticker
							</button>
							<div
								className={'w-full h-full rounded flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}
								style={{
									backgroundColor: 'rgba(11, 23, 57, 1)',
									border: '1px solid rgba(52, 59, 79, 1)'
								}}
								onClick={() => history.push('/stickers/create')}>
								<div className={'flex gap-3 align-center justify-center'}>
									<span className={'font-sans text-base text-white'}>Add Sticker</span>
								</div>
								<div className={'flex gap-3 align-center justify-center'}>
									<div className={'flex align-center justify-center rounded-full'}
										 style={{
											 width: '2rem',
											 height: '2rem',
											 backgroundColor: 'rgba(203, 60, 255, 0.5)'
										 }}>
										<span className={'font-sans text-4xl text-white'}>+</span>
									</div>
								</div>
							</div>
							{Stickers.map(({_id, label, identifier, deedlyVault, cover, createdAt, updatedAt}) => {
								const vaultName = getVaultNameById(deedlyVault);
								return (
									<div key={_id}
										 className={'w-full h-full rounded flex gap-2 justify-between align-center p-3 mb-3 bg-background-light dark:bg-background-dark'}
										 style={{
											 backgroundColor: 'rgba(11, 23, 57, 1)',
											 border: '1px solid rgba(52, 59, 79, 1)'
										 }}>
										<div className={'flex justify-start'}>
											<div className={'flex justify-center items-center mr-2'}>
												{
													!cover?.url || cover?.url === 'test' || cover?.url === 'artwork' ? (
														<div
															className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
															<ImageIcon width={'1rem'} height={'1rem'}
																	   background={'rgba(174, 185, 225, 1)'}/>
														</div>
													) : (
														<div
															className={'w-22 h-22 flex justify-center align-center rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
															<StickerImgCover
																className={'rounded-full'}
																src={cover.url}
																alt={''}/>
														</div>
													)
												}
											</div>
											<div className={'grid'}>
												<span
													className={'font-sans f-700 text-white truncate overflow-ellipsis'}>{label}</span>
												<p className={'font-mono truncate overflow-ellipsis'}
												   style={{color: 'rgba(174, 185, 225, 1)'}}>Identifier: {identifier ? identifier : 'no-identifier'}</p>
												{
													vaultName ? (
														<p className={'font-mono truncate overflow-ellipsis'}
														   style={{color: 'rgba(174, 185, 225, 1)'}}>Deedly
															Vault: {vaultName}</p>
													) : null
												}
											</div>
										</div>
										<div className={'flex justify-center align-center'}
											 onClick={() => history.push(`/stickers/${_id}`)}>
											<div onClick={() => history.push(`/stickers/${_id}`)}
												 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark cursor-pointer'}>
												<PencilIcon/>
											</div>
										</div>
									</div>
								);
							})}
						</StyledGrid>
					</div>
				);
			}}
		</VaultFindMany>
	);
};

StickerRender.propTypes = {
	Stickers: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		identifier: PropTypes.string.isRequired,
		deedlyVault: PropTypes.string.isRequired,
		cover: PropTypes.shape({
			url: PropTypes.string
		}),
		video: PropTypes.shape({
			url: PropTypes.string
		}),
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired
	})).isRequired
};