import {svgPropTypes} from '../../types';


export const BriefcaseIcon = ({ width, height, background, className, style, isActive }) =>{
	const fillColor = isActive ? 'rgba(203, 60, 255, 1)' : 'rgba(174, 185, 225, 1)';

	return (
		<svg xmlns='http://www.w3.org/2000/svg'
			 width={width}
			 height={height}
			 viewBox='0 0 11 11'
			 fill='none'
			 className={className}
			 style={{...style}}
		>
			<path
				d='M10.0062 2.53898H8.04943C7.95687 2.00296 7.67795 1.51687 7.26188 1.16649C6.84581 0.816108 6.31934 0.623962 5.77539 0.623962C5.23144 0.623962 4.70498 0.816108 4.28891 1.16649C3.87284 1.51687 3.59391 2.00296 3.50135 2.53898H1.54462C1.34061 2.53898 1.14495 2.62002 1.00069 2.76428C0.856434 2.90854 0.775391 3.1042 0.775391 3.30821V9.46206C0.775391 9.66607 0.856434 9.86173 1.00069 10.006C1.14495 10.1502 1.34061 10.2313 1.54462 10.2313H10.0062C10.2102 10.2313 10.4058 10.1502 10.5501 10.006C10.6943 9.86173 10.7754 9.66607 10.7754 9.46206V3.30821C10.7754 3.1042 10.6943 2.90854 10.5501 2.76428C10.4058 2.62002 10.2102 2.53898 10.0062 2.53898ZM5.77539 1.38513C6.11638 1.38579 6.44756 1.49935 6.71719 1.7081C6.98683 1.91685 7.17973 2.20902 7.26578 2.53898H4.28501C4.37105 2.20902 4.56395 1.91685 4.83359 1.7081C5.10322 1.49935 5.4344 1.38579 5.77539 1.38513Z'
				fill={fillColor}/>
		</svg>
	);
};

BriefcaseIcon.propTypes = svgPropTypes;

BriefcaseIcon.defaultProps = {
	width: '11',
	height: '11',
	background: 'transparent',
	className: '',
	style: {},
	isActive: false,
};

export default BriefcaseIcon;