import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {SchoolFormRender} from './SchoolForm.render';
import PropTypes from 'prop-types';
import {schoolMutations} from '../../actions/school.mutations';

export const SchoolFormLoad = ({onSuccess, currentSchool})=>{
	const history = useHistory();
	const defaultValues = {
		name: 		currentSchool?.name ||'',
		state: 		currentSchool?.state || '',
		city: 		currentSchool?.city || '',
		district: 	currentSchool?.district || '',
		country: 	currentSchool?.country || '',
		slug: 		currentSchool?.slug || '',
	};
	const [formState, setFormState] = useState(defaultValues);

	const {  data:districtData ,loading:districtLoading, error:districtError } = useQuery(query('districtFindMany'),{
		variables: {},
	});

	const [createSchool,{loading:createLoading}] = useMutation(schoolMutations('createOne'), {
		refetchQueries: [{ query: query('schoolFindMany') }],
		awaitRefetchQueries: true,
	});

	const [updateSchool,{loading:updateLoading}] = useMutation(schoolMutations('updateOne'), {
		refetchQueries: [{ query: query('schoolFindMany') }],
		awaitRefetchQueries: true,
	});


	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			if (!!currentSchool){
				await updateSchool({
					variables: {_id: currentSchool._id, record: formState,},
				});
			}else {
				await createSchool({
					variables: {record: formState,},
				});
			}

			onSuccess();
		} catch (error) {
			console.error('Error creating school:', error);
		}
	};

	const districtFindMany = districtData?.DistrictFindMany?.map(e=>({label:e.name, value:e._id})) ||[];
	return (
		<SchoolFormRender
			edit={!!currentSchool}
			isLaoding={createLoading || updateLoading ||districtLoading }
			districtOptions={{
				data:districtFindMany,
				isLoading:districtLoading
			}}
			formState={formState}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
		/>
	);
};

SchoolFormLoad.propTypes = {
	onSuccess:PropTypes.func.isRequired,
	currentSchool:PropTypes.shape({
		name:PropTypes.string.isRequired,
		state:PropTypes.string.isRequired,
		city:PropTypes.string.isRequired,
		district:PropTypes.string.isRequired,
		country:PropTypes.string.isRequired,
		slug:PropTypes.string.isRequired,
	}),

};