import PropTypes from 'prop-types';
import {Question} from '../../../LessonsEdit/components';
import {QuestionForm} from '../../../LessonsEdit/components/QuestionForm';
import {TakeAwayForm} from '../../forms';

export const FunFact =({sparkL, addQuestionModal, questions,questionPoints, setAddQuestionModal,onDeleteQuestion,onUpdateQuestion,onCreateQuestion })=>{
    
	return (
		<div className={'p-4 font-mono w-[800px] flex flex-col gap-3 text-white'}>
			<h1 className={'w-full center h1'}>Fun fact</h1>
			<TakeAwayForm sparkL={sparkL}/>
			<hr className={'border-cloudyBlue-default my-4'}/>
			<div className={'flex w-full justify-between items-center mt-2 max-h-[800px] '}>
				<h3 className={' center h3 block'}>Questions</h3>
				<button
					type={'button'}
					className={'bg-brightLilac-default cursor-pointer rounded px-2 hover:opacity-50'}
					onClick={()=>setAddQuestionModal(true)}
				>
					<p className={'flex gap-2 justify-center align-center font-sans'}>
						Add Fun fact Question
						<span className={'text-2xl'}>+</span>
					</p>

				</button>
			</div>

			{questions.map((item, index) =>
				<Question
					key={item._id}
					question={item}
					points={questionPoints.find(qp=>qp.question===item._id).points}
					questionIndex={index+1}
					onDeleteQuestion={onDeleteQuestion}
					onEditQuestion={onUpdateQuestion}

				/>
			)}

			<QuestionForm
				onCreate={onCreateQuestion}

				isOpen={addQuestionModal}
				setIsOpen={setAddQuestionModal}
			/>
		</div>
	);
};


FunFact.propTypes = {
	sparkL:PropTypes.object,
	addQuestionModal:PropTypes.bool.isRequired,

	questions: PropTypes.arrayOf(
		PropTypes.shape({
			question: PropTypes.string.isRequired,
			points: PropTypes.string.isRequired
		})
	).isRequired,
	questionPoints:PropTypes.shape({
		question:PropTypes.string,
		points:PropTypes.number,
	}).isRequired,

	setAddQuestionModal: PropTypes.func.isRequired,
	onUpdateQuestion:PropTypes.func.isRequired,
	onDeleteQuestion:PropTypes.func.isRequired,
	onCreateQuestion:PropTypes.func.isRequired,
};