import Button from '../../../../components/ui/Button';
import Modal from '../../../../components/ui/Modal/Modal';
import {useMutation} from '@apollo/client';
import {mutations, sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';

export const DeleteSparkLibraryModal = ({currentSparkLibrary,isOpenState})=>{
	const [isOpen,setIsOpen] = isOpenState;

	const [updateSparkLibrary,{loading ,error }]  = useMutation(sparkLibrariesMutations('updateOne'),{
		refetchQueries:['sparkLibraryFindMany']
	});
	const handleDelete = ()=>{
		updateSparkLibrary({
			variables:{
				record:{deleted:true},
				id:currentSparkLibrary._id
			},
		})
		.then(()=>setIsOpen(false));
	};
    
	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<div className={'p-4'}>
				<h2>Are you sure about delete the "{currentSparkLibrary.title}" ? </h2>
				<div className={'flex mt-8 justify-center gap-4'}>
					<Button
						className={'bg-brightLilac-default'}
						type={'button'}
						onClick={()=>setIsOpen(false)}
					>Cancel</Button>

					<Button
						className={`${!loading? 'bg-red-500' : 'bg-red-200'}`}
						disabled={loading}
						type={'button'}
						onClick={handleDelete}
					>Delete</Button>
				</div>
			</div>
		</Modal>
	);
};