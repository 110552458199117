import {useState} from 'react';
import PropTypes from 'prop-types';
import {MagnifyingGlassIcon} from '../../../icons';
import {SearchWrapper} from './Search.styles';
export default function Search({ label, onSearch, className, style }) {
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			onSearch(inputValue);
		}
	};

	return (
		<SearchWrapper className={`font-mono flex items-center p-2 my-2 rounded-lg cursor-pointer ${className}`}
					   style={style}
		>
			<div>
				<MagnifyingGlassIcon />
			</div>
			<div className='flex-1 ml-2'>
				<input
					type='text'
					value={inputValue}
					onChange={handleInputChange}
					onKeyDown={handleKeyDown}
					placeholder={label}
					className='bg-transparent outline-none text-white placeholder-gray-500 w-full'
				/>
			</div>
		</SearchWrapper>
	);
}

// Types for props:
Search.propTypes = {
	label: PropTypes.string,
	onSearch: PropTypes.func.isRequired,
	className: PropTypes.string,
	style: PropTypes.object,
};

Search.defaultProps = {
	label: 'Search for...',
};